import gql from "graphql-tag";
import * as GQLOps from "../../generated/generated-operations";
import { graphQLProductQuery, QueryGenerator } from "../../query";
import { fetchImageData, ImageData } from "../../images";
import { propertyImageFragment } from "../../graphql-queries/property-fragments";

const productImageQuery = gql`
  query Report_ProductImages($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        images {
          ...Image_Module_images
        }
        ...propertyImageModule
      }
    }
  }
  fragment Image_Module_images on Module_images {
    image {
      image
      type
      name
      file_name
    }
  }
  ${propertyImageFragment}
`;

export function* metaImageQuery(): QueryGenerator<GQLOps.Report_ProductImagesQuery["product"] | undefined> {
  const imageResponse = yield* graphQLProductQuery<
    GQLOps.Report_ProductImagesQuery,
    GQLOps.Report_ProductImagesQueryVariables
  >(productImageQuery, { productId: "665b2220-23c5-40f6-a68a-33600aa131e9" });
  return imageResponse.product;
}

export function* imageQueryByProductId(
  productId: string
): QueryGenerator<GQLOps.Report_ProductImagesQuery["product"] | undefined> {
  const imageResponse = yield* graphQLProductQuery<
    GQLOps.Report_ProductImagesQuery,
    GQLOps.Report_ProductImagesQueryVariables
  >(productImageQuery, { productId: productId });
  return imageResponse.product;
}

export function* getLogoImage(
  imageTable: GQLOps.ImageModulesFragment["images"]["image"] | undefined,
  imageServiceUrl: string
): QueryGenerator<ReadonlyArray<ImageData>> {
  const logo = imageTable?.filter((image) => image.name === "veab-logo-old");
  return yield* fetchImageData(logo || [], imageServiceUrl, 300, 300);
}
