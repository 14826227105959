import * as R from "ramda";
import { AbstractDoc as AD, AbstractDocJsx as ADX } from "abstract-document";
import { QueryRunner, ReportParams, ReportQueryResponse, ReportResponse } from "./types";
import { getReportModule } from "./report-registry";
import * as Common from "./common";

export function* runReportQuries(imageServiceUrl: string, reportParams: ReportParams): QueryRunner {
  const responses: Array<ReportResponse> = [];

  const metaImageQueryResponse = yield* Common.Queries.metaImageQuery();
  const propertyImageQueryResponse = yield* Common.Queries.imageQueryByProductId(reportParams.ehProductId);
  const metaImageResponse = metaImageQueryResponse?.modules.images.image;
  const propertyImages = propertyImageQueryResponse?.modules.custom_tables.property_image;
  const logoimage = yield* Common.Queries.getLogoImage(metaImageResponse, imageServiceUrl);

  const common = {
    logoImage: logoimage,
    imageResponse: metaImageResponse,
    headings: [reportParams.reportType],
  };

  const queryCache = new Map();

  const module = getReportModule(reportParams.reportType);
  const generator = module.query({ ...reportParams, imageResponse: metaImageResponse, propertyImages });
  let next = generator.next();
  while (!next.done) {
    const query = next.value;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let queryResult: any;
    const stringQuery = JSON.stringify(query);
    if (queryCache.has(stringQuery)) {
      queryResult = queryCache.get(stringQuery);
    } else {
      try {
        queryResult = yield query;
        queryCache.set(stringQuery, queryResult);
      } catch (e) {
        if (generator.throw) {
          generator.throw(e);
        } else {
          throw e;
        }
      }
    }
    next = generator.next(queryResult!);
  }
  responses.push(next.value);

  return { commonResponse: common, reportResponses: responses };
}
export async function createDocument(
  responses: ReportQueryResponse,
  reportParams: ReadonlyArray<ReportParams>
): Promise<AD.AbstractDoc.AbstractDoc> {
  const docList: Array<AD.AbstractDoc.AbstractDoc> = [];
  for (let i = 0; i < reportParams.length; i++) {
    const reportType = reportParams[i].reportType;
    const commonResponse = responses.commonResponse;
    const reportResponse = responses.reportResponses[i];
    const module = getReportModule(reportType);
    const data = await module.execute({ ...reportParams[i] }, commonResponse, reportResponse);
    docList.push(module.create(data));
  }
  const doc = combineAbstractDocs(docList, false);
  return ADX.render(doc);
}

// eslint-disable-next-line functional/prefer-readonly-type
function combineAbstractDocs(docs: AD.AbstractDoc.AbstractDoc[], joinDocs: boolean): AD.AbstractDoc.AbstractDoc {
  const fonts = R.mergeAll(docs.map((d) => d.fonts || {})) as AD.Types.Indexer<AD.Font.Font>;
  const imageResources = R.mergeAll(
    docs.map((d) => d.imageResources || {})
  ) as AD.Types.Indexer<AD.ImageResource.ImageResource>;
  const styles = R.mergeAll(docs.map((d) => d.styles || {})) as AD.Types.Indexer<AD.Style.Style>;
  const numberings = R.mergeAll(docs.map((d) => d.numberings || {})) as AD.Types.Indexer<AD.Numbering.Numbering>;
  const numberingDefinitions = R.mergeAll(
    docs.map((d) => d.numberingDefinitions || {})
  ) as AD.Types.Indexer<AD.NumberingDefinition.NumberingDefinition>;

  let children = undefined;
  if (joinDocs) {
    const mergedChildren: Array<AD.Section.Section> = [];
    for (const doc of docs) {
      if (doc.children.length === 0) {
        continue;
      }

      const prevChild = mergedChildren.pop();
      if (!prevChild) {
        mergedChildren.push(...doc.children);
        continue;
      }

      const nextChild = doc.children[0];
      const mergedChild = {
        ...prevChild,
        children: [...prevChild.children, ...nextChild.children],
      };
      mergedChildren.push(mergedChild);
      mergedChildren.push(...doc.children.slice(1));
    }
    children = mergedChildren;
  } else {
    children = R.unnest(docs.map((d) => d.children)) as ReadonlyArray<AD.Section.Section>;
  }

  return AD.AbstractDoc.create(
    {
      fonts,
      imageResources,
      styles,
      numberings,
      numberingDefinitions,
    },
    children
  );
}
