import { QueryGenerator } from "../../../query";
import { getPageProps } from "../../common";
import { ReportQueryProjectParams } from "../../types";
import { AdapterType, Response } from "./types";

export function* query(_param: ReportQueryProjectParams): QueryGenerator<Response> {
  return {};
}

export const execute: AdapterType = async (reportParams, common, _response) => {
  const { project } = reportParams;
  const pageProps = await getPageProps(reportParams, common);

  return {
    pageProps,
    productById: reportParams.productById,
    project,
  };
};
