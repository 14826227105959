import { ReportResponse } from ".";
import { ReportType, ReportModule, CreatorProps, reportSections, ReportQueryParams, ReportParams } from "./types";

const reports = reportSections.map((module) => ({ ...module }));

export function getReportModule(
  name: ReportType
): ReportModule<CreatorProps, ReportQueryParams, ReportParams, ReportResponse> {
  const report = reports.find((x) => x.Name === name);
  if (!report) {
    throw new Error(`could not find Module: ${name.toString()}`);
  }
  return report;
}
