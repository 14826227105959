import { PropertyFilter } from "@promaster-sdk/property";
import { unitLookup } from "uom-units";
import { isEndCustomer, isInternalUser } from "../../user";
import { getPageProps, imageToBitmapImage } from "../common";
import { ReportQueryProductParams } from "../types";
import { createQuoteData } from "./create-quote-data";
import { AdapterType, QuoteReportQueryParams, Response } from "./types";
import { fetchImageData, fetchPropertyImageData } from "../../images";
import { QueryGenerator } from "../../query";

export function* query(props: ReportQueryProductParams): QueryGenerator<Response> {
  // const logo = props.imageResponse?.filter((image) => image.file_name === "veab-logo.svg");
  // const imageResponse = yield* Common.Queries.imageQueryByProductId("bc224354-e4a4-4b62-9fef-1a24baad67e3");
  const images = yield* fetchImageData(props.heaterImages || [], props.imageServiceUrl, 1000, 1000);

  const propertyImage = props.propertyImages?.find((r) => {
    const propertyFilter =
      r.property_filter !== null ? PropertyFilter.fromString(r.property_filter, unitLookup) : undefined;
    return propertyFilter && PropertyFilter.isValid(props.properties, propertyFilter);
  });
  const cableInletImage = yield* fetchPropertyImageData(
    propertyImage ? [propertyImage] : [],
    props.imageServiceUrl,
    1000,
    1000
  );

  const internalUser = isInternalUser(props.user.claims);

  const endCustomer = isEndCustomer(props.user.claims);

  if (
    props.calculateRequest === undefined ||
    props.calculateResult === undefined ||
    props.priceAccessories === undefined
  ) {
    throw new Error("Missing parameters for quote report");
  }

  const quoteData = createQuoteData(props as QuoteReportQueryParams, internalUser, endCustomer);
  return { images: images, quoteData: quoteData, cableInletImage: cableInletImage[0] };
}

export const execute: AdapterType = async (quoteReportParams, common, response) => {
  const pageProps = await getPageProps(quoteReportParams, common);
  // const logoImageData = response.images[0];

  // const logoImage = await imageToBitmapImage(1000, 1000, logoImageData);
  const heaterImage = await imageToBitmapImage(500, 500, response.images[0]);

  const cableInletImage = await imageToBitmapImage(500, 500, response.cableInletImage);

  if (quoteReportParams.calculateRequest === undefined) {
    throw new Error("Calculate Request missing");
  }
  return {
    pageProps: pageProps,
    calculateRequest: quoteReportParams.calculateRequest,
    quoteData: response.quoteData,
    heaterImage: heaterImage,
    cableInletImage: cableInletImage,
  };
};
