import { AbstractDocExporters } from "abstract-document";
import BlobStream from "blob-stream";
import { saveAs } from "file-saver";
import { PDFBool, PDFDocument as PDFLibDocument, PDFName } from "pdf-lib";
import { createDocument } from "./report-runner-shared";
import { ReportParams, ReportParamsProduct, ReportQueryResponse } from "./types";
import { generateDrawing } from "./drawing-page";
import { CircuitDiagramTableRows, DocumentTableRows } from "../calculator";
import { generateCircuitDiagram } from "./circuit-diagram-page";

export async function createPrintoutClient(
  responses: ReportQueryResponse,
  projectReportParams: ReadonlyArray<ReportParams>,
  fileName: string,
  pdfKit: {},
  onSuccess?: () => void
): Promise<void> {
  const doc = await createDocument(responses, projectReportParams);
  const stream = BlobStream();
  stream.on("finish", () => {
    saveAs(stream.toBlob(), fileName);
    if (onSuccess) {
      onSuccess();
    }
  });
  AbstractDocExporters.Pdf.exportToStream(pdfKit, stream, doc);
}

export async function createPrintoutClient2(
  responses: ReportQueryResponse,
  projectReportParams: ReadonlyArray<ReportParamsProduct>,
  fileName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pdfKit: any,
  documents: ReadonlyArray<DocumentTableRows>,
  circuitDiagrams: ReadonlyArray<CircuitDiagramTableRows>,
  onSuccess?: () => void
): Promise<void> {
  const doc = await createDocument(responses, projectReportParams);
  const stream = BlobStream();

  stream.on("finish", () => {
    const processPdf = async (): Promise<void> => {
      // Get the blob from the stream
      const blob = stream.toBlob();

      // Convert the blob to an ArrayBuffer
      const buffer = await blob.arrayBuffer();

      // Load the PDFKit-generated PDF as a pdf-lib document
      const quoteAbstractDOC = await PDFLibDocument.load(buffer);

      const reportParams = projectReportParams.find((r) => r.reportType === "quote-page");
      const circuitDiagramDoc =
        reportParams?.calculateResult &&
        (await generateCircuitDiagram(circuitDiagrams, reportParams.calculateResult, reportParams.user));

      const drawingDoc = await generateDrawing(documents, reportParams!);

      // Create a new pdf-lib document to merge all the documents
      const mergedPdf = await PDFLibDocument.create();

      // Merge the documents
      const mergeDocs = async (srcDoc: PDFLibDocument): Promise<void> => {
        const pageCount = srcDoc.getPageCount();
        for (let i = 0; i < pageCount; i++) {
          const [srcPage] = await mergedPdf.copyPages(srcDoc, [i]);
          mergedPdf.addPage(srcPage);
        }
      };

      await mergeDocs(quoteAbstractDOC);
      if (circuitDiagramDoc) {
        await mergeDocs(circuitDiagramDoc);
      }

      await mergeDocs(drawingDoc);
      // Serialize the merged PDF and save it
      mergedPdf.getForm().acroForm.dict.set(PDFName.of("NeedAppearances"), PDFBool.True);
      const mergedPdfBytes = await mergedPdf.save();
      const mergedPdfBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      saveAs(mergedPdfBlob, fileName);
      if (onSuccess) {
        onSuccess();
      }
    };

    processPdf();
  });
  AbstractDocExporters.Pdf.exportToStream(pdfKit, stream, doc);
}
