import { PropertyValueSet } from "@promaster-sdk/property";

export type TextTypes = TextKey | PropertyNameKey | PropertyDescriptionKey | PropertyValueKey;

export type KeyParams = { readonly [key: string]: string | number };

export type TextKey = {
  readonly type: "TextKey";
  readonly key: string;
  readonly params: KeyParams;
  readonly productKey: string;
  readonly variant: PropertyValueSet.PropertyValueSet | undefined;
};

export const key = (
  key: string,
  params: KeyParams = {},
  productKey: string = "meta",
  variant: PropertyValueSet.PropertyValueSet | undefined = PropertyValueSet.Empty
): TextKey => ({
  type: "TextKey",
  key,
  params,
  productKey,
  variant,
});

export interface PropertyNameKey {
  readonly type: "PropertyName";
  readonly property: string;
  readonly productKey: string;
}

export function propertyNameKey(property: string | null, productKey: string): PropertyNameKey {
  return {
    type: "PropertyName",
    property: property || "",
    productKey,
  };
}

export interface PropertyDescriptionKey {
  readonly type: "PropertyDescription";
  readonly property: string;
  readonly productKey: string;
}

export function propertyDescriptionKey(property: string | null, productKey: string): PropertyDescriptionKey {
  return {
    type: "PropertyDescription",
    property: property || "",
    productKey,
  };
}

export interface PropertyValueKey {
  readonly type: "PropertyValue";
  readonly property: string;
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly productKey: string;
}

export function propertyValueKey(
  property: string,
  variant: PropertyValueSet.PropertyValueSet,
  productKey: string
): PropertyValueKey {
  return {
    type: "PropertyValue",
    property,
    variant,
    productKey,
  };
}

// export type ErrorTextKeys = {
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   readonly [K in TextKeyName]: K extends `${infer T}ERROR_${infer U}` ? K : never;
// }[TextKeyName];

export type TextKeyName = keyof typeof texts;

export const texts = {
  property_group: (groupName: string, productKey: string) => key("property_group_" + groupName, {}, productKey),
  property_name: propertyNameKey,
  property_desc: propertyDescriptionKey,
  property_value: propertyValueKey,
  search: key("search"),
  search_column: (column: string) => key(`search_column_${column}`),
  electrical_elements: key("electrical_elements"),
  back_to_search: key("back_to_search"),
  fill_in_values: key("fill_in_values"),
  err_fill_in_values: key("err_fill_in_values"),
  http_request_error: key("http_request_error"),
  not_found_404: key("not_found_404"),
  not_allowed: key("not_allowed"),
  go_back: key("go_back"),
  logout: key("logout"),
  options: key("options"),
  data: key("data"),
  value_is_required: key("value_is_required"),
  value_must_be_numeric: key("value_must_be_numeric"),
  calculate: key("calculate"),
  results: key("results"),
  model: key("model"),
  type: key("type"),
  width: key("width"),
  height: key("height"),
  depth: key("depth"),
  manual_depth: key("manual_depth"),
  button_clear: key("button_clear"),
  button_set: key("button_set"),
  power: key("power"),
  airspeed: key("airspeed"),
  pressuredrop: key("pressuredrop"),
  powerstepsdistribution: key("powerstepsdistribution"),
  tempincrease: key("tempincrease"),
  price: key("price"),
  voltage: key("voltage"),
  current: key("current"),
  weight: key("weight"),
  pfp_equals: key("pfp_equals"),
  pfp_not_equals: key("pfp_not_equals"),
  pfp_less_or_equal: key("pfp_less_or_equal"),
  pfp_greater_or_equal: key("pfp_greater_or_equal"),
  pfp_less: key("pfp_less"),
  pfp_greater: key("pfp_greater"),
  pfp_between: key("pfp_between"),
  pfp_and: key("pfp_and"),
  pfp_or: key("pfp_or"),
  pdf_quote: key("pdf_quote"),
  pdf_datasheet: key("pdf_datasheet"),
  quote_delivery_info_value: (productKey: string) => key("quote_delivery_info_value", {}, productKey),
  quote_insulation_NI: key("quote_insulation_NI"),
  quote_insulation_RI: key("quote_insulation_RI"),
  quote_stomme_value: (productKey: string, pvs: PropertyValueSet.PropertyValueSet) =>
    key("quote_stomme_value", {}, productKey, pvs),
  quote_controlling_value: (productKey: string, pvs: PropertyValueSet.PropertyValueSet) =>
    key("quote_controlling_value", {}, productKey, pvs),
  quote_temperature_info_value_heating: (
    productKey: string,
    params: {
      readonly tempIncrease: number;
      readonly airVolumeM3h: number;
      readonly velocityMs: number;
      readonly pressureDrop: number;
    }
  ) => key("quote_temperature_info_value_heating", params, productKey),
  quote_temperature_info_value_cooling: (
    productKey: string,
    params: {
      readonly tempIncrease: number;
      readonly airVolumeM3h: number;
      readonly velocityMs: number;
      readonly pressureDrop: number;
    }
  ) => key("quote_temperature_info_value_cooling", params, productKey),
  quote_temperature_info_value_heating_and_cooling: (
    productKey: string,
    params: {
      readonly heatingTempIncrease: number;
      readonly heatingAirVolumeM3h: number;
      readonly heatingVelocityMs: number;
      readonly heatingPressureDrop: number;
      readonly coolingTempIncrease: number;
      readonly coolingAirVolumeM3h: number;
      readonly coolingVelocityMs: number;
      readonly coolingPressureDrop: number;
    }
  ) => key("quote_temperature_info_value_heating_and_cooling", params, productKey),
  quote_webgenerated: key("quote_webgenerated"),
  yes: key("yes"),
  no: key("no"),
  contact_VEAB: key("contact_VEAB"),
  material_value: (productKey: string, pvs: PropertyValueSet.PropertyValueSet) =>
    key("material_value", {}, productKey, pvs),
  insulation_value: (productKey: string, pvs: PropertyValueSet.PropertyValueSet) =>
    key("insulation_value", {}, productKey, pvs),
  deliveryterms: (productKey: string, pvs: PropertyValueSet.PropertyValueSet) =>
    key("deliveryterms", {}, productKey, pvs),
  paymentterms: (productKey: string, pvs: PropertyValueSet.PropertyValueSet) =>
    key("paymentterms", {}, productKey, pvs),
  ductsensorTGK330: (price: number, currency: string, productKey: string, pvs: PropertyValueSet.PropertyValueSet) =>
    key("ductsensorTGK330", { price, currency }, productKey, pvs),
  ductsensorTGK360: (price: number, currency: string, productKey: string, pvs: PropertyValueSet.PropertyValueSet) =>
    key("ductsensorTGK360", { price, currency }, productKey, pvs),
  pressureswitchDTV300: (price: number, currency: string, productKey: string, pvs: PropertyValueSet.PropertyValueSet) =>
    key("pressureswitchDTV300", { price, currency }, productKey, pvs),
  roomsensorTGR430: (price: number, currency: string, productKey: string, pvs: PropertyValueSet.PropertyValueSet) =>
    key("roomsensorTGR430", { price, currency }, productKey, pvs),
  excl_accessories_below: (price: number, currency: string, productKey: string) =>
    key("excl_accessories_below", { price, currency }, productKey),
  workingdays: key("workingdays"),
  changesdetected: key("changesdetected"),
  load: key("load"),
  existing_code: key("existing_code"),
  material_a: key("material_a"),
  material_s: key("material_s"),
  material_sa: key("material_sa"),
  discount: (productKey: string) => key("discount", {}, productKey),
  discount_label: key("discount_label"),
  start_page_welcome: key("start_page_welcome"),
  start_page_welcome_subheading: key("start_page_welcome_subheading"),
  start_page_manual: key("start_page_manual"),
  start_page_manual_coil: key("start_page_manual_coil"),
  start_page_manual_heading: key("start_page_manual_heading"),
  start_page_veab_select: key("start_page_veab_select"),
  start_page_veab_select_subheading: key("start_page_veab_select_subheading"),
  start_page_veab_select_water_heater: key("start_page_veab_select_waterheater"),
  start_page_veab_select_water_heater_info: key("start_page_veab_select_water_heater_info"),
  start_page_veab_select_water_cooler: key("start_page_veab_select_watercooler"),
  start_page_veab_select_water_cooler_info: key("start_page_veab_select_watercooler_info"),
  start_page_veab_select_compressor: key("start_page_veab_select_compressor"),
  start_page_veab_select_compressor_info: key("start_page_veab_select_compressor_info"),
  start_page_electical_heater: key("start_page_electicalheater"),
  start_page_electical_heater_info: key("start_page_electicalheater_info"),
  start_page_ehb_title: key("start_page_ehb_title"),
  start_page_heaters: key("start_page_heaters"),
  start_page_water_dx: key("start_page_water_dx"),
  start_page_electrical: key("start_page_electrical"),
  start_page_heaters_coolers: key("start_page_heaters_coolers"),
  start_page_select_category: key("start_page_select_category"),
  start_page_special: key("start_page_special"),
  to_veab_select: key("to_veab_select"),
  to_ehb_select: key("to_ehb_select"),
  request_quote: key("request_quote"),
  request_quote_sent: key("request_quote_sent"),
  request_quote_failed: key("request_quote_failed"),
  request_quote_email_subject: key("request_quote_email_subject"),
  request_quote_email_text: (userEmail: string, configurationLink: string) =>
    key("request_quote_email_text", { userEmail, configurationLink }),
  group: (groupName: string) => key(`group_${groupName}`),
  add_custom_item: key("add_custom_item"),
  custom_items: key("custom_items"),
  quantity: key("quantity"),
  item_name: key("item_name"),
  total_price: key("total_price"),
  single_price: key("single_price"),
  position: key("position"),
  project: key("project"),
  reference: key("reference"),
  quote_stomme: key("quote_stomme"),
  quote_controlling: key("quote_controlling"),
  quote_delivery_info: key("quote_delivery_info"),
  quote_temperature_info: key("quote_temperature_info"),
  material: key("material"),
  protection: key("protection"),
  supplier_code: key("supplier_code"),
  insulation: key("insulation"),
  production_time: key("production_time"),
  product_information_title: key("product_information_title"),
  product_information: (productKey: string) => key("product_information", {}, productKey),
  accessories: key("accessories"),
  circular_connection: key("circular_connection"),
  quote_number: key("quote_number"),
  quote_header: key("quote_header"),
  quote_header_water: key("quote_header_water"),
  quote_header_dx: key("quote_header_dx"),
  quote_payment_terms: key("quote_payment_terms"),
  quote_date: key("quote_date"),
  notes: key("notes"),
  configuration_link: key("configuration_link"),
  link: key("link"),
  ERROR_IPCLASS_NOT_POSSIBLE: (productKey: string) => key("ERROR_IPCLASS_NOT_POSSIBLE", {}, productKey),
  ERROR_METHOD_NOT_POSSIBLE: (productKey: string) => key("ERROR_METHOD_NOT_POSSIBLE", {}, productKey),
  ERROR_SELECTION_NOT_POSSIBLE: (productKey: string) => key("ERROR_SELECTION_NOT_POSSIBLE", {}, productKey),
  ERROR_DEPTH_NOT_POSSIBLE: (productKey: string) => key("ERROR_DEPTH_NOT_POSSIBLE", {}, productKey),
  ERROR_CABLE_INLET: (productKey: string) => key("ERROR_CABLE_INLET", {}, productKey),
  ERROR_AIRSPEED_TO_HIGH: (productKey: string) => key("ERROR_AIRSPEED_TO_HIGH", {}, productKey),
  ERROR_AIRSPEED_TO_LOW: (productKey: string) => key("ERROR_AIRSPEED_TO_LOW", {}, productKey),
  ERROR_MAX_WIDTH: (productKey: string) => key("ERROR_MAX_WIDTH", {}, productKey),
  ERROR_MAX_HEIGHT: (productKey: string) => key("ERROR_MAX_HEIGHT", {}, productKey),
  ERROR_MIN_WIDTH: (productKey: string) => key("ERROR_MIN_WIDTH", {}, productKey),
  ERROR_MIN_HEIGHT: (productKey: string) => key("ERROR_MIN_HEIGHT", {}, productKey),
  ERROR_MAX_TEMP: (productKey: string) => key("ERROR_MAX_TEMP", {}, productKey),
  ERROR_MAX_STEPSIZE_FOR_1X230V: (productKey: string) => key("ERROR_MAX_STEPSIZE_FOR_1X230V", {}, productKey),
  ERROR_MAX_STEPSIZE_FOR_2X400V: (productKey: string) => key("ERROR_MAX_STEPSIZE_FOR_2X400V", {}, productKey),
  ERROR_MAX_POWER_3X400V: (productKey: string) => key("ERROR_MAX_POWER_3X400V", {}, productKey),
  ERROR_MAX_POWER_3X230V: (productKey: string) => key("ERROR_MAX_POWER_3X230V", {}, productKey),
  ERROR_MIN_HEIGHT_DUCT: (productKey: string) => key("ERROR_MIN_HEIGHT_DUCT", {}, productKey),
  ERROR_MIN_WIDTH_DUCT: (productKey: string) => key("ERROR_MIN_WIDTH_DUCT", {}, productKey),
  ERROR_MAX_POWER_FOR_MQ: (productKey: string) => key("ERROR_MAX_POWER_FOR_MQ", {}, productKey),
  ERROR_MAX_POWER_FOR_MQ_3X230V: (productKey: string) => key("ERROR_MAX_POWER_FOR_MQ_3X230V", {}, productKey),
  ERROR_MAX_POWER_FOR_MTUL: (productKey: string) => key("ERROR_MAX_POWER_FOR_MTUL", {}, productKey),
  ERROR_MAX_POWER_MTUL_230V: (productKey: string) => key("ERROR_MAX_POWER_MTUL_230V", {}, productKey),
  ERROR_FRAME_TO_BIG: (productKey: string) => key("ERROR_FRAME_TO_BIG", {}, productKey),
  ERROR_AIRSPEED_TO_LOW_FOR_120C: (productKey: string) => key("ERROR_AIRSPEED_TO_LOW_FOR_120C", {}, productKey),
  ERROR_CIRCULAR_NOT_POSSIBLE: (productKey: string) => key("ERROR_CIRCULAR_NOT_POSSIBLE", {}, productKey),
  ERROR_MAX_POWER_FOR_1X230V: (productKey: string) => key("ERROR_MAX_POWER_FOR_1X230V", {}, productKey),
  ERROR_MAX_DEPTH: (productKey: string) => key("ERROR_MAX_DEPTH", {}, productKey),
  ERROR_NEGATIVE_VALUE: (productKey: string) => key("ERROR_NEGATIVE_VALUE", {}, productKey),
  err_not_possible: key("err_not_possible"),
  ERROR_STEP1_TO_HIGH: (productKey: string) => key("ERROR_STEP1_TO_HIGH", {}, productKey),
  ERROR_STEP_TO_HIGH: (productKey: string) => key("ERROR_STEP_TO_HIGH", {}, productKey),
  err_0_steps: key("err_0_steps"),
  err_max_steps: key("err_max_steps"),
  err_pressure_drop_power: key("err_pressure_drop_power"),
  err_pressure_drop_delta_t: key("err_pressure_drop_delta_t"),
  report_footer_company: key("report_footer_company"),
  report_footer_postal_code: key("report_footer_postal_code"),
  report_footer_country: key("report_footer_country"),
  report_footer_visitor_address_text: key("report_footer_visitor_address_text"),
  report_footer_visitor_address: key("report_footer_visitor_address"),
  report_footer_delivery_address_text: key("report_footer_delivery_address_text"),
  report_footer_delivery_address: key("report_footer_delivery_address"),
  report_footer_phone_text: key("report_footer_phone_text"),
  report_footer_phone: key("report_footer_phone"),
  report_footer_email: key("report_footer_email"),
  report_footer_site: key("report_footer_site"),
  production_time_message: key("production_time_message"),
  heating_rod_surface_temp: key("heating_rod_surface_temp"),
  search_all: key("search_all"),
  search_attribute: (attributeName: string) => key(`search_${attributeName}`),
  search_attribute_value: (attributeName: string, value: string) => key(`search_${attributeName}_${value}`),
  unselectable_option: key("unselectable_option"),
  calculation_input_error: key("calculation_input_error"),
  calculation_error_dll_request: key("calculation_error_dll_request"),
  calculation_error_no_product_data: key("calculation_error_no_product_data"),
  calculation_error_variant_no_found: key("calculation_error_variant_no_found"),
  frt_dll_error: (errorNumber: string) => key(`frt_dll_error_${errorNumber.toString()}`),
  frt_dll_warning: (warningNumber: string) => key(`frt_dll_warning_${warningNumber.toString()}`),
  frt_dll_error_generic: (errorNumber: string) => key(`frt_dll_error_generic`, { error_number: errorNumber }),
  calculate_products: (numProducts: number) => key(`calculate_products`, { num_products: numProducts }),
  no_results: key("no_results"),
  no_results_found_message: key("no_results_found_message"),
  no_results_found_tolerance_message: key("no_results_found_tolerance_message"),
  dxf_and_rfa: key("dxf_and_rfa"),
  dimensions: key("dimensions"),
  webgl_not_available_message: key("webgl_not_available_message"),
  view_product: key("view_product"),

  capacity: key("capacity"),
  performance_data: key("performance_data"),
  air_side_data: key("air_side_data"),
  fluid_side_data: key("fluid_side_data"),
  atmospheric_pressure: key("atmospheric_pressure"),
  altitude: key("altitude"),
  volumetric_flow: key("volumetric_flow"),
  mass_flow: key("mass_flow"),
  density: key("density"),
  surface_velocity: key("surface_velocity"),
  air_inlet_temperature: key("air_inlet_temperature"),
  inlet_humidity: key("inlet_humidity"),
  air_outlet_temperature: key("air_outlet_temperature"),
  outlet_humidity: key("outlet_humidity"),
  pressure_drop: key("pressure_drop"),
  fluid: key("fluid"),
  mixture_rate: key("mixture_rate"),
  inlet_outlet_temperature: key("inlet_outlet_temperature"),
  velocity: key("velocity"),
  heat_exchanger_data: key("heat_exchanger_data"),
  tube_volume: key("tube_volume"),
  tube_material: key("tube_material"),
  fin_material: key("fin_material"),
  subcooling: key("subcooling"),
  superheating: key("superheating"),
  condensation_temperature: key("condensation_temperature"),

  fri_material_aluminum: key("fri_material_aluminum"),
  fri_material_copper: key("fri_material_copper"),
  fri_material_epoxy_coated_aluminum: key("fri_material_epoxy_coated_aluminum"),
  fri_material_hydrophilic_coated_aluminum: key("fri_material_hydrophilic_coated_aluminum"),
  fri_material_steel: key("fri_material_steel"),
  fri_material_stainless_steel: key("fri_material_stainless_steel"),
  fri_material_galvanized_steel: key("fri_material_galvanized_steel"),
  fri_material_cupronickel: key("fri_material_cupronickel"),
  fri_material_al_mg: key("fri_material_al_mg"),
  unknown_material: key("unknown_material"),
  mode: (modeName: string) => key(`mode_${modeName}`),
  order_results_by: key("order_results_by"),
  mode_results: (modeName: string) => key(`mode_results_${modeName}`),

  // Project
  projects: key("projects"),
  project_default_name: key("project_default_name"),
  create_project: key("create_project"),
  delete_project: key("delete_project"),
  project_name: key("project_name"),
  project_owner: key("project_owner"),
  project_created: key("project_created"),
  project_last_modified: key("project_last_modified"),
  msg_failed_to_load_project: key("msg_failed_to_load_project"),
  msg_no_project_open: key("msg_no_project_open"),
  msg_project_is_empty: key("msg_project_is_empty"),
  msg_no_projects: key("msg_no_projects"),
  project_remove_item: key("project_remove_item"),
  project_item_col: (columnName: string) => key(`project_item_col_${columnName}`),
  project_column: (columnName: string) => key(`project_column_${columnName}`),
  back_to_selection: key("back_to_selection"),
  remove_item: key("remove_item"),
  duplicate_item: key("duplicate_item"),
  add_item: key("add_item"),
  untitled_project: key("untitled_project"),
  unknown_name: key("unknown_name"),
  item_type: (itemType: "water" | "dx" | "electric" | "unknown") => key(`item_type_${itemType}`),
};
