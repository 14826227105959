export function roundTo<T extends number | undefined>(
  value: T,
  decimals: number
): T extends number ? number : undefined {
  if (value === undefined) {
    return undefined as T extends number ? number : undefined;
  }
  return Number(`${Math.round(parseFloat(`${value}e${decimals}`))}e-${decimals}`) as T extends number
    ? number
    : undefined;
}

export function numberToString(value: number, decimals: number): string {
  return roundTo(value, decimals).toString();
}
