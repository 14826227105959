import { ReportModule, ReportParamsProject, ReportQueryProjectParams } from "../../types";
import * as GQLOps from "../../../generated/generated-operations";
import * as Common from "../../common";

export const Name = "project-first-page";
export type Response = {};

export type CreatorProps = {
  readonly pageProps: Common.CataloguePageProps;
  readonly project: GQLOps.ProjectResponse;
};

export type ReportType = ReportModule<CreatorProps, ReportQueryProjectParams, ReportParamsProject, Response>;
export type AdapterType = ReportType["execute"];
export type CreatorType = ReportType["create"];
