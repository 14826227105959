import React, { useEffect, useRef, useState } from "react";
import { withTw } from "./with-tw";
import { SvgAngleDown } from "./icon";
import { useOnClickOutside, useSelectPositioning, useWindowDimensions } from "./hooks";

const SmallGapRow = withTw(
  "div",
  "w-full flex items-center gap-2 overflow-hidden text-ellipsis",
  ({ align }: { readonly align?: "right" | "left" }) => (align === "right" ? "justify-end" : "")
);

interface DropdownProps {
  readonly valid: boolean;
  readonly disabled?: boolean;
  readonly align?: "right" | "left";
}

const SelectRotateAngleContainer = withTw(
  "div",
  "flex flex-row items-center px-1 h-full text-gray-400",
  ({ up }: { readonly up: boolean }) => (up ? "rotate-180" : " rotate-0")
);

const DropdownButton = withTw(
  "button",
  "form-input user-select-none relative flex justify-between gap-[7px] outline-none w-full",
  "items-center whitespace-nowrap",
  ({ valid }: DropdownProps) => (!valid ? "form-input-danger" : ""),
  ({ align }: DropdownProps) => (align === "left" ? "" : "flex-row-reverse"),
  "enabled:hover:cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed"
);

const ReadonlyDropDown = withTw(
  "div",
  "flex gap-[7px] h-8 overflow-hidden w-full p-2",
  "items-center whitespace-nowrap",
  ({ valid }: DropdownProps) => (!valid ? "form-input-danger" : "")
);

const DropdownImage = withTw("img", "h-8 w-auto");

export const DropdownUnorderedList = withTw(
  "ul",
  "block fixed bg-white list-none m-0 p-0 z-[999]  shadow-md max-h-[350px] overflow-y-auto overflow-x-hidden border"
);

export const DropdownListItem = withTw(
  "li",
  "flex gap-[5px] h-8 items-center whitespace-nowrap p-2 first:border-tr-[5px] last:border-bl-[5px] cursor-pointer hover:bg-[#F5F4F3] aria-selected:bg-[#F5F4F3]",
  ({ valid }: DropdownProps) => (!valid ? "form-input-danger" : "text-neutral"),
  ({ valid }: DropdownProps) => (!valid ? "active:pointer-events-none cursor-not-allowed" : "text-neutral"),
  ({ align }: DropdownProps) => (align === "left" ? "" : "flex-row-reverse")
);

const ReadOnlyContainer = withTw(
  "div",
  "flex pl-2 gap-[7px] h-8  outline-none",
  "bg-white items-center whitespace-nowrap"
  // "enabled:hover:cursor-pointer enabled:hover:bg-[#FCFBFA] disabled:opacity-75 disabled:cursor-not-allowed"
);

export type Option<T> = {
  readonly label: string;
  readonly key: string;
  readonly value: T;
  readonly valid?: boolean;
  readonly imageUrl?: string;
};

export function DropdownInput<T>({
  value,
  options,
  onChange,
  parentScrollToggle,
  disabled,
  align = "left",
}: {
  readonly value: Option<T> | undefined;
  readonly options: ReadonlyArray<Option<T>>;
  readonly onChange: (option: Option<T>) => void;
  readonly parentScrollToggle?: boolean;
  readonly disabled?: boolean;
  readonly align?: "right" | "left";
}): JSX.Element {
  const [isFocus, setIsFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const namespace = "discrete-selector";
  const uListRef = useRef<HTMLUListElement>(undefined!);
  const dropdownButtonRef = useRef<HTMLButtonElement>(undefined!);
  const { width, height } = useWindowDimensions();
  const selectedOption = value && options.find((o) => o.value === value.value);
  const handleClickOutside = (): void => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const select = (newValue: Option<T> | undefined): void => {
    if (newValue !== undefined) {
      onChange(newValue);
    }
    setIsDropdownOpen(false);
  };

  useOnClickOutside(dropdownButtonRef, handleClickOutside);
  useSelectPositioning(isOpen, width, height, dropdownButtonRef, uListRef);
  useEffect(() => {
    if (uListRef.current) {
      handleClickOutside();
    }
  }, [parentScrollToggle]);

  useEffect(() => {
    if (isOpen) {
      return registerOpenDropdownHandlers({
        options,
        activeIndex,
        setActiveIndex,
        select,
      });
    } else if (isFocus) {
      return registerClosedDropdownHandlers({
        setIsDropdownOpen,
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {}; // Return an empty function when isFocus is false
    }
  }, [isOpen, activeIndex, isFocus, namespace]);

  if (disabled) {
    return <ReadOnlyContainer>{selectedOption?.label || ""}</ReadOnlyContainer>;
  }

  const setIsDropdownOpen = (v: boolean): void => {
    if (v) {
      const selected = value ? options.findIndex((o) => o.value === value.value) : -1;
      setActiveIndex(selected < 0 ? 0 : selected);
      if (uListRef.current && isSafari()) {
        requestAnimationFrame(() => {
          uListRef.current.focus();
        });
      }
    } else if (uListRef.current && isSafari()) {
      requestAnimationFrame(() => {
        const previousSibling = uListRef.current.previousSibling;

        if (previousSibling instanceof HTMLElement) {
          previousSibling.focus();
        }
      });
    }
    setIsOpen(v);
  };

  return options.length > 1 ? (
    <DropdownButton
      onMouseDown={(e) => {
        if (e.button === 0 && !uListRef.current?.contains(e.target as Node)) {
          e.button === 0 && setIsOpen(!isOpen);
        }
      }}
      valid={selectedOption?.valid !== false}
      elementref={dropdownButtonRef}
      role="combobox"
      aria-autocomplete="none"
      aria-haspopup="listbox"
      aria-controls={`${namespace}_dropdown`}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      aria-expanded={isOpen}
      aria-activedescendant={`${namespace}_element_${selectedOption?.value || ""}`}
      disabled={disabled}
      align={align}
    >
      <SmallGapRow align={align}>
        {/* {sel.selectedItem.image && <DropdownImage src={getImageUrl(activeUser, sel.selectedItem.image)} />} */}
        {selectedOption ? (selectedOption?.valid !== false ? selectedOption?.label : `✘ ${selectedOption?.label}`) : ""}
      </SmallGapRow>
      <SelectRotateAngleContainer up={isOpen}>
        <SvgAngleDown width="0.8rem" height="0.8rem" fill="#00000" />
      </SelectRotateAngleContainer>
      {isOpen && (
        <DropdownUnorderedList elementref={uListRef} role="listbox" id={`${namespace}_dropdown`}>
          {options.map((o, index) => (
            <DropdownListItem
              aria-selected={index === activeIndex}
              onMouseOver={() => setActiveIndex(index)}
              key={o.key}
              disabled={disabled}
              align={align}
              onMouseUp={(e) => {
                if (e.button !== 0) {
                  return;
                }
                if (o.valid !== undefined && !o.valid) {
                  return;
                }
                onChange(o);
                setIsOpen(false);
              }}
              valid={o.valid !== false}
            >
              {o.imageUrl && <DropdownImage src={o.imageUrl} />}
              {o.valid !== false ? o.label : `✘ ${o.label}`}
            </DropdownListItem>
          ))}
        </DropdownUnorderedList>
      )}
    </DropdownButton>
  ) : (
    <ReadonlyDropDown valid={selectedOption?.valid !== false} disabled={disabled}>
      {selectedOption ? (selectedOption?.valid !== false ? selectedOption?.label : `✘ ${selectedOption?.label}`) : ""}
    </ReadonlyDropDown>
  );
}

const isSafari = (): boolean => {
  const chromeInAgent = navigator.userAgent.indexOf("Chrome") > -1;
  const safariInAgent = navigator.userAgent.indexOf("Safari") > -1;
  return safariInAgent && !chromeInAgent;
};

const registerClosedDropdownHandlers = ({
  setIsDropdownOpen,
}: {
  readonly setIsDropdownOpen: (v: boolean) => void;
}): (() => void) => {
  const keyDownCallback = (e: KeyboardEvent): void => {
    switch (e.key) {
      case "Up":
      case "ArrowUp":
      case "Down":
      case "ArrowDown":
      case " ": // Space
      case "Enter":
        e.preventDefault();
        setIsDropdownOpen(true);
        break;
      default:
        break;
    }
  };
  document.addEventListener("keydown", keyDownCallback);
  return () => {
    document.removeEventListener("keydown", keyDownCallback);
  };
};

function registerOpenDropdownHandlers<T>({
  options,
  activeIndex,
  setActiveIndex,
  select,
}: {
  readonly options: ReadonlyArray<Option<T>>;
  readonly activeIndex: number;
  readonly setActiveIndex: (i: number) => void;
  readonly select: (option: Option<T> | undefined) => void;
}): () => void {
  const optionsLength = options.length;
  const keyDownCallback = (e: KeyboardEvent): void => {
    e.preventDefault();
    switch (e.key) {
      case "Up":
      case "ArrowUp":
        e.preventDefault();
        setActiveIndex(activeIndex <= 0 ? optionsLength - 1 : activeIndex - 1);
        return;
      case "Down":
      case "ArrowDown":
        e.preventDefault();
        setActiveIndex(activeIndex + 1 === optionsLength ? 0 : activeIndex + 1);
        return;
      case "Enter":
      case " ": {
        // Space
        e.preventDefault();
        select(options[activeIndex]);
        return;
      }
      case "Esc":
      case "Escape":
        e.preventDefault();
        select(undefined);
        return;
      case "PageUp":
      case "Home":
        e.preventDefault();
        setActiveIndex(0);
        return;
      case "PageDown":
      case "End":
        e.preventDefault();
        setActiveIndex(optionsLength - 1);
        break;
      default:
        break;
    }
  };

  document.addEventListener("keydown", keyDownCallback);
  return () => {
    document.removeEventListener("keydown", keyDownCallback);
  };
}
