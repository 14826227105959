import { Connection, Model, Type } from "./types";
import { VeabConstants } from "./constants";
import { getBendLengthsRegulator, getBendLengthsWithoutRegulator, LengthTable } from "./get-element-data";
import { ElementTableRows } from "./get-tables";

export function calc_depth(
  widthMm: number,
  heightMm: number,
  powerKw: number,
  voltageV: number,
  phases: number,
  powerStepsKw: ReadonlyArray<number>,
  type: Type,
  elementTable: ReadonlyArray<ElementTableRows>,
  model: Model
): CalculateDepthResult | undefined {
  const elementVoltage = getElementVoltage(voltageV, phases);

  if (!elementVoltage) {
    return undefined;
  }

  if (type === Type.M || type === Type.ML) {
    return calculateDepthWithoutRegulator(
      heightMm,
      widthMm,
      elementVoltage.elementVoltage,
      elementVoltage.minimumElements,
      powerStepsKw,
      elementTable
    );
  }

  //MTXL
  return calculateDepthWithRegulator(
    heightMm,
    widthMm,
    elementVoltage.elementVoltage,
    elementVoltage.minimumElements,
    powerKw,
    elementTable,
    voltageV,
    phases,
    model
  );
}

function addExtraDepthMTEMLMTULOver40Amp(type: Type, calculatedDepthMm: number, heightMm: number, Amp: number): number {
  let depthMm = calculatedDepthMm;
  const heightLessThan400 = heightMm < 400;

  const isMTEMLorMTUL = type === Type.MTEML || type === Type.MTUL;
  const AmpGreaterThan40 = Amp > 40;

  if (heightLessThan400 && isMTEMLorMTUL && AmpGreaterThan40) {
    depthMm += 180;
  }

  return depthMm;
}

function roundVFLVFLPG(
  widthMm: number,
  type: Type,
  calculatedDepthMm: number,
  connection: Connection,
  powerW: number,
  voltageV: number,
  minimumElements: number
): number {
  let depthMm = calculatedDepthMm;
  const VFLVFLPGDepth_Add = 70;

  const withRegulator = type !== Type.M && type === Type.ML;
  const coolingProfileLimit = getCoolingProfileLimit(powerW, voltageV, minimumElements);

  if (connection === Connection.Circular) {
    let InletCalculationValue = VeabConstants.Inlet + VFLVFLPGDepth_Add / 2;
    const OutletCalculationValue = VeabConstants.Outlet + VFLVFLPGDepth_Add / 2;

    if (withRegulator) {
      if (powerW <= coolingProfileLimit) {
        InletCalculationValue += VeabConstants.CoolingProfile_Small;
      } else {
        InletCalculationValue += VeabConstants.CoolingProfile_Big;
      }
    }

    let DepthValue_B = 0;
    let DepthValue_A = 0;

    if (widthMm / 3 > OutletCalculationValue) {
      DepthValue_B = widthMm / 3 - OutletCalculationValue;
    }

    if (widthMm / 3 > InletCalculationValue) {
      DepthValue_A = widthMm / 3 - InletCalculationValue;
    }

    depthMm += VFLVFLPGDepth_Add + DepthValue_B + DepthValue_A;
  } else {
    depthMm += VFLVFLPGDepth_Add;
  }

  if (depthMm <= 370) {
    depthMm = 370;
  } else if (depthMm <= 500) {
    depthMm = 500;
  } else if (depthMm <= 600) {
    depthMm = 600;
  } else if (depthMm <= 700) {
    depthMm = 700;
  } else if (depthMm <= 800) {
    depthMm = 800;
  } else if (depthMm <= 900) {
    depthMm = 900;
  } else if (depthMm <= 1000) {
    depthMm = 1000;
  } else if (depthMm <= 1100) {
    depthMm = 1100;
  } else if (depthMm <= 1200) {
    depthMm = 1200;
  } else if (depthMm <= 1300) {
    depthMm = 1300;
  } else if (depthMm <= 1400) {
    depthMm = 1400;
  } else if (depthMm <= 1500) {
    depthMm = 1500;
  } else if (depthMm <= 1600) {
    depthMm = 1600;
  } else if (depthMm <= 1700) {
    depthMm = 1700;
  } else if (depthMm <= 1800) {
    depthMm = 1800;
  } else if (depthMm <= 1900) {
    depthMm = 1900;
  } else if (depthMm <= 2000) {
    depthMm = 2000;
  } else if (depthMm <= 2100) {
    depthMm = 2100;
  } else if (depthMm <= 2200) {
    depthMm = 2200;
  } else if (depthMm <= 2300) {
    depthMm = 2300;
  } else if (depthMm <= 2400) {
    depthMm = 2400;
  } else if (depthMm <= 2500) {
    depthMm = 2500;
  } else if (depthMm <= 2600) {
    depthMm = 2600;
  } else if (depthMm <= 2700) {
    depthMm = 2700;
  } else if (depthMm <= 2800) {
    depthMm = 2800;
  } else if (depthMm <= 2900) {
    depthMm = 2900;
  } else if (depthMm <= 3000) {
    depthMm = 3000;
  }

  return depthMm;
}

export function getDepthForVFLVFLPG(
  widthMm: number,
  type: Type,
  calculatedDepthMm: number,
  heightMm: number,
  current: number,
  connection: Connection,
  powerW: number,
  voltageV: number,
  minimumElements: number
): number {
  const depth = addExtraDepthMTEMLMTULOver40Amp(type, calculatedDepthMm, heightMm, current);
  return roundVFLVFLPG(widthMm, type, depth, connection, powerW, voltageV, minimumElements);

  // let depthMm = calculatedDepthMm + 70;
  // const heightLessThan801 = heightMm < 801;

  // const isMTEML = type === Type.MTEML;
  // const effektGreaterThan27 = powerKw > 27;

  // if (depthMm <= 370) {
  //   depthMm = heightLessThan801 && isMTEML ? 500 : 370;
  // } else if (depthMm <= 500) {
  //   depthMm = depthMm && isMTEML && effektGreaterThan27 ? 600 : 500;
  // } else if (depthMm <= 600) {
  //   depthMm = heightLessThan801 && isMTEML && effektGreaterThan27 ? 700 : 600;
  // } else if (depthMm <= 700) {
  //   depthMm = heightLessThan801 && isMTEML && effektGreaterThan27 ? 800 : 700;
  // } else if (depthMm <= 800) {
  //   depthMm = heightLessThan801 && isMTEML && effektGreaterThan27 ? 900 : 800;
  // } else if (depthMm <= 900) {
  //   depthMm = heightLessThan801 && isMTEML && effektGreaterThan27 ? 1000 : 900;
  // } else if (depthMm <= 1000) {
  //   depthMm = heightLessThan801 && isMTEML && effektGreaterThan27 ? 1100 : 1000;
  // } else if (depthMm <= 1100) {
  //   depthMm = heightLessThan801 && isMTEML && effektGreaterThan27 ? 1200 : 1100;
  // } else if (depthMm <= 1200) {
  //   depthMm = heightLessThan801 && isMTEML && effektGreaterThan27 ? 1300 : 1200;
  // } else if (depthMm <= 1300) {
  //   depthMm = heightLessThan801 && isMTEML && effektGreaterThan27 ? 1400 : 1300;
  // }

  // return depthMm;
}

export type CalculateDepthResult = {
  readonly depthMm: number;
  readonly electricalElements: number;
  readonly articleNumber: string;
};

function calculateDepthWithoutRegulator(
  heightMm: number,
  widthMm: number,
  elementVoltage: 400 | 230,
  minimumElements: number,
  powerStepsKw: ReadonlyArray<number>,
  elementTable: ReadonlyArray<ElementTableRows>
): CalculateDepthResult | undefined {
  const lengths = getBendLengthsWithoutRegulator(elementVoltage, widthMm, elementTable);
  if (!lengths || lengths.length === 0) {
    return undefined;
  }
  const bendsAndElectricalElements =
    lengths && getBendsAndElectricalElements(widthMm, heightMm, minimumElements, powerStepsKw, lengths);
  if (!bendsAndElectricalElements) {
    return undefined;
  }
  const depthMm =
    bendsAndElectricalElements && calculateDepthFromBends(heightMm, bendsAndElectricalElements.elementBends);
  return {
    depthMm: depthMm ?? 0,
    electricalElements: bendsAndElectricalElements.numberElectricalElements,
    articleNumber: bendsAndElectricalElements.articleNumber,
  };
}

function getCoolingProfileLimit(_powerW: number, voltageV: number, minimumElements: number): number {
  if (voltageV === 230 && minimumElements === 3) {
    return 9936;
  }
  return 17280;
}

function calculateDepthWithRegulator(
  heightMm: number,
  widthMm: number,
  elementVoltage: 400 | 230,
  minimumElements: number,
  powerKw: number,
  elementTable: ReadonlyArray<ElementTableRows>,
  voltageV: number,
  phases: number,
  model: Model
): CalculateDepthResult | undefined {
  const powerW = powerKw * 1000;

  const coolingProfileLimit = getCoolingProfileLimit(powerKw * 1000, voltageV, minimumElements);

  let steps = 0;

  if ((voltageV === 230 && minimumElements === 1) || (voltageV === 400 && minimumElements === 1)) {
    steps = Math.ceil(powerW / voltageV / 40);
  } else {
    steps = Math.ceil(powerW / voltageV / Math.sqrt(3) / 40);
  }

  // //Räknar ut antal steg och avrundar uppåt. 7 Steg kan inte göras, så därför blir det 8 steg.
  // if ($markspanning == 1 || $markspanning == 2) {

  //     $steg = ceil(($effekt_onskad / $Power) / 40); //Önskad effekt delat på Spänning. Blir strömmen.

  // } else {

  //     $steg = ceil((($effekt_onskad / $Power) / sqrt(3)) / 40); //Önskad effekt delat på Spänning, sen delat på roten ur 3 pga 3 fas. Blir strömmen.

  // }

  const coolingProfile =
    powerW <= coolingProfileLimit ? VeabConstants.CoolingProfile_Small : VeabConstants.CoolingProfile_Big;

  // let steps = Math.ceil(powerW / 27000);

  // if (voltageV === 230 && minimumElements === 3) {
  //   steps = Math.ceil(powerW / 15000);
  // }

  if (steps === 7) {
    //7 Steps can not be made, so increase it to 8
    steps = 8;
  }
  const powerPerStep = powerW / steps;
  const { componentHeight, VH } = calculateComponentArea(powerKw, voltageV, phases);
  const bridgesForDepth = calculateBridgesForDepth(heightMm, componentHeight, VH);
  const bridgeDepth = calculateBridgeDepth(heightMm, componentHeight, VH, powerKw, phases, voltageV);
  // TODO - Possible wrong in veabs code. (Diffrent code for api and for website. )
  const minCalculatedElements = minimumElements;
  let elementsFound = 0;
  let elements = minimumElements;
  let elementFirstFound: number | undefined = undefined;
  let maxRows = 0;
  const depthResult: Array<{
    readonly finalDepth: number;
    readonly length: number;
    readonly articleNumber: string;
    readonly numberOfElements: number;
  }> = [];
  const depths = [];
  while (elementsFound <= 4) {
    const minElementsPower = powerPerStep / elements;
    const maxPowerW = Math.ceil(minElementsPower * 1.04);

    const minPowerW = Math.floor(minElementsPower * 0.96);

    const lengths = getBendLengthsRegulator(elementVoltage, widthMm, minPowerW, maxPowerW, elementTable) || [];

    for (const length of lengths) {
      const bends = findAmountBends(widthMm, length.length);
      const elementsPerRow = findElementsPerRow(heightMm, bends);

      const rowMinusOne = Math.ceil((steps * elements) / elementsPerRow);

      const depthMeasurement =
        VeabConstants.Inlet + VeabConstants.Outlet + VeabConstants.Distance_Between_Each_ElementRow * rowMinusOne;

      let depthMeasurementFinal =
        depthMeasurement + coolingProfile >= bridgesForDepth ? depthMeasurement + coolingProfile : bridgesForDepth;

      if (bridgeDepth > depthMeasurement + coolingProfile && bridgeDepth < depthMeasurementFinal) {
        depthMeasurementFinal = bridgeDepth;
      }

      depthMeasurementFinal = Math.max(
        depthMeasurementFinal,
        VeabConstants.Minimum_Published_Deep_Measurement_With_Regulator
      );
      depthResult.push({
        articleNumber: length.article_number,
        length: length.length,
        finalDepth: depthMeasurementFinal,
        numberOfElements: elements,
      });
      depths.push(depthMeasurementFinal);
      if (elementsPerRow >= 1) {
        if (!elementFirstFound) {
          elementFirstFound = elements;
        }
        elementsFound += lengths.length;
      }
    }

    // Exists when more than 50 loops
    maxRows += 1;
    if (maxRows >= 50) {
      elementsFound = 6;
    }

    elements += minCalculatedElements;
    if (elementFirstFound) {
      if (elements > elementFirstFound * 1.9) {
        elementsFound = 6;
      }
    }
  }
  if (depthResult.length === 0) {
    return undefined;
  }
  const minDepthResult = depthResult.reduce((prev, curr) => {
    return prev.finalDepth < curr.finalDepth ? prev : curr;
  });

  // let maxDepth = Math.min(...depthResult.map((r) => r.finalDepth));
  // const filteredArray = depthResult.filter((r) => r.finalDepth === maxDepth);
  // const lastElement = filteredArray[filteredArray.length - 1];
  // const articleNumberUsed = lastElement?.articleNumber ?? "";

  let maxDepth = Math.ceil(minDepthResult.finalDepth / 10) * 10;

  // console.log("model", model, Model["VRA-FU"]);

  if (model === Model["VRA-FU"] || model === Model.VTL) {
    //Add 30 depth if width or height is over 1400, due to reinforcement corner must be put there
    const maxSize = 1400;

    // console.log("here", widthMm, heightMm, widthMm > maxSize || heightMm > maxSize);

    if (widthMm > maxSize || heightMm > maxSize) {
      maxDepth += 30;
    }
  }

  return {
    depthMm: isInfinity(maxDepth) ? 0 : maxDepth,
    electricalElements:
      isInfinity(maxDepth) || elementFirstFound === undefined ? 0 : minDepthResult.numberOfElements * steps, // elements - minimumElements,
    articleNumber: minDepthResult.articleNumber ?? "",
  };
}

function isInfinity(value: number): boolean {
  return value === Infinity || value === -Infinity;
}
function getElementVoltage(
  voltage: number,
  phases: number
):
  | {
      readonly elementVoltage: 400 | 230;
      readonly minimumElements: number;
    }
  | undefined {
  let voltageType = 1;

  if (voltage === 230 && phases === 1) {
    // -- ej rot 3
    voltageType = 1;
  } else if (voltage === 230 && phases === 3) {
    voltageType = 3;
  } else if (voltage === 400 && phases === 2) {
    // -- ej rot 3
    voltageType = 2;
  } else if (voltage === 400 && phases === 3) {
    voltageType = 3;
  } else {
    return undefined;
  }

  if (voltageType === 1) {
    return { elementVoltage: 230, minimumElements: 1 }; //-- ej rot 3
  } else if (voltageType === 2) {
    return { elementVoltage: 400, minimumElements: 1 }; //-- ej rot 3
  } else {
    return { elementVoltage: 230, minimumElements: 3 };
  }
}

export type GetAmountElementBendsResult = {
  readonly elementBends: ReadonlyArray<number>;
  readonly numberElectricalElements: number;
  readonly articleNumber: string;
};

function getBendsAndElectricalElements(
  width: number,
  height: number,
  minimumElement: number,
  powerStepsKw: ReadonlyArray<number>,
  elementLengths: ReadonlyArray<LengthTable>
): GetAmountElementBendsResult | undefined {
  const elementBends = [];
  const electricalElements = [];
  if (!elementLengths) {
    return undefined;
  }
  //let elementsUsed = 0;
  let articleNumbersUsed: string = "";
  for (const powerStepKw of powerStepsKw) {
    const elementPowerW = powerStepKw * 1000;
    let perElementPower = minimumElement; //1 or 3
    let percent = 0.04;
    let effekt_max = 346;
    let effekt_min = 320;
    if (elementPowerW < 1900) {
      percent = 0.3;
    } else if (elementPowerW < 5000) {
      percent = 0.15;
    } else {
      percent = 0.04;
    }

    let elementFound = false;
    while (!elementFound) {
      if (elementPowerW >= 1000) {
        const element_effekt = (powerStepKw * 1000) / perElementPower; //per element, depends on 1, 2 or 3 phase.
        effekt_min = Math.floor(element_effekt * (1 - percent));
        effekt_max = Math.ceil(element_effekt * (1 + (percent === 0.04 ? 0.09 : percent)));
      }

      let elementBendsFound = 0;
      for (const elementLength of [...elementLengths].sort((a, b) => a.power - b.power)) {
        if (elementLength.power >= effekt_min && elementLength.power <= effekt_max) {
          const elementBends = findAmountBends(width, elementLength.length);
          const elementBendSize =
            elementBends * VeabConstants.CC_Measurement_Per_Bend +
            VeabConstants.Distance_First_Element_To_Edge +
            VeabConstants.Distance_Between_Each_Element;
          if (elementBendSize <= height) {
            elementFound = true;
            elementBendsFound = elementBends;
            articleNumbersUsed = elementLength.article_number;
            //elementsUsed += minimumElement;
            break;
          }
        }
      }

      if (elementBendsFound !== 0) {
        for (let elementSave = 0; elementSave < perElementPower; elementSave++) {
          elementBends.push(elementBendsFound * VeabConstants.CC_Measurement_Per_Bend);
        }
      }
      if (perElementPower > 30) {
        //"Can't find any possible choices. Please change Height, width or kW.";
        return undefined;
      }
      if (!elementFound) {
        perElementPower += minimumElement;
      }
    }
    electricalElements.push(perElementPower);
  }

  // console.log(electricalElements);
  return {
    elementBends: elementBends.sort((a, b) => b - a),
    numberElectricalElements: electricalElements.reduce((sum, curr) => sum + curr, 0),
    articleNumber: articleNumbersUsed,
  };
}

// dmb/funktioner.php  U_Bockningar
function calculateDepthFromBends(heightMm: number, bends: ReadonlyArray<number>): number | undefined {
  const marginMm = VeabConstants.Distance_First_Element_To_Edge; //from corner
  const spacingMm = VeabConstants.Distance_Between_Each_Element;
  const rowSpacingMm = VeabConstants.Distance_Between_Each_ElementRow;
  const initialDepthMm = VeabConstants.Minimum_Start_Deep_Measurement_Without_Regulator;
  const minimumDepthMm = VeabConstants.Minimum_Published_Deep_Measurement_Without_Regulator;

  const rowHeightMm = heightMm - marginMm;

  if (!(bends[0] < rowHeightMm)) {
    //height is too small
    return undefined;
  }

  const rowsRemainingHeightMm = [];
  const bendsWithSpacing = bends.map((b) => b + spacingMm);
  for (const bendHeightMm of bendsWithSpacing) {
    const rowIndex = rowsRemainingHeightMm.findIndex((row) => row >= bendHeightMm);
    if (rowIndex === -1) {
      rowsRemainingHeightMm.push(rowHeightMm - bendHeightMm);
    } else {
      rowsRemainingHeightMm[rowIndex] -= bendHeightMm;
    }
  }

  const depthMm = Math.max(minimumDepthMm, initialDepthMm + (rowsRemainingHeightMm.length - 1) * rowSpacingMm);
  return depthMm;
}

function findAmountBends(widthMm: number, lengthMm: number): number {
  //A fixed value for what a C/C 30 bend takes from the length of the element.
  const ccMm = VeabConstants.Material_Usage_Of_Element_CC_Bend;
  //The value that defines the "C Dimension", i.e. the distance between the bend and the upper/lower edge of the width dimension in the channel.
  const widthMarginMm = VeabConstants.C_Measurement_Element;
  //The value that the element nipple "takes" from the length of the element. Times 2.
  const widthElementNippleMm = VeabConstants.Element_Nipple * 2; // 15*2

  const widthInAndOutMarginMm = widthMarginMm * 2;
  let bends = 1;
  const elementLengthMm = lengthMm - widthInAndOutMarginMm - widthElementNippleMm;
  const widthNoMarginMm = widthMm - widthInAndOutMarginMm;

  while (widthNoMarginMm < (elementLengthMm - bends * ccMm) / (bends + 1)) {
    bends += 2;
  }
  return bends;
}

// dmb/functioner.php Element_per_Rad
function findElementsPerRow(heightMm: number, bends: number): number {
  let elementsPerRow = 0;
  let remainingArea =
    heightMm - VeabConstants.Distance_Between_Each_Element - VeabConstants.Distance_First_Element_To_Edge;

  const length = bends * VeabConstants.CC_Measurement_Per_Bend;
  while (remainingArea > length) {
    elementsPerRow += 1;
    remainingArea = remainingArea - VeabConstants.Distance_Between_Each_Element - length;
  }

  return elementsPerRow;
}

// dmb/komponent.php Komponent_Yta
function calculateComponentArea(
  powerkW: number,
  voltageV: number,
  phases: number
): { readonly componentHeight: number; readonly VH: number } {
  const components = [
    VeabConstants.Component_JJG16,
    VeabConstants.Component_JJG35,
    VeabConstants.Component_J70,
    VeabConstants.Component_VH95,
    VeabConstants.Component_VH150,
    VeabConstants.Component_VH240,
    VeabConstants.Component_Contactors,
    VeabConstants.Component_Signal_Converter,
    VeabConstants.Component_Transformer,
    VeabConstants.Component_Step_Switch,
    VeabConstants.Component_Automatic_Fuses,
    VeabConstants.Component_M8M12_Ground_Bolts,
  ];

  //$component_size = array(12,16,21,32,42,42,45,48,35,105,18,40); //Managed by config_varden.php from 2020-12-07.
  //+120 on all for 8x row terminal and ground plate.
  //+65 on all as margin measure.
  //at >96 amp VH 95 Depth should be (55+55) and VH150/240 Depth should be (65+65).

  let amp = 0;
  const powerW = powerkW * 1000;
  if ((voltageV === 230 && phases === 1) || (voltageV === 400 && phases === 2)) {
    amp = Math.ceil(powerW / voltageV);
  } else {
    amp = Math.ceil(powerW / voltageV / Math.sqrt(3));
  }

  let componentAmounts: Array<number> = [];
  if (amp >= 1.3 && amp <= 25) {
    componentAmounts = [4, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0];
  } else if (amp > 25 && amp <= 40) {
    componentAmounts = [4, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0];
  } else if (amp > 40 && amp <= 63) {
    componentAmounts = [0, 4, 0, 0, 0, 0, 2, 1, 1, 1, 0, 0];
  } else if (amp > 63 && amp <= 80) {
    componentAmounts = [0, 0, 3, 0, 0, 0, 2, 1, 1, 1, 6, 1];
  } else if (amp > 80 && amp <= 96) {
    componentAmounts = [0, 0, 3, 0, 0, 0, 3, 1, 1, 1, 9, 1];
  } else if (amp > 96 && amp <= 120) {
    componentAmounts = [0, 0, 0, 3, 0, 0, 3, 1, 1, 1, 9, 1];
  } else if (amp > 120 && amp <= 128) {
    componentAmounts = [0, 0, 0, 3, 0, 0, 4, 1, 1, 1, 12, 1];
  } else if (amp > 128 && amp <= 160) {
    componentAmounts = [0, 0, 0, 0, 3, 0, 4, 1, 1, 1, 12, 1];
  } else if (amp > 160 && amp <= 200) {
    componentAmounts = [0, 0, 0, 0, 0, 3, 5, 1, 1, 1, 15, 1];
  } else if (amp > 200 && amp <= 240) {
    componentAmounts = [0, 0, 0, 6, 0, 0, 6, 1, 1, 1, 18, 1];
  } else if (amp > 240 && amp <= 256) {
    componentAmounts = [0, 0, 0, 6, 0, 0, 8, 1, 1, 1, 24, 1];
  } else if (amp > 256 && amp <= 320) {
    componentAmounts = [0, 0, 0, 0, 6, 0, 8, 1, 1, 1, 24, 1];
  }

  let componentIndex = 0;
  let componentHeight = 0;
  let VH = 0;
  while (componentIndex < 3) {
    if (componentAmounts[componentIndex] * components[componentIndex] !== 0) {
      componentHeight +=
        (componentAmounts[componentIndex] * components[componentIndex] + VeabConstants.Component_JJG_Terminal) * 2;
    }
    componentIndex++;
  }

  while (componentIndex < 6) {
    if (componentAmounts[componentIndex] * components[componentIndex] !== 0) {
      componentHeight +=
        (componentAmounts[componentIndex] * components[componentIndex] +
          VeabConstants.Component_VH95VH150VH240_Terminal) *
        2;
      if (componentIndex === 4) {
        VH = VeabConstants.Component_VH95_Protection;
      } else {
        VH = VeabConstants.Component_VH150240_Protection;
      }
    }
    componentIndex++;
  }

  while (componentIndex < 12) {
    if (componentAmounts[componentIndex] * components[componentIndex] !== 0) {
      componentHeight +=
        componentAmounts[componentIndex] * (components[componentIndex] + VeabConstants.Component_Minimum_Spacing);
    }
    componentIndex++;
  }

  componentHeight =
    componentHeight +
    VeabConstants.Component_Bridge_Connection_Terminal_Grounding +
    VeabConstants.Component_Bridge_Constant_Extra_Value;

  return { componentHeight, VH };
}

// dmb/komponent.php DjupMatt_Bryggor
function calculateBridgesForDepth(depth: number, componentHeight: number, VH: number): number {
  const numberOfBridges = Math.ceil(componentHeight / depth);

  // const bridgesWidth = VeabConstants.Component_Bridge_Width * numberOfBridges; // TODO - not used in veabs code. Should be used

  const bridgeSpacing = (numberOfBridges > 1 ? VeabConstants.Component_Bridge_SpaceBetween : 0) * (numberOfBridges - 1);

  const depthMeasurement =
    VeabConstants.Component_Bridge_Customer_Connection_Opposite_side +
    bridgeSpacing +
    VeabConstants.Component_Bridge_Customer_Connection +
    VH * 2;

  return depthMeasurement;
}

// dmb/komponent.php DjupMatt_Bryggor_Djup
//Function to calculate whether you can have the piers on the depth measurement instead of the height measurement.
function calculateBridgeDepth(
  height: number,
  componentHeight: number,
  VH: number,
  powerkW: number,
  phases: number,
  voltageV: number
): number {
  let contacts = 0;
  let components = 0;

  let amp = 0;

  const powerW = powerkW * 1000;
  if ((voltageV === 230 && phases === 1) || (voltageV === 400 && phases === 2)) {
    amp = Math.ceil(powerW / voltageV);
  } else {
    amp = Math.ceil(powerW / voltageV / Math.sqrt(3));
  }

  if (amp >= 1.3 && amp <= 40) {
    contacts = 1;
    components = 53;
  } else if (amp > 40 && amp <= 80) {
    contacts = 2;
    components = 203;
  } else if (amp > 80 && amp <= 120) {
    contacts = 3;
    components = 203;
  } else if (amp > 120 && amp <= 160) {
    contacts = 4;
    components = 203;
  } else if (amp > 160 && amp <= 200) {
    contacts = 5;
    components = 203;
  } else if (amp > 200 && amp <= 240) {
    contacts = 6;
    components = 203;
  } else if (amp > 240 && amp <= 320) {
    contacts = 8;
    components = 203;
  }

  const A = VeabConstants.Component_Bridge_Customer_Connection_Opposite_side; //The side at the back from the customer's connection/terminals
  const B = VeabConstants.Component_Bridge_SpaceBetween; //Between bridges
  const C = VeabConstants.Component_Bridge_Customer_Connection; //The customer's connection side to the terminal
  // const D = VeabConstants.Component_Bridge_Width; // TODO-  not used in veab should be used. //The width of the pier
  const flangeHeight = VeabConstants.VRA_Type_Height;
  const extraHeightBehindContactor = 0;

  const Bryggor_2 = A + 0 + B + 0 + C + VH * 2; //2 st (406) + VH = 110/130 (516/536)
  const Bryggor_3 = A + 0 + B + 0 + B + 0 + C + VH * 2; //3 st (594) + VH = 110/130 (704/724)

  const inletHeight = powerkW > 121 ? 180 - VH : 90 - VH;

  if (height - flangeHeight - inletHeight - extraHeightBehindContactor >= Bryggor_3) {
    return contacts * (VeabConstants.Component_Contactors + VeabConstants.Component_Minimum_Spacing) + components + 20;
  }
  if (height - flangeHeight - inletHeight - extraHeightBehindContactor >= Bryggor_2) {
    return Math.ceil(componentHeight / 2);
  }
  return 0;
}
