import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import { NonNull } from "../utils";
import { ProductVariantsRow, SearchResultColumn, SearchResultColumnTable } from "./query";
import * as Types from "./types";
import { Calculate, CalculatorFrtCoil, CalculatorResult, GetFieldFormatFn, Texts } from "..";
import { getSortableValue, extractResultValue } from "../calculator-result";
import { ActiveUser, isInternalUser } from "../user";

export type ResultTable = {
  readonly columns: ReadonlyArray<ResultColumn>;
  readonly rows: ReadonlyArray<ResultRow>;
};

export type ResultColumn = {
  readonly key: string;
  readonly type: "meta" | "mode" | "result" | "variant_column" | "price";
  readonly label: string;
  readonly fieldName: string | undefined;
  readonly intialSortOrder: SortOrder;
};

export type ResultRow = {
  readonly key: string;
  readonly model: string;
  readonly group: string;
  readonly productKey: string;
  readonly productId: string;
  readonly selectionKey: string;
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly messages: ReadonlyArray<ResultMessage>;
  readonly availableModeResults: ReadonlyArray<ModeResult>;
  readonly values: ReadonlyArray<ResultColumnValue>;
};

export type ModeResult = "heating" | "cooling";

export const modeResults: ReadonlyArray<ModeResult> = ["heating", "cooling"];

export type ResultMessage = {
  readonly type: "warning" | "error";
  readonly text: string;
};

const metaColumnTypes = ["model", "warnings", "errors"] as const;
export type MetaColumnType = (typeof metaColumnTypes)[number];

export type ResultColumnValue =
  | {
      readonly type: "meta";
      readonly sortNo: number | string;
      readonly value: MetaColumnType;
    }
  | {
      readonly type: "mode";
      readonly sortNo: number | string;
      readonly heating: string;
      readonly cooling: string;
    }
  | {
      readonly type: "result";
      readonly sortNo: number | string;
      readonly heating: string;
      readonly cooling: string;
    }
  | {
      readonly type: "variant_column";
      readonly sortNo: number | string;
      readonly value: string;
    }
  | {
      readonly type: "price";
      readonly sortNo: number | string;
      readonly value: string;
    };

export type SortOrder = "desc" | "asc";

export function createTableResults(
  translate: Texts.TranslateFn,
  getFieldFormat: GetFieldFormatFn,
  properties: PropertyValueSet.PropertyValueSet,
  searchResultColumnTable: SearchResultColumnTable,
  result: Types.Result,
  calculationResult: Calculate.CalculationResultByKey,
  sortByColumnKey: string,
  sortOrder: SortOrder,
  orderByMode: ModeResult,
  activeUser: ActiveUser
): ResultTable {
  const cols = searchResultColumnTable
    .filter((r) => PropertyFilter.isValid(properties, r.property_filter))
    .filter((col) => isInternalUser(activeUser.claims) || col.type !== "price");

  const rows: Array<ResultRow> = [];
  for (let i = 0; i < result.matches.length; i++) {
    const match = result.matches[i];
    const res = calculationResult.get(match.selectionKey);
    const values = cols.map((col) => createColValue(orderByMode, getFieldFormat, match, col, res, i));
    const calcMessages =
      res?.type === "Ok"
        ? [...(res.value.heating?.messages || []), ...(res.value.cooling?.messages || [])]
        : res?.error.messages || [];
    const messages = calcMessages.map(
      (m): ResultMessage => ({
        type: m.type,
        text: translate(m.text, m.textFallback),
      })
    );

    const availableModeResults: Array<ModeResult> = [];
    for (const m of modeResults) {
      for (const v of values) {
        if (v.type !== "result") {
          continue;
        }
        if (v[m] !== "" && v[m] !== "-") {
          availableModeResults.push(m);
          break;
        }
      }
    }
    if (availableModeResults.length === 0) {
      availableModeResults.push(orderByMode);
    }

    rows.push({
      key: match.model,
      model: match.model,
      group: match.selection.pm_model,
      productKey: match.selection.productKey,
      productId: match.selection.productId,
      variant: match.variant,
      selectionKey: match.selectionKey,
      messages: messages,
      availableModeResults: availableModeResults,
      values: values,
    });
  }

  const columns = cols.map(
    (col): ResultColumn => ({
      key: col.value,
      type: col.type as "meta" | "mode" | "result" | "variant_column" | "price",
      label: col.text_key ? translate(Texts.key(col.text_key)) : "",
      fieldName: col.field_name,
      intialSortOrder: col.sort_order === "desc" ? "desc" : "asc",
    })
  );

  const sortColIndex = columns.findIndex((col) => col.key === sortByColumnKey);
  const sortedRows =
    sortColIndex === -1
      ? rows
      : [...rows].sort((a, b) => {
          const aValue = a.values[sortColIndex];
          const bValue = b.values[sortColIndex];
          const aSortNo = aValue.sortNo;
          const bSortNo = bValue.sortNo;
          let res = 0;
          if (
            aValue.type === "meta" &&
            bValue.type === "meta" &&
            aValue.value === "model" &&
            bValue.value === "model"
          ) {
            // Locale compare properly sorts texts in a natural order (handles numbers correctly)
            res = a.model.localeCompare(b.model, undefined, { numeric: true, sensitivity: "base" });
          } else if (typeof aSortNo === "number" && typeof bSortNo === "number") {
            res = aSortNo - bSortNo;
          } else if (typeof aSortNo === "string" && typeof bSortNo === "string") {
            res = aSortNo.localeCompare(bSortNo);
          }
          return res * (sortOrder === "desc" ? -1 : 1);
        });

  return {
    columns,
    rows: sortedRows,
  };
}

function createColValue(
  sortByMode: ModeResult,
  getFieldFormat: GetFieldFormatFn,
  match: Types.Match,
  col: NonNull<SearchResultColumn>,
  calcResult: CalculatorFrtCoil.CalculationResult | undefined,
  rowIndex: number
): ResultColumnValue {
  if (col.type === "meta" && validateMetaColumnType(col.value)) {
    return {
      type: "meta",
      sortNo: getSortableMetaValue(col.value, rowIndex),
      value: col.value,
    };
  } else if (col.type === "result" || col.type === "mode") {
    const [heating, cooling] =
      calcResult?.type === "Ok"
        ? col.value.split(",").map((p) => extractResultValue(calcResult.value, p))
        : [undefined, undefined];
    return {
      type: col.type,
      sortNo: getSortableValue(getFieldFormat, col.field_name, sortByMode === "heating" ? heating : cooling),
      heating: CalculatorResult.renderValue(getFieldFormat, col.field_name, heating),
      cooling: CalculatorResult.renderValue(getFieldFormat, col.field_name, cooling),
    };
  } else if (col.type === "variant_column") {
    const value = match.productVariantRow[col.value as keyof ProductVariantsRow];
    return {
      type: "variant_column",
      sortNo: value || "",
      value: value?.toString() || "",
    };
  } else if (col.type === "price") {
    const value = calcResult?.type === "Ok" ? extractResultValue(calcResult.value, col.value) : undefined;
    const rounded = typeof value === "number" ? Math.round(value * 100) / 100 : value;
    return {
      type: "price",
      sortNo: rounded?.toString() || "0",
      value: rounded?.toString() || "",
    };
  } else {
    throw new Error(`mapValue: Invalid column result ${JSON.stringify(col)}`);
  }
}

function getSortableMetaValue(colType: MetaColumnType, rowIndex: number): string {
  if (colType === "model") {
    return rowIndex.toString();
  } else {
    return "";
  }
}

function validateMetaColumnType(type: string): type is MetaColumnType {
  if (metaColumnTypes.includes(type as MetaColumnType)) {
    return true;
  } else {
    throw new Error(`isMetaColumnType: Invalid meta column type '${type}'`);
  }
}
