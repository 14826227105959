import * as React from "react";
import { useDebounce } from "./hooks/use-debounce";
import { PropertySelectorInputContainer, PropertySelectorTextAreaContainer } from "./styled-textfield";

export function TextInput({
  value,
  disabled = false,
  multiline,
  onChange,
  valid = true,
  debounceTime = 50,
}: {
  readonly value: string | undefined | null;
  readonly disabled?: boolean;
  readonly multiline?: boolean;
  readonly onChange: (value: string) => void;
  readonly valid?: boolean;
  readonly debounceTime?: number;
}): JSX.Element {
  const [hasFocus, setHasFocus] = React.useState(false);
  const [textValue, setTextValue] = React.useState(value || "");

  React.useEffect(() => {
    if (!hasFocus) {
      setTextValue(value || "");
    }
  }, [value]);

  const onValueChangeCb = React.useCallback(
    (onChangeValue?: string): void => {
      const newValue = onChangeValue ?? textValue;
      setTextValue(newValue);
      if (valid && value !== newValue) {
        onChange(newValue);
      }
    },
    [textValue, valid, value]
  );
  const onChangeDebounced = useDebounce(() => onValueChangeCb(), debounceTime);

  return multiline ? (
    <PropertySelectorTextAreaContainer
      rows={textValue.split("\n").length || 1}
      value={textValue}
      onChange={(e) => {
        setTextValue(e.currentTarget.value);
        onChangeDebounced.debouncedFunc();
      }}
      valid={valid}
      disabled={disabled}
      onFocus={() => setHasFocus(true)}
      onBlur={() => {
        onChangeDebounced.cancel();
        onChange(textValue);
        setHasFocus(false);
      }}
    />
  ) : (
    <PropertySelectorInputContainer
      value={textValue}
      onChange={(e) => {
        setTextValue(e.currentTarget.value);
        onChangeDebounced.debouncedFunc();
      }}
      valid={valid}
      disabled={disabled}
      onFocus={() => setHasFocus(true)}
      onBlur={() => {
        onChangeDebounced.cancel();
        onChange(textValue);
        setHasFocus(false);
      }}
    />
  );
}
