import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { Routes, SharedState } from "@ehb/client-infra";
import { Texts } from "@ehb/shared";
import { getProjectName, itemIdQueryParam, projectIdQueryParam } from "@ehb/shared/src/project";
import { MainLocation } from "@ehb/client-infra/src/routes";
import { ActiveUser } from "@ehb/shared/src/user";
import { roundTo } from "@ehb/shared/src/utils";
import { PropertyValueSet } from "@promaster-sdk/property";
import { CenterMessage, DateInput, Icon, NumberField, Spinner, SpinnerIcon, withTw } from "../../elements";
import { State, Action, Column, SortSetting, ListEntry } from "./state";
import * as GQLOps from "../../generated/generated-operations";
import * as ProjectState from "../../project-state";
import { Button } from "../../elements/button";
import { TextInput } from "../../elements/text-input";
import { Group } from "../../elements/group";
import { ItemData } from "../../project-state";

const LabelTh = withTw("th", "group h-[25px] cursor-pointer hover:text-red-600 select-none");
const StickyHead = withTw("thead", "bg-white z-50 sticky top-0");
const TableHeadRow = withTw("tr", "[&>*]:py-2");
const TableTr = withTw("tr", "hover:bg-[#fff8f8] h-[50px] border-t border-[#e5e7eb]");
const Td = withTw("td", "p-1 align-middle");
const ModelLink = withTw("a", "font-bold cursor-pointer");
const Flex = withTw("div", "flex flex-row [&>*]:basis-1/2");

export function View({
  state,
  sharedState,
  dispatch,
}: {
  readonly state: State;
  readonly sharedState: SharedState.SharedState;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const { activeUser, translate } = sharedState;
  const { projectList, sortSetting, projectState } = state;

  const dispatchProject = Dispatch.map(Action.DispatchProject, dispatch);

  const readOnly = projectState.type === "busy";

  return (
    <div className="flex flex-col items-stretch mx-auto">
      <h1 className="mt-2 mb-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        {translate(Texts.texts.projects)}
      </h1>
      <div className="flex justify-center items-stretch">
        <div className="text-xs flex flex-col gap-8 w-1/3">
          <Group>
            <div className="flex flex-row gap-4">
              <Button
                label={translate(Texts.texts.create_project)}
                onClick={() =>
                  dispatchProject(
                    ProjectState.Action.CreateProject(translate(Texts.texts.project_default_name), (newProjectId) =>
                      Routes.buildMainUrl(MainLocation.Projects({ [projectIdQueryParam]: newProjectId }))
                    )
                  )
                }
              />
            </div>
          </Group>
          {projectList ? (
            <ProjectTable
              translate={translate}
              dispatch={dispatch}
              sortSetting={sortSetting}
              projectList={projectList}
              openProjectId={sharedState.openProjectId}
            />
          ) : (
            <Spinner />
          )}
        </div>
        <div className="border-l border-[#e5e7eb] mx-8" />
        <div className="text-xs flex flex-col gap-8 w-2/3">
          <ProjectData dispatch={dispatch} translate={translate} projectState={projectState} readOnly={readOnly} />
          <ProjectItems
            activeUser={activeUser}
            dispatch={dispatch}
            translate={translate}
            projectState={projectState}
            readOnly={readOnly}
          />
        </div>
      </div>
    </div>
  );
}

function ProjectData({
  translate,
  dispatch,
  projectState,
  readOnly,
}: {
  readonly dispatch: Dispatch<Action>;
  readonly translate: Texts.TranslateFn;
  readonly projectState: ProjectState.State;
  readonly readOnly: boolean;
}): JSX.Element | null {
  const dispatchProject = Dispatch.map(Action.DispatchProject, dispatch);

  const [infoClosed, setInfoClosed] = React.useState(false);
  if (projectState.type !== "open" && projectState.type !== "busy") {
    return null;
  }
  const project = projectState.project.project;

  const inputRow = (
    label: Texts.TextKey,
    valueField: keyof GQLOps.Project,
    type: "text" | "date" | "discount" | "days" = "text"
  ): JSX.Element | null => (
    <InfoRow
      label={translate(label)}
      value={(() => {
        const onChange = (v: string | number): void =>
          dispatchProject(ProjectState.Action.UpdateProject({ [valueField]: v || "" }));
        switch (type) {
          case "text":
            return (
              <TextInput
                value={project[valueField] as string}
                onChange={onChange}
                disabled={readOnly}
                debounceTime={500}
              />
            );
          case "days":
            return (
              <Flex>
                <NumberField
                  value={project[valueField] as number}
                  onChange={onChange}
                  disabled={readOnly}
                  decimals={0}
                />
                <span style={{ padding: "0.5rem" }}>days</span>
              </Flex>
            );
          case "date":
            return (
              <Flex>
                <DateInput value={project[valueField] as string} onChange={onChange} disabled={readOnly} />
              </Flex>
            );

          case "discount":
            return (
              <Flex>
                <NumberField value={project[valueField] as number} onChange={onChange} disabled={readOnly} />
                <span style={{ padding: "0.5rem" }}>%</span>
              </Flex>
            );
          default:
            return null;
        }
      })()}
    />
  );

  return (
    <Group
      header={getProjectName(translate, project)}
      closed={infoClosed}
      onToggleClosed={() => setInfoClosed(!infoClosed)}
    >
      <div className="flex flex-col gap-2">
        {inputRow(Texts.texts.project_name, "name")}
        <InfoRow label={translate(Texts.texts.project_owner)} value={project.owner} />
        <InfoRow label={translate(Texts.texts.project_created)} value={formatDateTime(project.createdDate)} />
        <InfoRow label={translate(Texts.texts.project_last_modified)} value={formatDateTime(project.modifiedDate)} />
        {inputRow(Texts.texts.quote_number, "quote_number")}
        {inputRow(Texts.texts.quote_date, "quote_date", "date")}
        {inputRow(Texts.texts.production_time, "production_time", "days")}
        {inputRow(Texts.texts.quote_payment_terms, "payment_terms")}
        {inputRow(Texts.texts.quote_delivery_info, "delivery_info")}
        {inputRow(Texts.texts.discount_label, "discount", "discount")}
        <div>
          <Button
            disabled={projectState.type !== "open"}
            label={translate(Texts.texts.delete_project)}
            onClick={() =>
              dispatchProject(ProjectState.Action.DeleteProject(Routes.buildMainUrl(MainLocation.Projects({}))))
            }
          />
          <Button
            label={translate(Texts.texts.pdf_datasheet)}
            onClick={() => {
              dispatch(Action.GetReport("project-first-page"));
            }}
            iconLeft="file-pdf"
          />
        </div>
      </div>
    </Group>
  );
}

function InfoRow({
  label,
  value,
}: {
  readonly label: React.ReactNode | string;
  readonly value: React.ReactNode | string;
}): JSX.Element {
  return (
    <div className="flex flex-row justify-between items-center h-8">
      <div className="basis-1/3">{typeof label === "string" ? <div>{label}</div> : label}</div>
      <div className="basis-2/3">{typeof value === "string" ? <div>{value}</div> : value}</div>
    </div>
  );
}

function ProjectItems({
  activeUser,
  translate,
  dispatch,
  projectState,
  readOnly,
}: {
  readonly activeUser: ActiveUser;
  readonly dispatch: Dispatch<Action>;
  readonly translate: Texts.TranslateFn;
  readonly projectState: ProjectState.State;
  readonly readOnly: boolean;
}): JSX.Element {
  const dispatchProject = Dispatch.map(Action.DispatchProject, dispatch);

  if (projectState.type === "loading") {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <SpinnerIcon small={true} />
      </div>
    );
  } else if (projectState.type === "closed") {
    return <CenterMessage message={translate(Texts.texts.msg_no_project_open)} />;
  } else if (projectState.type === "error") {
    return (
      <CenterMessage
        message={`${translate(Texts.texts.msg_failed_to_load_project)} (${
          projectState.id.type === "project"
            ? `project: ${projectState.id.projectId}`
            : `item: ${projectState.id.itemId}`
        })`}
      />
    );
  } else if (projectState.type === "creating") {
    return <Spinner />;
  } else if (projectState.project.items.length === 0) {
    return <CenterMessage message={translate(Texts.texts.msg_project_is_empty)} />;
  }

  const itemDataMap = React.useMemo(
    () => ProjectState.getAllItemData(translate, activeUser, projectState),
    [projectState.data, projectState.project, projectState.type]
  );
  if (!itemDataMap) {
    return <SpinnerIcon small={true} />;
  }

  const totalPrice = projectState.project.items.reduce((sum, i) => {
    const itemData = itemDataMap.get(i.id);
    return sum + (itemData?.price || 0);
  }, 0);
  return (
    <div>
      <table className="w-full">
        <StickyHead>
          <TableHeadRow>
            <ItemColumn className="w-9" translate={translate} column={"position"} />
            <ItemColumn translate={translate} column={"reference"} />
            <ItemColumn translate={translate} column={"note"} />
            <ItemColumn className="w-9" translate={translate} column={"type"} />
            <ItemColumn translate={translate} column={"name"} />
            <ItemColumn className="w-9" translate={translate} column={"article_number"} />
            <ItemColumn className="w-9" translate={translate} column={"quantity"} />
            <ItemColumn className="w-9" translate={translate} column={"price"} />
            <LabelTh className="w-4" />
          </TableHeadRow>
        </StickyHead>
        <tbody>
          {projectState.project.items.map((i) => {
            const itemData = itemDataMap.get(i.id);
            const disabled = readOnly || !itemData;
            return (
              <TableTr key={i.id}>
                <Td className="text-left">{itemData?.sortNo}</Td>
                <Td className="text-left">
                  <ItemPropertyInput
                    dispatchProject={dispatchProject}
                    disabled={disabled}
                    itemData={itemData}
                    itemId={i.id}
                    propertyName="reference"
                  />
                </Td>
                <Td className="text-left">
                  <ItemPropertyInput
                    dispatchProject={dispatchProject}
                    disabled={disabled}
                    itemData={itemData}
                    itemId={i.id}
                    propertyName="notes"
                  />
                </Td>
                <Td className="text-left">{translate(Texts.texts.item_type(itemData?.type || "unknown"))}</Td>
                <Td className="text-left">
                  <ItemLink itemData={itemData} item={i} />
                </Td>
                <Td className="text-left">{itemData?.articleNumber}</Td>
                <Td className="text-left">
                  <NumberField
                    className="w-10"
                    value={itemData?.quantity}
                    onChange={(v) => dispatchProject(ProjectState.Action.UpdateItem({ id: i.id, quantity: v }))}
                    disabled={disabled}
                    decimals={0}
                  />
                </Td>
                <Td className="text-left">
                  {itemData?.price
                    ? `${roundTo(itemData.price, 2)} ${getCurrencySymbol(activeUser.claims.currency)}`
                    : "-"}
                </Td>
                <Td>
                  <div className="inline-block">
                    <Icon
                      icon={"trash-alt"}
                      disabled={false}
                      onClick={() => dispatchProject(ProjectState.Action.DeleteItem(i.id, undefined))}
                      message={translate(Texts.texts.project_remove_item)}
                      className="mr-2 text-red-600 transition duration-75 origin-center hover:scale-110"
                    />
                  </div>
                </Td>
              </TableTr>
            );
          })}
          <TableTr>
            <Td colSpan={7}>
              <div style={{ float: "right", paddingRight: "1em" }}>{translate(Texts.texts.total_price)}:</div>
            </Td>
            <Td>
              {roundTo(totalPrice, 2)} {getCurrencySymbol(activeUser.claims.currency)}
            </Td>
            <Td />
          </TableTr>
        </tbody>
      </table>
      <div></div>
    </div>
  );
}

function ItemPropertyInput({
  dispatchProject,
  itemId,
  itemData,
  disabled,
  propertyName,
}: {
  readonly dispatchProject: Dispatch<ProjectState.Action>;
  readonly itemId: string;
  readonly itemData: ItemData | undefined;
  readonly disabled: boolean;
  readonly propertyName: string;
}): JSX.Element | null {
  const value = itemData?.properties ? PropertyValueSet.getText(propertyName, itemData?.properties) || "" : "";
  return (
    <TextInput
      multiline={propertyName === "notes"}
      value={value}
      onChange={(v) =>
        itemData?.properties &&
        dispatchProject(
          ProjectState.Action.UpdateItemProperties(
            itemId,
            PropertyValueSet.setText(propertyName, v, itemData.properties)
          )
        )
      }
      disabled={disabled}
      debounceTime={500}
    />
  );
}

function ProjectTable({
  dispatch,
  translate,
  sortSetting,
  projectList,
  openProjectId,
}: {
  readonly dispatch: Dispatch<Action>;
  readonly translate: Texts.TranslateFn;
  readonly sortSetting: SortSetting | undefined;
  readonly projectList: ReadonlyArray<ListEntry>;
  readonly openProjectId: string | undefined;
}): JSX.Element {
  if (projectList.length === 0) {
    return <CenterMessage message={translate(Texts.texts.msg_no_projects)} />;
  }
  return (
    <table className="w-full">
      <StickyHead>
        <TableHeadRow>
          <ListColumn translate={translate} dispatch={dispatch} sortSetting={sortSetting} column={"name"} />
          <ListColumn translate={translate} dispatch={dispatch} sortSetting={sortSetting} column={"owner"} />
          <ListColumn translate={translate} dispatch={dispatch} sortSetting={sortSetting} column={"createdDate"} />
          <ListColumn translate={translate} dispatch={dispatch} sortSetting={sortSetting} column={"modifiedDate"} />
        </TableHeadRow>
      </StickyHead>
      <tbody>
        {projectList.map(({ project: p }) => (
          <TableTr
            key={p.id}
            className={openProjectId === p.id ? "font-bold" : ""}
            onClick={() => dispatch(Action.SetOpenProject(p.id))}
          >
            <Td className="text-left" style={{ minWidth: "200px" }}>
              <ModelLink
                href={Routes.buildUrl(
                  Routes.RootLocation.MainLocation(Routes.MainLocation.Projects({ [projectIdQueryParam]: p.id }))
                )}
              >
                {p.name || "Untitled"}
              </ModelLink>
            </Td>
            <Td className="text-left">{p.owner}</Td>
            <Td className="text-left tabular-nums">{formatDate(p.createdDate)}</Td>
            <Td className="text-left tabular-nums">{formatDate(p.modifiedDate)}</Td>
          </TableTr>
        ))}
      </tbody>
    </table>
  );
}

function ItemLink({
  itemData,
  item,
}: {
  readonly itemData: ProjectState.ItemData | undefined;
  readonly item: GQLOps.Item;
}): JSX.Element {
  return (
    <ModelLink
      href={
        item.type === "electric"
          ? Routes.buildMainUrl(MainLocation.ProductSelect({ [itemIdQueryParam]: item.id }))
          : Routes.buildMainUrl(MainLocation.ProductCalculate({ [itemIdQueryParam]: item.id }, item.product))
      }
    >
      {itemData?.name || item.product}
    </ModelLink>
  );
}
function ListColumn({
  translate,
  dispatch,
  sortSetting,
  column,
}: {
  readonly translate: Texts.TranslateFn;
  readonly dispatch: Dispatch<Action>;
  readonly sortSetting: SortSetting | undefined;
  readonly column: Column;
}): JSX.Element {
  const setting = sortSetting?.column === column ? sortSetting : undefined;
  return (
    <LabelTh
      key={column}
      onClick={() => {
        dispatch(Action.SetSortOrder(column, setting?.order === "desc" ? "asc" : "desc"));
      }}
    >
      <div className={`p-1 space-x-1 text-left flex flex-row`}>
        <div>{translate(Texts.texts.project_column(column))}</div>
        {/*   <SortIcon columnOrder={setting?.order} column={column} /> */}
      </div>
    </LabelTh>
  );
}

function ItemColumn({
  translate,
  column,
  className,
}: {
  readonly translate: Texts.TranslateFn;
  readonly column: string;
  readonly className?: string;
}): JSX.Element {
  return (
    <LabelTh>
      <div className={`p-1 space-x-1 text-left flex flex-row ${className || ""}`}>
        <div>{translate(Texts.texts.project_item_col(column))}</div>
      </div>
    </LabelTh>
  );
}

// function SortIcon({
//   columnOrder,
//   column,
// }: {
//   readonly columnOrder: Order | undefined;
//   readonly column: Column;
// }): JSX.Element {
//   const sortOrder = columnOrder || initialSortOrders[column];
//   const className = ` w-4 ${
//     columnOrder === undefined ? "text-gray-500 invisible group-hover:visible" : "text-gray-500"
//   }`;
//   if (sortOrder === "asc") {
//     return <Icon className={className} prefix="fas" icon="arrow-down-short-wide" />;
//   } else if (sortOrder === "desc") {
//     return <Icon className={className} prefix="fas" icon="arrow-up-wide-short" />;
//   } else {
//     exhaustiveCheck(sortOrder, true);
//   }
// }

export function formatDate(dateValue: string): string {
  const date = new Date(dateValue);
  // sv-SE to format the date as yyyy-mm-dd
  const isoDateValue = date.toLocaleDateString("sv-SE");
  if (Number.isFinite(date.getTime())) {
    return isoDateValue;
  } else {
    return "";
  }
}

export function formatDateTime(dateValue: string): string {
  const date = new Date(dateValue);
  const isoDateValue = date.toLocaleTimeString("sv-SE");
  if (Number.isFinite(date.getTime())) {
    return `${formatDate(dateValue)} ${isoDateValue}`;
  } else {
    return "";
  }
}

function getCurrencySymbol(currency: string): string {
  return currency === "EUR" ? " €" : currency === "SEK" ? " kr" : ` ${currency}`;
}
