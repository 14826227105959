import { Accessories } from "..";
import * as Types from "./types";
import * as Search from "../search";

export function calculatePrice(
  selectedProduct: Search.ProductVariantsRow,
  accessories: ReadonlyArray<Accessories.Accessory>,
  userCurrency: string,
  discount: number
): Types.Price {
  const isEUR = userCurrency.toUpperCase() === "EUR";
  const price = (isEUR ? selectedProduct.price_eur : selectedProduct.price_sek) || undefined;

  selectedProduct.art_nr;

  if (!price) {
    throw new Error(`Could not find price for selected product ${selectedProduct.model}`);
  }

  const accessoryPrice = accessories.reduce((s, c) => s + Number(isEUR ? c.price_eur : c.price_sek), 0);
  return {
    articleNumber: selectedProduct.art_nr === null ? undefined : selectedProduct.art_nr,
    grossPrice: (price + accessoryPrice) * (1 - discount),
    priceType: isEUR ? "price_eur" : "price_sek",
  };
}
