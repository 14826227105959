import * as React from "react";
import { roundTo } from "@ehb/shared/src/utils";
import { debounce as lodashDebounce, DebouncedFunc } from "lodash";
import { Icon } from ".";

export interface NumberFieldProps {
  readonly value: number | undefined | null;
  readonly onChange?: (newNumber: number) => void;
  readonly onChangeWithError?: (newNumber: number | undefined) => void;
  readonly onChangeWithEmpty?: (newNumber: number | undefined) => void;
  readonly decimals?: number;
  readonly notNumericMessage?: string;
  readonly isRequiredMessage?: string;
  readonly errorMessage?: string;
  readonly disabled?: boolean;
  readonly readOnly?: boolean;
  readonly className?: string;
  readonly showSpinButton?: boolean;
  readonly valid?: boolean;
  readonly onClearOverride?: () => void;
}

// What the optimal debounce is may vary between users. 350ms seems like a nice value...
const debounceTime = 350;

export function NumberField(props: NumberFieldProps): React.ReactElement<NumberFieldProps> {
  const { disabled, readOnly, errorMessage, value, valid, decimals, onClearOverride, className } = props;

  const isNumberInput = !!props.showSpinButton;
  const inputRef = React.useRef(null);

  const [textValue, setTextValue] = React.useState(numberInputString(numberToString(value, decimals), isNumberInput));
  const update = React.useMemo(createDebouncedUpdate, []);
  React.useEffect(() => {
    if (document.activeElement !== inputRef.current) {
      const currentTextValue = numberToString(value, decimals);
      if (currentTextValue !== textValue) {
        setTextValue(numberInputString(currentTextValue.replace(",", "."), isNumberInput));
      }
    }
  }, [props.value]);

  const finalError = getInputErrorMessage(props, textValue) || errorMessage;

  return (
    <div className={className}>
      {readOnly ? (
        <span className="form-input-readonly w-full">{textValue}</span>
      ) : (
        <input
          className={`form-input w-full ${valid === false && "form-input-danger"}`}
          type={isNumberInput ? "number" : "text"}
          step={0.1}
          ref={inputRef}
          value={textValue}
          disabled={readOnly}
          readOnly={disabled || readOnly}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const newTextValue = e.currentTarget.value.toString();
            const oldFormatedValue = numberToString(value, decimals);
            const newFormatedValue = numberToString(getNumber(newTextValue), decimals);
            setTextValue(newTextValue);
            update(props, newFormatedValue, oldFormatedValue);
          }}
          title={finalError}
          onBlur={() => {
            update.cancel();
            const oldFormatedValue = numberToString(value, decimals);
            const newFormatedValue = numberToString(getNumber(textValue), decimals);
            setTextValue(numberInputString(newFormatedValue, isNumberInput));
            updateValue(props, newFormatedValue, oldFormatedValue);
          }}
        />
      )}
      {onClearOverride && !disabled && !readOnly && (
        <Icon icon="xmark" onClick={!disabled ? () => onClearOverride() : undefined} />
      )}
    </div>
  );
}

//Number inputs in chrome does not take comma in a string, need to replace it with a dot
function numberInputString(value: string, isNumberInput: boolean): string {
  return isNumberInput ? value.replace(",", ".") : value;
}

function createDebouncedUpdate(): DebouncedFunc<
  (props: NumberFieldProps, textValue: string, originalValue: string) => void
> {
  return lodashDebounce(
    (props, textValue, originalValue) => updateValue(props, textValue, originalValue),
    debounceTime
  );
}

function updateValue(props: NumberFieldProps, textValue: string, originalValue: string): void {
  if (textValue === originalValue) {
    return;
  }
  if (isEmpty(textValue) && props.onChangeWithEmpty) {
    props.onChangeWithEmpty(undefined);
    return;
  }
  const errorMessage = getInputErrorMessage(props, textValue);
  if (!errorMessage && !props.disabled) {
    const number = getNumber(textValue);
    const roundedNumer =
      props.decimals !== undefined && number !== undefined
        ? Math.round(number * 10 ** props.decimals) / 10 ** props.decimals
        : number;
    if (roundedNumer !== undefined && (props.onChange || props.onChangeWithEmpty)) {
      props.onChangeWithEmpty && props.onChangeWithEmpty(roundedNumer);
      props.onChange && props.onChange(roundedNumer);
    } else if (props.onChangeWithError) {
      props.onChangeWithError(roundedNumer);
    }
  }
}

function isEmpty(textValue: string | undefined): boolean {
  return textValue === undefined || textValue.trim() === "";
}

function getInputErrorMessage(props: NumberFieldProps, textValue: string): string | undefined {
  const { isRequiredMessage, notNumericMessage } = props;
  const number = getNumber(textValue);
  // Check if blank and if required or not
  if (isEmpty(textValue) && props.isRequiredMessage) {
    // The user has not entred anything, but a value was required
    return isRequiredMessage;
  }
  if (!number && isRequiredMessage) {
    // The user has entered something, but it could not be converted to an number (=was not numeric)
    return notNumericMessage;
  }
  return undefined;
}

function getBrowserLocale(): string {
  if (typeof navigator === "undefined") {
    return "en-GB";
  }
  const locale = navigator.languages ? navigator.languages[0] : navigator.language;
  return locale || "en-GB";
}

const browserLocale = getBrowserLocale();
const decimalSeparator = (1.5).toLocaleString(browserLocale)[1];

function numberToString(num: number | undefined | null, decimals?: number): string {
  if (num === undefined || num === null) {
    return "";
  }

  if (decimals === undefined) {
    return num.toString().replace(",", ".").replace(".", decimalSeparator);
  }
  return roundTo(num, decimals).toString().replace(",", ".").replace(".", decimalSeparator);
}

function stringToNumber(str: string): number {
  return parseFloat(str.replace(",", "."));
}

// function getNumDecimals(str: string): number {
//   const pointIndex = str.indexOf(decimalSeparator);
//   if (pointIndex >= 0) {
//     return str.length - pointIndex - 1;
//   }
//   return 0;
// }

function getNumber(text: string): number | undefined {
  if (!text || text.length === 0) {
    return undefined;
  }
  const parsedFloatValue = stringToNumber(text);
  if (Number.isNaN(parsedFloatValue)) {
    return undefined;
  }
  // const decimals = getNumDecimals(text);
  return parsedFloatValue;
}
