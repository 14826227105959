import { ParagraphStyle } from "abstract-document/lib/abstract-document";
import { PropertyValueSet } from "@promaster-sdk/property";
import { Amount } from "uom";
import * as React from "react";
import { AbstractDocJsx as ADX } from "abstract-document";
import * as Common from "../common";
import { CreatorType } from "./types";
import { Cell, row, table } from "../common/elements";
import { TextKey, texts } from "../../lang-texts";
import { CalculatorResult, Texts } from "../..";
import { getUnitLabel } from "../../units/unit-labels";

const { AbstractDoc, Section, Group, Paragraph, Image, render } = ADX;

export const create: CreatorType = ({ pageProps, calculationResult, variant, model, productById, dimensionsImage }) => {
  const { getFieldFormat, translate, clientConfig } = pageProps;
  const styles = Common.styles();
  const numberingDefinitions = Common.numberingDefinitions(styles);

  const medium = PropertyValueSet.getInteger("medium", variant);
  const product = productById[clientConfig.promaster_search_product_id];

  const unit = (fieldName: string): string => getUnitLabel(getFieldFormat(fieldName).unit.name);
  const value = (value: Amount.Amount<unknown> | undefined, fieldName: string): string =>
    CalculatorResult.renderValue(getFieldFormat, fieldName, value);

  const valueRow = (
    text: TextKey,
    heating: Amount.Amount<unknown> | undefined,
    cooling: Amount.Amount<unknown> | undefined,
    fieldName: string
  ): readonly Cell[] => {
    if (heating && cooling) {
      return row(translate(text), unit(fieldName), value(heating, fieldName), value(cooling, fieldName));
    } else {
      return row(translate(text), unit(fieldName), value(heating ?? cooling, fieldName));
    }
  };

  const project = PropertyValueSet.getText("project", variant);
  const reference = PropertyValueSet.getText("reference", variant);
  const notes = PropertyValueSet.getText("notes", variant);

  const meta = table(
    [],
    [
      row(translate(texts.project), project),
      row(translate(texts.reference), reference),
      row(translate(texts.notes), notes),
    ]
  );

  const cooling = calculationResult.cooling;
  const heating = calculationResult.heating;
  const sharedResult = heating || cooling; // Used for results that are the same for both heating and cooling

  const heatingFluidName = heating && translate(texts.property_value("h_fluid_type", variant, product.key));
  const coolingFluidName = cooling && translate(texts.property_value("c_fluid_type", variant, product.key));

  const coolingAndHeating = !!(heating && cooling);

  const performance = table(headerRow(translate, texts.performance_data, coolingAndHeating), [
    [{ value: translate(texts.model) }, { value: model, colSpan: coolingAndHeating ? 3 : 2 }],
    valueRow(texts.capacity, heating?.capacity, cooling?.capacity, "power"),
  ]);

  const airSide = table(headerRow(translate, texts.air_side_data, coolingAndHeating), [
    [
      { value: `${translate(texts.atmospheric_pressure)} / ${translate(texts.altitude)}` },
      { value: `${unit("atmospheric_pressure")} / ${unit("altitude")}` },
      {
        value: `${value(sharedResult?.atmosphericPressure, "atmospheric_pressure")} / ${value(
          sharedResult?.altitude,
          "altitude"
        )}`,
        colSpan: coolingAndHeating ? 2 : 1,
      },
    ],
    valueRow(texts.volumetric_flow, heating?.airFlow, cooling?.airFlow, "air_flow"),
    valueRow(texts.mass_flow, heating?.airMassFlow, cooling?.airMassFlow, "air_mass_flow"),
    valueRow(texts.density, heating?.airDensity, cooling?.airDensity, "air_density"),
    valueRow(texts.surface_velocity, heating?.airVelocity, cooling?.airVelocity, "air_velocity"),
    valueRow(texts.air_inlet_temperature, heating?.airInletTemperature, cooling?.airInletTemperature, "air_temp"),
    valueRow(texts.inlet_humidity, heating?.airInletHumidity, cooling?.airInletHumidity, "air_humidity"),
    valueRow(texts.air_outlet_temperature, heating?.airOutletTemperature, cooling?.airOutletTemperature, "air_temp"),
    valueRow(texts.outlet_humidity, heating?.airOutletHumidity, cooling?.airOutletHumidity, "air_humidity"),
    valueRow(texts.pressure_drop, heating?.airPressureDrop, cooling?.airPressureDrop, "air_pressure_drop"),
  ]);

  // medium 1 = Water, medium 2 = DX
  const fluidSide =
    medium === 1
      ? table(headerRow(translate, texts.fluid_side_data, coolingAndHeating), [
          row(translate(texts.fluid), "-", heatingFluidName, coolingFluidName),
          valueRow(texts.mixture_rate, heating?.fluidMixtureRatio, cooling?.fluidMixtureRatio, "fluid_mixture_rate"),
          valueRow(texts.volumetric_flow, heating?.fluidFlow, cooling?.fluidFlow, "fluid_flow"),
          row(
            translate(texts.inlet_outlet_temperature),
            unit("fluid_temp"),
            heating &&
              `${value(heating.fluidInletTemperature, "fluid_temp")} / ${value(
                heating?.fluidOutletTemperature,
                "fluid_temp"
              )}`,
            cooling &&
              `${value(cooling.fluidInletTemperature, "fluid_temp")} / ${value(
                cooling.fluidOutletTemperature,
                "fluid_temp"
              )}`
          ),
          valueRow(texts.mass_flow, heating?.fluidMassFlow, cooling?.fluidMassFlow, "fluid_mass_flow"),
          valueRow(texts.density, heating?.fluidDensity, cooling?.fluidDensity, "fluid_density"),
          valueRow(texts.velocity, heating?.fluidVelocity, cooling?.fluidVelocity, "fluid_velocity"),
          valueRow(texts.pressure_drop, heating?.fluidPressureDrop, cooling?.fluidPressureDrop, "fluid_pressure_drop"),
        ])
      : table(headerRow(translate, texts.fluid_side_data, coolingAndHeating), [
          row(translate(texts.fluid), "-", heatingFluidName, coolingFluidName),
          valueRow(
            texts.condensation_temperature,
            heating?.condensationTemperature,
            cooling?.condensationTemperature,
            "fluid_temp"
          ),
          valueRow(texts.superheating, heating?.superheating, cooling?.superheating, "dx_temp"),
          valueRow(texts.subcooling, heating?.subcooling, cooling?.subcooling, "dx_temp"),
          valueRow(texts.pressure_drop, heating?.fluidPressureDrop, cooling?.fluidPressureDrop, "fluid_pressure_drop"),
          valueRow(texts.mass_flow, heating?.fluidMassFlow, cooling?.fluidMassFlow, "fluid_mass_flow"),
        ]);

  const exchanger = table(
    [{ value: translate(texts.heat_exchanger_data), colSpan: 3 }],
    [
      valueRow(texts.tube_volume, sharedResult?.tubeVolume, undefined, "tube_volume"),
      row(translate(texts.tube_material), "-", sharedResult && translate(sharedResult?.tubeMaterial)),
      row(translate(texts.fin_material), "-", sharedResult && translate(sharedResult?.finMaterial)),
    ]
  );

  const dimensions = table(row(translate(texts.dimensions)), []);

  const dimensionsImageResource = dimensionsImage ? Common.createImageResource(dimensionsImage) : undefined;

  const doc = render(
    <AbstractDoc styles={styles} numberingDefinitions={numberingDefinitions}>
      <Section id={Common.pageId(pageProps)} page={Common.cataloguePage(pageProps)}>
        {meta}
        {performance}
        {airSide}
        {fluidSide}
        {exchanger}
        <Group keepTogether={true}>
          {dimensions}
          <Paragraph style={ParagraphStyle.create({})}>
            {dimensionsImageResource && <Image imageResource={dimensionsImageResource} width={300} height={300} />}
          </Paragraph>
        </Group>
      </Section>
    </AbstractDoc>
  );

  return doc;
};

function headerRow(
  translate: Texts.TranslateFn,
  label: Texts.TextKey,
  coolingAndHeating: boolean
): ReadonlyArray<Cell> {
  const values: Array<Cell> = [
    {
      value: translate(label),
      colSpan: coolingAndHeating ? 2 : 3,
    },
  ];
  if (coolingAndHeating) {
    values.push({
      value: translate(texts.mode("heating")),
    });
    values.push({
      value: translate(texts.mode("cooling")),
    });
  }
  return values;
}
