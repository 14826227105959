import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import { unitLookup } from "uom-units";
import { Calculate, CalculatorFrtCoil, MagicloudApi, Search } from "../..";
import { QueryGenerator, graphQLProductQuery, httpBlobQuery, promiseQuery } from "../../query";
import { ActiveUser } from "../../user";
import { BitmapImage, getPageProps, imageToBitmapImage } from "../common";
import { ReportQueryProductParams } from "../types";
import { AdapterType, Response } from "./types";
import * as GQLOps from "../../generated/generated-operations";
import { createSearchData } from "../../search";
import { createProxiedMagicloudUrl } from "../../magicloud-api";
import { fetchImageData, ImageData } from "../../images";
import { imageQueryByProductId } from "../common/queries";

export function* query({
  clientConfig,
  productByKey,
  productKey,
  user,
  properties,
  imageServiceUrl,
}: ReportQueryProductParams): QueryGenerator<Response> {
  const searchQueryData = yield* graphQLProductQuery<GQLOps.SearchSingleQuery, GQLOps.SearchSingleQueryVariables>(
    Search.searchProductQuerySingle,
    {
      searchProductId: clientConfig.promaster_search_product_id,
      productId: productByKey[productKey].id,
    }
  );

  const fritermCoilData = yield* graphQLProductQuery<GQLOps.FrtCoilQuery, GQLOps.FrtCoilQueryVariables>(
    CalculatorFrtCoil.query,
    { searchProductId: clientConfig.promaster_search_product_id }
  );

  const searchData = createSearchData(searchQueryData);
  const searchResult = Search.searchSingle(searchData, createSearchQuery(user, searchData, properties, productKey));
  const match = searchResult?.match;
  if (!match) {
    throw new Error("Failed to search");
  }

  const rows = Search.filterRows(
    match.selection,
    searchQueryData.product?.modules.custom_tables.product_variants || []
  );

  const magicloudId = rows[0].magicloud_id;
  const model =
    magicloudId === null ? undefined : yield* promiseQuery(MagicloudApi.getX3DModelByGuid(magicloudId, undefined));
  const proxiedDimensionsUrl = model?.dimensionsUrl && createProxiedMagicloudUrl(model.dimensionsUrl);
  const imageData = typeof proxiedDimensionsUrl !== "string" ? undefined : yield* httpBlobQuery(proxiedDimensionsUrl);

  const dimensionsimage = imageData && {
    image: { image: "dimension_image", file_name: null },
    imageData,
  };

  const searchProductImages = yield* imageQueryByProductId(clientConfig.promaster_search_product_id);
  const headerImages = yield* createHeaderImages(searchQueryData, match, searchProductImages, imageServiceUrl);
  return { searchData: searchQueryData, fritermCoilData, dimensionsimage, headerImages };
}

export const execute: AdapterType = async (reportParams, common, response) => {
  const { searchData, fritermCoilData, headerImages: headerImagesData } = response;
  const { user, productKey, properties } = reportParams;
  const pageProps = await getPageProps(reportParams, common);
  const dimensionsImage = response.dimensionsimage && (await imageToBitmapImage(500, 500, response.dimensionsimage));
  const calcData = CalculatorFrtCoil.mapQuery(fritermCoilData);
  const query = createSearchQuery(user, createSearchData(searchData), properties, productKey);
  const searchResult = Search.searchSingle(createSearchData(searchData), query);
  const match = searchResult?.match;
  if (!match) {
    throw new Error("Failed to search");
  }

  const calculationResult = await Calculate.calculateProduct(
    calcData,
    properties,
    match.selection,
    match.productVariantRow,
    query,
    reportParams.waterAccessories
  );

  if (!calculationResult || calculationResult.type === "Err") {
    throw new Error("Failed to calculate");
  }

  const headerImages = [
    headerImagesData?.density && (await imageToBitmapImage(500, 500, headerImagesData.density)),
    headerImagesData?.tuv && (await imageToBitmapImage(500, 500, headerImagesData.tuv)),
    headerImagesData?.ce && (await imageToBitmapImage(500, 500, headerImagesData.ce)),
  ].filter((h): h is BitmapImage => !!h);

  return {
    pageProps: { ...pageProps, headerImages },
    calculationResult: calculationResult.value,
    variant: properties,
    model: match.model,
    productById: reportParams.productById,
    dimensionsImage,
  };
};

function createSearchQuery(
  activeUser: ActiveUser,
  searchData: Search.SearchData,
  variant: PropertyValueSet.PropertyValueSet,
  productKey: string
): Search.Query {
  return {
    company: activeUser.companyName,
    filter: Search.propertiesToSearchFilter(searchData, variant),
    productKey: productKey,
    userCurrency: activeUser.claims.currency,
  };
}

export function* createHeaderImages(
  searchData: GQLOps.SearchSingleQuery,
  match: Search.Match,
  searchProductImages: GQLOps.Report_ProductImagesQuery["product"] | undefined,
  imageServiceUrl: string
): QueryGenerator<
  | {
      readonly density: ImageData | undefined;
      readonly tuv: ImageData | undefined;
      readonly ce: ImageData | undefined;
    }
  | undefined
> {
  const printoutSymbols = searchData?.searchProduct?.modules.custom_tables.printout_symbols.find(
    (p) =>
      p.property_filter &&
      PropertyFilter.isValid(match.variant, PropertyFilter.fromStringOrEmpty(p.property_filter, unitLookup))
  );

  const images = searchProductImages?.modules.images.image;
  if (!images) {
    return undefined;
  }

  const headerImages = {
    density: printoutSymbols?.density_class && images?.find((i) => i.name === printoutSymbols.density_class),
    tuv: printoutSymbols?.tuv && images?.find((i) => i.name === printoutSymbols.tuv),
    ce: printoutSymbols?.ce_marking && images?.find((i) => i.name === printoutSymbols.ce_marking),
  };

  const headerImagesData = {
    density: headerImages.density
      ? (yield* fetchImageData([headerImages.density], imageServiceUrl, 300, 300, 1, "jpeg"))[0]
      : undefined,
    tuv: headerImages.tuv
      ? (yield* fetchImageData([headerImages.tuv], imageServiceUrl, 300, 300, 1, "jpeg"))[0]
      : undefined,
    ce: headerImages.ce
      ? (yield* fetchImageData([headerImages.ce], imageServiceUrl, 300, 300, 1, "jpeg"))[0]
      : undefined,
  };

  return headerImagesData;
}
