export interface ClientConfig {
  readonly env: string;
  readonly dsn: string | undefined;
  readonly promaster_meta_id: string;
  readonly promaster_eh_id: string;
  readonly promaster_search_product_id: string;
  readonly promaster_special_calculation_product_id: string;
  readonly promaster_marker: string;
  readonly oidc_login_url: string;
  readonly oidc_client_id: string;
  readonly oidc_post_logout_url?: string;
  readonly gaTargetId: string | undefined; // TARGET_ID for Google Analytics API
  readonly imageServiceUrl: string;
}
