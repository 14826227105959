import * as React from "react";
import { useDebounce } from "./hooks/use-debounce";
import { withTw } from "./with-tw";

export const Input = withTw("input", "form-input-area");

export function DateInput({
  value,
  disabled = false,
  onChange,
  valid = true,
  debounceTime = 50,
}: {
  readonly value: string | undefined | null;
  readonly disabled?: boolean;
  readonly onChange: (value: string) => void;
  readonly valid?: boolean;
  readonly debounceTime?: number;
}): JSX.Element {
  const [hasFocus, setHasFocus] = React.useState(false);
  const [dateValue, setDateValue] = React.useState(value || "");

  const dateInputRef = React.useRef<HTMLInputElement>(null);

  // Sync internal state with external value when input is not focused
  React.useEffect(() => {
    if (!hasFocus) {
      setDateValue(value || "");
    }
  }, [value]);

  // Callback for handling changes
  const onValueChangeCb = React.useCallback(
    (onChangeValue?: string): void => {
      const newValue = onChangeValue ?? dateValue;
      setDateValue(newValue);
      // Only trigger onChange if valid and if the value has changed
      if (valid && value !== newValue) {
        onChange(newValue);
      }
    },
    [dateValue, valid, value]
  );

  // Debounced version of the onValueChangeCb
  const onChangeDebounced = useDebounce(() => onValueChangeCb(), debounceTime);

  const handleDateClick = (): void => {
    if (dateInputRef.current?.showPicker && !disabled) {
      dateInputRef.current.showPicker(); // Programmatically opens the date picker
    }
  };

  return (
    <Input
      type="date"
      elementref={dateInputRef}
      value={dateValue}
      disabled={disabled}
      onClick={handleDateClick}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      onChange={(e) => {
        setDateValue(e.target.value);
        onChangeDebounced.debouncedFunc();
      }}
    />
  );
}
