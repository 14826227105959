import { PropertyValueSet } from "@promaster-sdk/property";
import { ReportModule, ReportParamsProduct, ReportQueryProductParams, ReportType } from "../types";
import * as Common from "../common";
import { ImageData, PropertyImageData } from "../../images";
import { CalculateRequest, CalculateResult, CustomItems, ImageTableRows } from "../../calculator";
import { TranslateFn } from "../../lang-texts";
import { PriceAccessories } from "../../calculator/calc-price";
import { ActiveUser } from "../../user";
import * as GQLOps from "../../generated/generated-operations";

export const Name = "quote-page";
export type Response = {
  readonly images: readonly ImageData[];
  readonly quoteData: QuoteData;
  readonly cableInletImage: PropertyImageData;
};

export type CreatorProps = {
  readonly pageProps: Common.CataloguePageProps;
  readonly calculateRequest: CalculateRequest;
  readonly quoteData: QuoteData;
  readonly heaterImage: Common.BitmapImage | undefined;
  readonly cableInletImage: Common.BitmapImage | undefined;
};

export type QuoteDataRow = {
  readonly key: string;
  readonly translatedKey: string;
  readonly value: string;
  readonly style?: { readonly spaceBetweenRow?: boolean };
};

export type QuoteData = ReadonlyArray<QuoteDataRow>;

type ReportModuleType = ReportModule<CreatorProps, ReportQueryProductParams, ReportParamsProduct, Response>;
export type AdapterType = ReportModuleType["execute"];
export type CreatorType = ReportModuleType["create"];

export type QuoteReportParams = {
  readonly reportType: ReportType;
  readonly imageServiceUrl: string;
  readonly metaProductId: string;
  readonly ehProductId: string;
  readonly productKey: string;
  readonly calculateResult: CalculateResult;
  readonly calculateRequest: CalculateRequest;
  readonly translate: TranslateFn;
  readonly priceAccessories: PriceAccessories;
  readonly user: ActiveUser;
  readonly configurationLink: string;
  readonly customItems: CustomItems;
  readonly heaterImages: ReadonlyArray<ImageTableRows>;
  readonly properties: PropertyValueSet.PropertyValueSet;
};

export interface QuoteReportQueryParams extends QuoteReportParams {
  readonly imageResponse: GQLOps.ImageModulesFragment["images"]["image"] | undefined;
  readonly propertyImages: GQLOps.PropertyImageFragment["property_image"] | undefined;
}
