import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { ReportQueryResponse, ReportType } from "@ehb/shared/src/reports";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import {
  Reports,
  Search,
  Calculate,
  FP,
  CalculatorFrtCoil,
  SelectableFormat,
  logWarn,
  A3D,
  Accessories,
} from "@ehb/shared";
import { PropertyValueSet } from "@promaster-sdk/property";
import { SharedState, PromiseEffectManager, NavigationEffectManager as Navigation, Routes } from "@ehb/client-infra";
import { clientConfig } from "@ehb/client-infra/src/client-config";
import { productQuery } from "@ehb/shared/src/graphql-queries/product";
import {
  Accessory,
  getAccessoriesFromPvs,
  removeAccessoriesProperty,
  setAccessoriesInPvs,
} from "@ehb/shared/src/accessories";
import { MainLocation } from "@ehb/client-infra/src/routes";
import {
  decodePropertyValueSet,
  encodePropertyValueSet,
  encodeVariantPropertyValueSet,
} from "@ehb/shared/src/product-utils";
import { SharedStateAction } from "@ehb/client-infra/src/shared-state";
import { ActiveUser } from "@ehb/shared/src/user";
import { MagicloudApi } from "@ehb/shared/src";
import { handleReportGenerator } from "@ehb/client-infra/src/effect-managers/report-handler";
import { getItemIdProperties, getItemProperties, itemIdQueryParam } from "@ehb/shared/src/project";
import * as GQLOps from "../../generated/generated-operations";
import { generateDefaultProperties } from "../../elements/properties-selector/property-selector-def";
import * as ProjectState from "../../project-state";

type LocationParams = { readonly query: { readonly [query: string]: string }; readonly productKey: string };

export type ResultOrder = {
  readonly sortOrder: Search.SortOrder;
  readonly sortColumn: string;
};

export type MagicloudData = {
  readonly model: MagicloudApi.X3DModel | undefined;
  readonly loading: boolean;
};

export type ProductSelection = UrlSelection | ProjectSelection;

export type UrlSelection = {
  readonly type: "url";
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly selectedAccessories: ReadonlyArray<Accessory>;
};

export type ProjectSelection = {
  readonly type: "item";
  readonly itemId: string;
};

export type State = {
  readonly productSelection: ProductSelection;
  readonly autoCalculate: boolean;
  readonly searchProduct: GQLOps.ProductQuery | undefined;
  readonly calcData: CalculatorFrtCoil.CalculationData | undefined;
  readonly searchData: Search.SearchData | undefined;
  readonly searchResult: Search.SingleResult | undefined;
  readonly match: Search.Match | undefined;
  readonly calculating: boolean;
  readonly calculationResults: CalculatorFrtCoil.CalculationResult | undefined;
  readonly resultOrder: ResultOrder;
  readonly productKey: string;
  readonly locationParams: LocationParams;
  readonly magicloudData: MagicloudData | undefined;
  readonly orbitCamera: A3D.OrbitCamera | undefined;
  readonly printoutStatus: "printing" | "idle";
  readonly reportQueryRunner: Reports.QueryRunner | undefined;
  readonly reportResponse: Reports.ReportQueryResponse | undefined;
  readonly projectState: ProjectState.State;
};

export function init(
  prevState: State | undefined,
  sharedState: SharedState.SharedState,
  locationParams: LocationParams
): readonly [State, Cmd<Action>?] {
  let state: State =
    prevState && prevState.productKey === locationParams.productKey
      ? prevState
      : {
          locationParams,
          searchProduct: undefined,
          autoCalculate: true,
          calcData: undefined,
          searchData: undefined,
          searchResult: undefined,
          match: undefined,
          calculating: false,
          calculationResults: undefined,
          resultOrder: {
            sortOrder: "asc" as const,
            sortColumn: "model",
          },
          magicloudData: undefined,
          orbitCamera: undefined,
          printoutStatus: "idle",
          reportQueryRunner: undefined,
          reportResponse: undefined,
          productKey: locationParams.productKey,
          productSelection: { type: "url", variant: PropertyValueSet.Empty, selectedAccessories: [] },
          projectState: {
            type: "closed",
          },
        };
  state = { ...state, locationParams };

  const cmds = [];

  if (locationParams.query[itemIdQueryParam] || sharedState.openProjectId) {
    const [projectState, projectCmd] = ProjectState.init(state?.projectState, sharedState, locationParams.query);
    state = {
      ...state,
      projectState: projectState,
    };
    cmds.push(Cmd.map(Action.DispatchProject, projectCmd));
  }

  if (state.projectState.type !== "loading") {
    const [state2, initCmd2] = init2(state, sharedState);
    state = state2;
    cmds.push(initCmd2);
  }

  return [state, Cmd.batch(cmds)];
}

function init2(state: State, sharedState: SharedState.SharedState): readonly [State, Cmd<Action>?] {
  const { locationParams, projectState } = state;

  let newState = state;

  const itemId = locationParams.query[itemIdQueryParam];
  const project = projectState.type === "open" ? projectState.project : undefined;
  const itemProperties =
    project && itemId ? getItemIdProperties(project, "water_dx", newState.productKey, itemId) : undefined;

  if (itemProperties) {
    newState = {
      ...newState,
      productSelection: {
        type: "item",
        itemId,
      },
    };
  } else {
    const urlProperties = decodePropertyValueSet(locationParams.query, sharedState.activeUser);
    const urlVariant = removeAccessoriesProperty(urlProperties);
    const urlAccessories = getAccessoriesFromPvs(urlProperties);
    const oldVariant =
      newState.productSelection.type === "url" ? newState.productSelection.variant : PropertyValueSet.Empty;
    newState = {
      ...newState,
      productSelection: {
        type: "url",
        variant: PropertyValueSet.merge(urlVariant, oldVariant),
        selectedAccessories: urlAccessories,
      },
    };
  }

  if (newState.searchData) {
    return [
      newState,
      Cmd.batch([
        search(
          newState.searchData,
          createSearchQuery(
            sharedState.activeUser,
            newState.searchData,
            getVariant(newState, projectState),
            newState.productKey
          )
        ),
      ]),
    ];
  } else {
    const gqlCmdProduct = sharedState.graphQLProductQuery<GQLOps.ProductQuery, GQLOps.ProductQueryVariables, Action>(
      productQuery,
      { productId: clientConfig.promaster_search_product_id, language: sharedState.selectedLanguage },
      (data) => {
        return Action.SearchProductRecieved(data);
      }
    );
    return [newState, Cmd.batch([gqlCmdProduct])];
  }
}

export const Action = ctorsUnion({
  DispatchProject: (action: ProjectState.Action) => ({ action }),
  SearchProductRecieved: (data: GQLOps.ProductQuery) => ({ data }),
  SearchDataRecieved: (data: GQLOps.SearchSingleQuery) => ({ data }),
  CalcDataRecieved: (data: GQLOps.FrtCoilQuery) => ({ data }),
  SetVariant: (variant: PropertyValueSet.PropertyValueSet) => ({ variant }),
  UpdateSearchResult: (result: Search.SingleResult | undefined) => ({ result }),
  UpdateCalculationResult: (result: CalculatorFrtCoil.CalculationResult) => ({ result }),
  SetSelectedFormat: (fieldName: string, selectedFormat: SelectableFormat) => ({ fieldName, selectedFormat }),
  SetFieldUnit: (fieldName: string, unit: string, decimalCount: number) => ({
    fieldName,
    unit,
    decimalCount,
  }),
  ClearFieldUnit: (fieldName: string) => ({ fieldName }),
  SetResultOrder: (resultOrder: ResultOrder) => ({ resultOrder }),
  UpdateMagicloudData: (data: MagicloudData) => ({ data }),
  SetOrbitCamera: (camera: A3D.OrbitCamera | undefined) => ({ camera }),
  SetSelectedAccessory: (accessory: Accessory, selected: boolean) => ({ accessory, selected }),
  SetPrintoutStatus: (status: "idle" | "printing") => ({ status }),
  ReportDataReceived: (data: ReportQueryResponse, report: ReportType, done: boolean) => ({
    data,
    report: report as ReportType,
    done,
  }),

  GetReport: (report: ReportType) => ({ report: report as ReportType }),
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "DispatchProject": {
      const cmds = [];
      let newState = state;

      const [newProjectState, cmd, sharedStateAction] = ProjectState.update(
        action.action,
        state.projectState,
        sharedState
      );
      newState = {
        ...newState,
        projectState: newProjectState,
      };
      cmds.push(Cmd.map(Action.DispatchProject, cmd));

      if (newState.projectState.type !== "loading" && state.projectState.type === "loading") {
        // Continue initing if a project got loaded
        const [initState2, initCmd2] = init2(newState, sharedState);
        newState = initState2;
        cmds.push(initCmd2);
      }

      return [newState, Cmd.batch(cmds), sharedStateAction];
    }
    case "SearchProductRecieved": {
      const newState = { ...state, searchProduct: action.data };
      const gqlCmdSearch = sharedState.graphQLProductQuery<
        GQLOps.SearchSingleQuery,
        GQLOps.SearchSingleQueryVariables,
        Action
      >(
        Search.searchProductQuerySingle,
        {
          searchProductId: clientConfig.promaster_search_product_id,
          productId: sharedState.productByKey[state.productKey].id,
        },
        (data) => {
          return Action.SearchDataRecieved(data);
        }
      );
      return [newState, Cmd.batch([gqlCmdSearch])];
    }

    case "SearchDataRecieved": {
      const searchData = Search.createSearchData(action.data);
      const gqlCmdCalc = sharedState.graphQLProductQuery<GQLOps.FrtCoilQuery, GQLOps.FrtCoilQueryVariables, Action>(
        CalculatorFrtCoil.query,
        { searchProductId: clientConfig.promaster_search_product_id },
        (data) => {
          return Action.CalcDataRecieved(data);
        }
      );
      return [{ ...state, searchData: searchData }, Cmd.batch([gqlCmdCalc])];
    }

    case "CalcDataRecieved": {
      if (!state.searchData) {
        return [state];
      }
      const calcData = CalculatorFrtCoil.mapQuery(action.data);
      return [
        { ...state, calcData: calcData },
        search(
          state.searchData,
          createSearchQuery(
            sharedState.activeUser,
            state.searchData,
            getVariant(state, state.projectState),
            state.productKey
          )
        ),
      ];
    }

    case "SetVariant": {
      const productProperties = state.searchProduct?.product?.modules.properties.property;
      if (!state.searchData || !productProperties) {
        return [state];
      }
      const searchCmd = search(
        state.searchData,
        createSearchQuery(sharedState.activeUser, state.searchData, action.variant, state.productKey)
      );
      const [newState, updateCmd] = updateProductSelection(sharedState, state, action.variant, undefined, "update_url");
      return [newState, Cmd.batch([searchCmd, updateCmd])];
    }

    case "UpdateSearchResult": {
      if (!state.calcData || !state.searchData) {
        return [state];
      }

      const searchResult = action.result;
      if (!searchResult) {
        return [{ ...state, searchResult: undefined, calculationResults: undefined, match: undefined }];
      }

      const match = searchResult.match;
      const variant = getVariant(state, state.projectState);
      const [mcState, mcCmd] = loadMagicadData(state.magicloudData, state.searchData.searchProduct, match);
      const query = createSearchQuery(sharedState.activeUser, state.searchData, variant, state.productKey);
      const selectedAccessories = getSelectedAccessories(state);

      return [
        {
          ...state,
          calculating: state.autoCalculate,
          searchResult: searchResult,
          match: match,
          ...mcState,
        },
        Cmd.batch([
          state.autoCalculate ? calculate(state.calcData, variant, match, query, selectedAccessories) : undefined,
          mcCmd,
        ]),
      ];
    }

    case "UpdateCalculationResult": {
      return [{ ...state, calculating: false, calculationResults: action.result }];
    }

    case "SetSelectedFormat": {
      return [state, undefined, SharedStateAction.SetSelectedFormat(action.fieldName, action.selectedFormat)];
    }
    case "ClearFieldUnit": {
      return [state, undefined, SharedStateAction.ClearFieldUnit(action.fieldName)];
    }
    case "SetFieldUnit": {
      return [state, undefined, SharedStateAction.SetFieldUnit(action.fieldName, action.unit, action.decimalCount)];
    }

    case "SetResultOrder": {
      return [{ ...state, resultOrder: action.resultOrder }];
    }

    case "UpdateMagicloudData": {
      return [{ ...state, magicloudData: action.data, orbitCamera: undefined }];
    }

    case "SetOrbitCamera": {
      return [{ ...state, orbitCamera: action.camera }];
    }

    case "GetReport": {
      const reportParams = createReportParams(sharedState, state.projectState, state, action.report);
      const queryRunner = Reports.runReportQuries(clientConfig.imageServiceUrl, reportParams);
      const newState = {
        ...state,
        reportQueryRunner: queryRunner,
      };

      return [
        newState,
        handleReportGenerator(
          queryRunner,
          sharedState,
          undefined,
          (value: Reports.ReportQueryResponse, done: boolean): Action => {
            return Action.ReportDataReceived(value, action.report, done);
          }
        ),
      ];
    }

    case "ReportDataReceived": {
      if (!state.projectState || !state.reportQueryRunner) {
        return [state];
      }

      if (action.done) {
        const reportParams = createReportParams(sharedState, state.projectState, state, action.report);
        const cmd = print(action.data, reportParams, action.report) as Cmd<Action>;
        return [
          {
            ...state,
            reportQueryRunner: undefined,
            reportResponse: action.data,
          },
          cmd,
        ];
      }

      return [
        state,
        handleReportGenerator(
          state.reportQueryRunner,
          sharedState,
          action.data,
          (value: Reports.ReportQueryResponse, done: boolean): Action => {
            return Action.ReportDataReceived(value, action.report, done);
          }
        ),
      ];
    }

    case "SetPrintoutStatus": {
      return [{ ...state, printoutStatus: action.status }];
    }

    case "SetSelectedAccessory": {
      if (!state.calcData || !state.match || !state.searchData) {
        return [state];
      }
      const variant = getVariant(state, state.projectState);
      const selectedAccessoriesOld = getSelectedAccessories(state);
      const newSelectedAccessories = action.selected
        ? [...selectedAccessoriesOld, action.accessory]
        : selectedAccessoriesOld.filter((a) => a.articleNumber !== action.accessory.articleNumber);

      const query = createSearchQuery(sharedState.activeUser, state.searchData, variant, state.productKey);
      const calculateCmd = calculate(state.calcData, variant, state.match, query, newSelectedAccessories);

      const [newState, updateCmd] = updateProductSelection(
        sharedState,
        state,
        undefined,
        newSelectedAccessories,
        "update_url"
      );

      return [newState, Cmd.batch([updateCmd, calculateCmd])];
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

function search(
  searchData: Search.SearchData,
  query: Search.Query
): PromiseEffectManager.PromiseEffect<Action, never, Search.SingleResult | undefined> {
  return PromiseEffectManager.perform<Action, Search.SingleResult | undefined>(
    (data) => Action.UpdateSearchResult(data),
    (async (): Promise<FP.Result<never, Search.SingleResult | undefined>> => ({
      type: "Ok",
      value: Search.searchSingle(searchData, query),
    }))()
  );
}

function calculate(
  calcData: CalculatorFrtCoil.CalculationData,
  properties: PropertyValueSet.PropertyValueSet,
  match: Search.Match,
  query: Search.Query,
  accessories: ReadonlyArray<Accessories.Accessory>
): PromiseEffectManager.PromiseEffect<Action, never, CalculatorFrtCoil.CalculationResult> {
  return PromiseEffectManager.perform<Action, CalculatorFrtCoil.CalculationResult>(
    (data) => Action.UpdateCalculationResult(data),
    (async (): Promise<FP.Result<never, CalculatorFrtCoil.CalculationResult>> => {
      return {
        type: "Ok",
        value: await Calculate.calculateProduct(
          calcData,
          properties,
          match.selection,
          match.productVariantRow,
          query,
          accessories
        ),
      };
    })()
  );
}

function createSearchQuery(
  activeUser: ActiveUser,
  searchData: Search.SearchData,
  variant: PropertyValueSet.PropertyValueSet,
  productKey: string
): Search.Query {
  return {
    company: activeUser.companyName,
    filter: Search.propertiesToSearchFilter(searchData, variant),
    productKey: productKey,
    userCurrency: activeUser.claims.currency,
  };
}

function loadMagicadData(
  magicloudData: MagicloudData | undefined,
  viewData: GQLOps.SearchSingleQuery | undefined,
  match: Search.Match | undefined
): readonly [Partial<State>, PromiseEffectManager.PromiseEffect<Action, never, MagicloudData> | undefined] {
  const newState = { magicloudData: undefined };
  if (!viewData || !match) {
    return [newState, undefined];
  }
  const rows = Search.filterRows(match.selection, viewData.product?.modules.custom_tables.product_variants || []);
  if (rows.length !== 1) {
    logWarn("loadMagicadData: rows.length !== 1");
    return [newState, undefined];
  }
  const magicloudId = rows[0].magicloud_id;
  if (!magicloudId) {
    return [newState, undefined];
  }
  if (
    !magicloudData?.loading &&
    magicloudData?.model?.magicloudId &&
    magicloudData?.model?.magicloudId === magicloudId
  ) {
    return [{}, undefined];
  }
  return [
    { magicloudData: { model: magicloudData?.model, loading: true } },
    PromiseEffectManager.perform<Action, MagicloudData>(
      (data) => Action.UpdateMagicloudData(data),
      (async (): Promise<FP.Result<never, MagicloudData>> => {
        return {
          type: "Ok",
          value: await (async (): Promise<MagicloudData> => {
            const model = await MagicloudApi.getX3DModelByGuid(magicloudId, undefined);
            return {
              model,
              loading: false,
            };
          })(),
        };
      })()
    ),
  ];
}

function createReportParams(
  sharedState: SharedState.SharedState,
  projectState: ProjectState.State,
  state: State,
  report: ReportType
): Reports.ReportParamsProduct {
  const variant = getVariant(state, projectState);
  const params: Reports.ReportParamsProduct = {
    reportType: report,
    imageServiceUrl: clientConfig.imageServiceUrl,
    metaProductId: clientConfig.promaster_meta_id,
    ehProductId: clientConfig.promaster_eh_id,
    translate: sharedState.translate,
    calculateResult: undefined,
    calculateRequest: undefined,
    priceAccessories: undefined,
    user: sharedState.activeUser,
    configurationLink: getConfigurationLink(state, sharedState),
    customItems: [],
    heaterImages: [],
    properties: variant,
    productKey: state.productKey,
    clientConfig: clientConfig,
    productByKey: sharedState.productByKey,
    productById: sharedState.productById,
    selectedLanguage: sharedState.selectedLanguage,
    getFieldFormat: sharedState.getFieldFormat,
    waterAccessories: getSelectedAccessories(state),
  };

  return params;
}

function getConfigurationLink(state: State, sharedState: SharedState.SharedState): string {
  const { productSelection, projectState } = state;
  if (productSelection.type === "url") {
    return window.location.href;
  } else if (projectState.type === "open") {
    const item = projectState.project.items.find((i) => i.id === productSelection.itemId);
    if (!item) {
      throw new Error(`getConfigurationLink: item not found '${productSelection.itemId}'`);
    }
    return (
      window.location.origin +
      Routes.buildMainUrl(
        MainLocation.ProductCalculate(
          encodeVariantPropertyValueSet(getItemProperties(item), [], sharedState.activeUser),
          item.product
        )
      )
    );
  } else {
    return "";
  }
}

function print(
  reportResponse: Reports.ReportQueryResponse,
  reportParams: Reports.ReportParamsProduct,
  report: ReportType
): PromiseEffectManager.PromiseEffect<Action, never, undefined> {
  const date = new Date().toISOString().split("T")[0];
  const reportName = report === "quote-page-water" ? "Quote" : report === "technical-data-sheet" ? "Data sheet" : "";
  const fileName = `${reportName} ${date}.pdf`;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pdfKit = (window as any).PDFDocument;
  return PromiseEffectManager.perform<Action, undefined>(
    () => Action.SetPrintoutStatus("idle"),
    (async (): Promise<FP.Result<never, undefined>> => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      await Reports.createPrintoutClient(reportResponse, [reportParams], fileName, pdfKit, () => {});
      return {
        type: "Ok",
        value: undefined,
      };
    })()
  );
}

export function getVariant(state: State, projectState: ProjectState.State): PropertyValueSet.PropertyValueSet {
  const { productSelection, searchProduct } = state;

  const propertyTable = searchProduct?.product?.modules.properties.property;
  if (!propertyTable) {
    throw new Error("getVariant: search product data must be loaded");
  }

  let variant;
  if (productSelection.type === "url") {
    variant = productSelection.variant;
  } else if (projectState.type === "open") {
    variant =
      getItemIdProperties(projectState.project, "water_dx", state.productKey, productSelection.itemId) ||
      PropertyValueSet.Empty;
  } else {
    variant = PropertyValueSet.Empty;
  }

  variant = PropertyValueSet.setInteger("mode", 2, variant); // mode=2 for product page

  variant = generateDefaultProperties(propertyTable, variant);

  return variant;
}

export function getSelectedAccessories(state: State): ReadonlyArray<Accessory> {
  const { productSelection, projectState } = state;
  if (productSelection.type === "url") {
    return productSelection.selectedAccessories;
  } else if (projectState.type === "open") {
    const pvs =
      getItemIdProperties(projectState.project, "water_dx", state.productKey, productSelection.itemId) ||
      PropertyValueSet.Empty;
    return getAccessoriesFromPvs(pvs);
  } else {
    return [];
  }
}

export function updateProductSelection(
  sharedState: SharedState.SharedState,
  state: State,
  variant: PropertyValueSet.PropertyValueSet | undefined,
  selectedAccessories: ReadonlyArray<Accessory> | undefined,
  updateUrl: "update_url" | "dont_update_url"
): readonly [State, Cmd<Action>?] {
  const { productSelection, projectState } = state;

  const productProperties = state.searchProduct?.product?.modules.properties.property;
  if (!state.searchData || !productProperties) {
    return [state];
  }

  const newVariant = variant || getVariant(state, projectState);
  const newSelectedAccessories = selectedAccessories || getSelectedAccessories(state);

  const pvs = setAccessoriesInPvs(newVariant, newSelectedAccessories);

  if (productSelection.type === "url") {
    return [
      { ...state, productSelection: { type: "url", variant: newVariant, selectedAccessories: newSelectedAccessories } },
      Cmd.batch([
        search(
          state.searchData,
          createSearchQuery(sharedState.activeUser, state.searchData, newVariant, state.productKey)
        ),
        updateUrl === "update_url"
          ? Navigation.replaceUrl<Action>(
              Routes.buildMainUrl(
                MainLocation.ProductCalculate(
                  encodePropertyValueSet(pvs, sharedState.activeUser.claims),
                  state.productKey
                )
              ),
              undefined,
              true
            )
          : undefined,
      ]),
    ];
  } else {
    const [newProjectState, projectCmd] = ProjectState.updateItemProperties(
      sharedState,
      projectState,
      productSelection.itemId,
      pvs
    );
    return [{ ...state, projectState: newProjectState }, Cmd.map(Action.DispatchProject, projectCmd)];
  }
}
