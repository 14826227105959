import { AbstractDoc as AD } from "abstract-document";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as GQLOps from "../generated/generated-operations";
import { TranslateFn } from "../lang-texts";
import { QueryGenerator } from "../query";
import { ImageData } from "../images";
import { CalculateRequest, CalculateResult, CustomItems, ImageTableRows } from "../calculator";
import { PriceAccessories } from "../calculator/calc-price";
import { ActiveUser } from "../user";
import * as QuotePage from "./quote-page-section";
import * as TechnicalDataSheet from "./technical-data-sheet";
import * as QuotePageWater from "./quote-page-water";
import * as ProjectFirstPage from "./project/first-page";
import { ClientConfig } from "../client-config";
import { GetFieldFormatFn } from "../units";
import { Accessories } from "..";

export type ReportResponse = QuotePage.Response | TechnicalDataSheet.Response;
export type CreatorProps = QuotePage.CreatorProps | TechnicalDataSheet.CreatorProps;

const reports = [QuotePage, TechnicalDataSheet, QuotePageWater, ProjectFirstPage];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reportSections: ReadonlyArray<ReportModule<any, any, any, any>> = reports;

const ReportNames = reports.map((r) => r.Name);
export type ReportType = (typeof ReportNames)[number];

export type ReportModule<TInput, TQueryParams extends ReportQueryParams, TParams extends ReportParams, TResponse> = {
  readonly Name: ReportType;
  readonly query: (params: TQueryParams) => QueryGenerator<TResponse>;
  readonly execute: (params: TParams, commonResponse: CommonResponse, response: TResponse) => Promise<TInput>;
  readonly create: (params: TInput) => AD.AbstractDoc.AbstractDoc;
};

type Product = GQLOps.AllProductsQuery["products"][number];

export interface ReportParamsShared {
  readonly reportType: ReportType;
  readonly imageServiceUrl: string;
  readonly metaProductId: string;
  readonly ehProductId: string;
  readonly translate: TranslateFn;
  readonly user: ActiveUser;
  readonly clientConfig: ClientConfig;
  readonly selectedLanguage: string;
  readonly productByKey: Record<string, Product>;
  readonly productById: Record<string, Product>;
  readonly getFieldFormat: GetFieldFormatFn;
}

export type ReportParamsProduct = ReportParamsShared & {
  readonly productKey: string;
  readonly calculateResult: CalculateResult | undefined;
  readonly calculateRequest: CalculateRequest | undefined;
  readonly priceAccessories: PriceAccessories | undefined;
  readonly waterAccessories: ReadonlyArray<Accessories.Accessory>;
  readonly configurationLink: string;
  readonly customItems: CustomItems;
  readonly heaterImages: ReadonlyArray<ImageTableRows>;
  readonly properties: PropertyValueSet.PropertyValueSet;
};

export type ReportParamsProject = ReportParamsShared & {
  readonly project: GQLOps.ProjectResponse;
};

export type ReportParams = ReportParamsProduct | ReportParamsProject;

export type ReportQueryProductParams = ReportParamsProduct & {
  readonly imageResponse: GQLOps.ImageModulesFragment["images"]["image"] | undefined;
  readonly propertyImages: GQLOps.PropertyImageFragment["property_image"] | undefined;
};

export type ReportQueryProjectParams = ReportParamsProject & {
  readonly imageResponse: GQLOps.ImageModulesFragment["images"]["image"] | undefined;
  readonly propertyImages: GQLOps.PropertyImageFragment["property_image"] | undefined;
};

export type ReportQueryParams = ReportQueryProductParams | ReportQueryProjectParams;

export type CommonResponse = {
  readonly logoImage: ReadonlyArray<ImageData>;
};

export type ReportQueryResponse = {
  readonly commonResponse: CommonResponse;
  readonly reportResponses: ReadonlyArray<ReportResponse>;
};

export type QueryRunner = QueryGenerator<ReportQueryResponse>;
