import { QueryGenerator } from "@ehb/shared/src/query";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import { FP, Query } from "@ehb/shared";
import { HttpFetch, PromiseEffectManager, SharedState, graphQLQueryWithAuth } from "../../";

// A more generic handler for report/query generators
export function handleReportGenerator<T, A>(
  generator: QueryGenerator<T>,
  sharedState: SharedState.SharedState,
  data: unknown,
  callback: (value: unknown, done: boolean) => A
): Cmd<A> {
  const result = generator.next(data);

  if (result.done) {
    // If generator is done, we run the final callback
    return PromiseEffectManager.perform<unknown, unknown>(
      () => callback(result.value, true),
      (async (): Promise<FP.Result<never, unknown>> => ({
        type: "Ok",
        value: result.value,
      }))()
    );
  }

  // Otherwise, we handle the next query with the provided callback
  return queryToCommand(result.value, sharedState, (data: unknown) => callback(data, false));
}

// Generic query handler that executes queries and performs a callback
function queryToCommand<A>(
  query: Query.Query,
  sharedState: SharedState.SharedState,
  callback: (value: unknown) => A
): Cmd<A> {
  switch (query.type) {
    case "HttpBlobQuery": {
      return HttpFetch.fetchOne({}, query.url, "blob", callback);
    }
    case "HttpBlobQueryMultiple": {
      return HttpFetch.fetchMultiple({}, query.url, "blob", callback);
    }
    case "GraphQLProductQuery": {
      return sharedState.graphQLProductQuery<unknown, unknown, A>(query.query, query.variables, callback);
    }
    case "GraphQLQuery": {
      return graphQLQueryWithAuth(sharedState.activeUser)<unknown, unknown, A>(query.query, query.variables, callback);
    }
    case "PromiseQuery": {
      return PromiseEffectManager.perform<unknown, unknown>(
        (data: unknown) => callback(data),
        (async (): Promise<FP.Result<never, unknown>> => ({
          type: "Ok",
          value: await query.promise,
        }))()
      );
    }
    default:
      exhaustiveCheck(query);
      throw new Error("Not implemented");
  }
}
