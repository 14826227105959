import * as R from "ramda";
import React, { Dispatch } from "react";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import { getFilteredImagesTable } from "@ehb/shared/src/calculator";
import { Routes, SharedState } from "@ehb/client-infra";
import { calculationWithinTargetThreshold, fixedAirflowFilter, ModeResult } from "@ehb/shared/src/search";
import { Calculate, GetFieldFormatFn, GetFieldFormatsFn, Search, Texts } from "@ehb/shared";
import { encodeVariantPropertyValueSet } from "@ehb/shared/src/product-utils";
import { ActiveUser, isInternalUser } from "@ehb/shared/src/user";
import { texts } from "@ehb/shared/src/lang-texts";
import { MainLocation } from "@ehb/client-infra/src/routes";
import { CenterMessage, Icon, PropertiesSelector, Spinner, SpinnerIcon, withTw } from "../../elements";
import { State, Action, ResultOrder } from "./state";
import {
  PropertySelectorDef,
  PropertyValueDef,
  mapPropertiesToSelector,
} from "../../elements/properties-selector/property-selector-def";
import { Button } from "../../elements/button";
import { propertyDefsFromSearchResult } from "../../elements/properties-selector/search-properties";
import { FieldFormatSelector } from "../../elements/field-format-selector";
import * as GQLOps from "../../generated/generated-operations";
import { Group } from "../../elements/group";
import { DropdownInput, Option } from "../../elements/dropdown-input";

const Container = withTw(
  "div",
  "relative gap-4 block gap-10 text-xs flex-col lg:flex-row max-w-[1400px] ml-auto mr-auto w-full pt-4 pb-4"
);
const FlexContainer = withTw("div", "flex w-full");
const SearchButtonContainer = withTw("div", "flex justify-center items-center");
const ResultColumnContainer = withTw("div", "w-full");
const LabelTh = withTw("th", "group h-[25px] cursor-pointer hover:text-red-600 select-none");
const UnitTh = withTw("th", "pb-2 pl-2 font-normal");
const StickyHead = withTw("thead", "bg-white z-50 sticky top-0");
const TableHeadRow = withTw("tr", "[&>*]:py-2");
const TableTr = withTw("tr", "h-[50px]");
const TableTBody = withTw("tbody", "hover:bg-[#fff8f8] h-[50px] border-t border-[#e5e7eb]");
const TableTd = withTw("td", "");

const ModelLink = withTw("a", "font-bold");

export function View({
  state,
  sharedState,
  dispatch,
}: {
  readonly state: State;
  readonly sharedState: SharedState.SharedState;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const { activeUser, translate, selectedLanguage, getFieldFormat, getFieldFormats } = sharedState;
  const { variant, searchProduct, searchData, searchResult, calculationResults, calculating, calcData, resultOrder } =
    state;

  const searchProperties = React.useMemo(
    () => (searchResult ? propertyDefsFromSearchResult(translate, searchResult) : undefined),
    [searchResult]
  );

  const propertySelectorDefs = React.useMemo(() => {
    if (!searchData || !searchResult) {
      return [];
    }
    const defs = mapPropertiesToSelector(activeUser, searchProduct, variant, translate);
    const defsWithSearchValues = defs.map((def): PropertySelectorDef => {
      const values = Search.getPropertyValues(translate, searchData, searchResult, def.name);

      if (!values) {
        return { ...def };
      }

      return {
        ...def,
        type: "Discrete",
        selectorType: "dropdown",
        items: values.map(
          (v, i): PropertyValueDef => ({
            sortNo: i,
            value: v.value,
            validationFilter: v.validationFilter,
            text: v.text,
          })
        ),
      };
    });
    return defsWithSearchValues;
  }, [searchProduct, selectedLanguage, searchResult, PropertyValueSet.toString(variant)]);

  const variantOk = React.useMemo(() => {
    return propertySelectorDefs.every(
      (p) => !PropertyFilter.isValid(variant, p.visibilityFilter) || PropertyFilter.isValid(variant, p.validationFilter)
    );
  }, [propertySelectorDefs, PropertyValueSet.toString(variant)]);

  if (!searchProduct?.product || !searchData || !searchResult || !calcData || searchProperties === undefined) {
    return <Spinner />;
  }

  const globalVariant = state.variant;

  const resultOrderOptions: ReadonlyArray<Option<Search.ModeResult>> = [
    { key: "heating", label: translate(texts.mode_results("heating")), value: "heating" },
    { key: "cooling", label: translate(texts.mode_results("cooling")), value: "cooling" },
  ];

  const calcuationMode = calcData.calculationInput.find(
    (r) => PropertyFilter.isValid(globalVariant, r.property_filter) && r.param === "function"
  )?.value;

  const resultToUseForOrdering =
    calcuationMode === "heating_and_cooling"
      ? state.resultToUseForOrdering
      : calcuationMode === "heating"
      ? "heating"
      : "cooling";

  return (
    <Container>
      <h1 className="mt-2 mb-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        {translate(texts.start_page_heaters_coolers)}
      </h1>
      <div className="flex justify-center ">
        <FlexContainer>
          <div className="min-w-[450px] mr-8 space-y-6">
            <PropertiesSelector
              selectedProperties={variant}
              properties={propertySelectorDefs}
              onChange={(
                newSelectedProperties: PropertyValueSet.PropertyValueSet,
                _propertyNames: ReadonlyArray<string>
              ) => {
                dispatch(Action.SetVariant(newSelectedProperties));
              }}
              onPropertyFormatChanged={(fieldName, unit, decimalCount) => {
                dispatch(Action.SetSelectedFormat(fieldName, { unit, decimalCount }));
              }}
              onPropertyFormatCleared={(fieldName) => {
                dispatch(Action.ClearFieldUnit(fieldName));
              }}
              activeUser={activeUser}
              productImages={getFilteredImagesTable(searchProduct, variant, false)}
              translate={translate}
              productKey={searchProduct.product.key}
              getFieldFormat={getFieldFormat}
              getFieldFormats={getFieldFormats}
            />

            <SearchButtonContainer>
              <Button
                disabled={state.calculating || searchResult.matches.length === 0 || !variantOk}
                label={translate(texts.calculate_products(searchResult.matches.length))}
                onClick={() => dispatch(Action.StartSearch())}
              />
            </SearchButtonContainer>
          </div>
          <ResultColumnContainer>
            {calcuationMode === "heating_and_cooling" && (
              <Group>
                <div className="inline-flex row gap-4 items-center">
                  <span className="text-nowrap">{translate(texts.order_results_by)}</span>
                  <DropdownInput
                    value={resultOrderOptions.find((o) => o.value === state.resultToUseForOrdering)}
                    options={resultOrderOptions}
                    onChange={(v) => dispatch(Action.SetResultToUseForOrdering(v.value))}
                  />
                </div>
              </Group>
            )}
            {calculationResults ? (
              <ResultsView
                dispatch={dispatch}
                translate={translate}
                getFieldFormat={getFieldFormat}
                getFieldFormats={getFieldFormats}
                searchResult={searchResult}
                calculationResults={calculationResults}
                resultOrder={resultOrder}
                globalVariant={globalVariant}
                searchData={searchData}
                searchProduct={searchProduct}
                sharedState={sharedState}
                variant={state.variant}
                activeUser={sharedState.activeUser}
                searchResultColumnTable={searchData.searchResultColumns}
                productImages={state.productImages}
                resultToUseForOrdering={resultToUseForOrdering}
              />
            ) : (
              calculating && (
                <div className="w-full flex justify-center items-center">
                  <SpinnerIcon small={true} />
                </div>
              )
            )}
          </ResultColumnContainer>
        </FlexContainer>
      </div>
    </Container>
  );
}

function ResultsView({
  activeUser,
  dispatch,
  searchResult,
  calculationResults,
  translate,
  getFieldFormat,
  getFieldFormats,
  searchResultColumnTable,
  resultOrder,
  globalVariant,
  searchData,
  productImages,
  variant,
  searchProduct,
  sharedState,
  resultToUseForOrdering,
}: {
  readonly activeUser: ActiveUser;
  readonly dispatch: Dispatch<Action>;
  readonly translate: Texts.TranslateFn;
  readonly getFieldFormat: GetFieldFormatFn;
  readonly getFieldFormats: GetFieldFormatsFn;
  readonly searchResult: Search.Result;
  readonly calculationResults: Calculate.CalculationResultByKey;
  readonly searchResultColumnTable: Search.SearchResultColumnTable;
  readonly resultOrder: ResultOrder;
  readonly globalVariant: PropertyValueSet.PropertyValueSet;
  readonly searchData: Search.SearchData;
  readonly productImages: ReadonlyMap<string, string>;
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly searchProduct: GQLOps.ProductQuery;
  readonly sharedState: SharedState.SharedState;
  readonly resultToUseForOrdering: Search.ModeResult;
}): JSX.Element {
  const resultsMaybeErrors = Search.createTableResults(
    translate,
    getFieldFormat,
    globalVariant,
    searchResultColumnTable,
    searchResult,
    calculationResults,
    resultOrder.sortColumn,
    resultOrder.sortOrder,
    resultToUseForOrdering,
    activeUser
  );

  //const rows = resultsMaybeErrors.rows.filter((r) => r.messages.length === 0);
  const rows = resultsMaybeErrors.rows;

  if (rows.length === 0) {
    return <CenterMessage message={translate(texts.no_results_found_message)} />;
  }

  const bothFixedAndNonFixedAirflow =
    searchResult.matches.some(
      (r) => Number.isNaN(r.selection.fixed_airflow) || Number(r.selection.fixed_airflow) === 0
    ) && searchResult.matches.some((r) => Number(r.selection.fixed_airflow) > 0);

  const filteredFixedAirflowRows = bothFixedAndNonFixedAirflow
    ? fixedAirflowFilter(rows, searchResult.matches, variant)
    : rows;

  const rowsWithinThreshold = filteredFixedAirflowRows.filter((r) =>
    calculationWithinTargetThreshold(
      calculationResults.get(r.selectionKey),
      variant,
      searchData.searchCalculationLimits,
      searchData.searchCalculationMargin
    )
  );

  if (rowsWithinThreshold.length === 0) {
    return <CenterMessage message={translate(texts.no_results_found_tolerance_message)} />;
  }

  const results = {
    ...resultsMaybeErrors,
    rows: rowsWithinThreshold,
  };

  const groupRowsByName = R.groupBy((p) => p.group || "main", results.rows);
  return (
    <table className="w-full">
      <StickyHead>
        <TableHeadRow>
          {results.columns.map((r) => (
            <ResultColumn key={r.key} dispatch={dispatch} resultOrder={resultOrder} resultColumn={r} />
          ))}
        </TableHeadRow>

        <TableHeadRow>
          {results.columns.map((r) => (
            <UnitTh key={r.key} className={r.type === "result" || r.type === "price" ? "text-right" : "text-left"}>
              {r.fieldName && (
                <FieldFormatSelector
                  getFieldFormat={getFieldFormat}
                  getFieldFormats={getFieldFormats}
                  fieldName={r.fieldName}
                  setFieldFormat={(f) => dispatch(Action.SetSelectedFormat(r.fieldName!, f))}
                  align={"right"}
                  activeUser={activeUser}
                />
              )}
            </UnitTh>
          ))}
        </TableHeadRow>
      </StickyHead>

      {R.keys(groupRowsByName).map((group) => {
        const productWebsiteUrl = searchProduct.product?.modules.custom_tables.website_product_url.find(
          (u) => u.language === sharedState.selectedLanguage && u.product === groupRowsByName[group][0].productId
        );

        return (
          <React.Fragment key={group}>
            <tbody>
              <tr>
                <td colSpan={results.columns.length}>
                  <div className="flex flex-row items-center text-red-700 font-bold pt-5 relative h-24">
                    <img
                      src={productImages.get(groupRowsByName[group][0].productId)}
                      height="100px"
                      width="100px"
                      className="max-w-16 max-h-16 mr-4"
                    ></img>
                    <span className="text-base">{group}</span>
                    {productWebsiteUrl?.url && (
                      <div className="ml-auto">
                        <a href={productWebsiteUrl.url} target={"_blank"} rel={"noopener noreferrer"}>
                          <Button label={translate(texts.view_product)} iconRight={"right-to-bracket"} type={"nav"} />
                        </a>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
            {groupRowsByName[group].map((row) => (
              <TableTBody key={row.key}>
                <TableTr>
                  {row.values.map((value, i) => (
                    <ResultTd
                      key={results.columns[i].key}
                      {...{ translate, value, activeUser, row, globalVariant, searchData }}
                      modeResult={row.availableModeResults[0]}
                      numModes={row.availableModeResults.length}
                      rowSpan={row.availableModeResults.length}
                    />
                  ))}
                </TableTr>
                {row.availableModeResults.length > 1 && (
                  <TableTr>
                    {row.values
                      .filter((v) => v.type === "result" || v.type === "mode")
                      .map((value, i) => (
                        <ResultTd
                          key={results.columns[i].key}
                          {...{ translate, value, activeUser, row, globalVariant, searchData }}
                          modeResult={row.availableModeResults[1]}
                          numModes={row.availableModeResults.length}
                          rowSpan={1}
                        />
                      ))}
                  </TableTr>
                )}
              </TableTBody>
            ))}
          </React.Fragment>
        );
      })}
    </table>
  );
}

function ResultColumn({
  dispatch,
  resultOrder,
  resultColumn,
}: {
  readonly dispatch: Dispatch<Action>;
  readonly resultOrder: ResultOrder | undefined;
  readonly resultColumn: Search.ResultColumn;
}): JSX.Element {
  if (!resultColumn.label) {
    return <LabelTh />;
  }
  const textAlign = resultColumn.type === "result" || resultColumn.type === "price" ? "right" : "left";
  const columnSortOrder = resultOrder?.sortColumn === resultColumn.key ? resultOrder : undefined;

  return (
    <LabelTh
      key={resultColumn.key}
      onClick={() => {
        if (resultColumn.type === "mode") {
          return;
        }
        dispatch(
          Action.SetResultOrder({
            sortColumn: resultColumn.key,
            sortOrder: columnSortOrder
              ? columnSortOrder.sortOrder === "desc"
                ? "asc"
                : "desc"
              : resultColumn.intialSortOrder,
          })
        );
      }}
    >
      <div className={`p-1 space-x-1 text-left flex flex-row ${textAlign === "right" ? "justify-end" : ""}`}>
        {resultColumn.type !== "mode" && (
          <SearchIcon resultOrder={columnSortOrder} initialOrder={resultColumn.intialSortOrder} />
        )}
        <div>{resultColumn.label}</div>
      </div>
    </LabelTh>
  );
}

function SearchIcon({
  resultOrder,
  initialOrder,
}: {
  readonly resultOrder: ResultOrder | undefined;
  readonly initialOrder: Search.SortOrder;
}): JSX.Element {
  const sortOrder = resultOrder ? resultOrder.sortOrder : initialOrder;
  const className = ` w-4 ${
    resultOrder === undefined ? "text-gray-500 invisible group-hover:visible" : "text-gray-500"
  }`;
  if (sortOrder === "asc") {
    return <Icon className={className} prefix="fas" icon="arrow-down-short-wide" />;
  } else if (sortOrder === "desc") {
    return <Icon className={className} prefix="fas" icon="arrow-up-wide-short" />;
  } else {
    exhaustiveCheck(sortOrder, true);
  }
}

function ResultTd({
  translate,
  activeUser,
  row,
  value,
  globalVariant,
  searchData,
  rowSpan,
  numModes,
  modeResult,
}: {
  readonly translate: Texts.TranslateFn;
  readonly activeUser: ActiveUser;
  readonly row: Search.ResultRow;
  readonly value: Search.ResultColumnValue;
  readonly globalVariant: PropertyValueSet.PropertyValueSet;
  readonly searchData: Search.SearchData;
  readonly rowSpan: number;
  readonly numModes: number;
  readonly modeResult: ModeResult;
}): JSX.Element {
  const makeTooltip = (type: "error" | "warning"): string =>
    row.messages
      .filter((m) => m.type === type)
      .map((m) => `• ${m.text}`)
      .join("\n");
  const bgClassName = numModes > 1 ? (modeResult === "heating" ? " bg-[#E31F2110]" : " bg-[#11B3E210]") : "";
  if (value.type === "meta") {
    switch (value.value) {
      case "model": {
        const properties = PropertyValueSet.merge(
          PropertyValueSet.keepProperties(
            searchData.searchColumnProperties.map((p) => p.property || ""),
            row.variant
          ),
          globalVariant
        );
        return (
          <TableTd className="p-1 text-left" style={{ minWidth: "100px" }} rowSpan={rowSpan}>
            <ModelLink
              href={Routes.buildMainUrl(
                MainLocation.ProductCalculate(encodeVariantPropertyValueSet(properties, [], activeUser), row.productKey)
              )}
            >
              {row.model}
            </ModelLink>
          </TableTd>
        );
      }
      case "errors": {
        return (
          <TableTd title={makeTooltip("error")} rowSpan={rowSpan}>
            {row.messages.some((m) => m.type === "error") ? (
              <Icon icon="circle-xmark" className="error-icon pl-1" size="xl" />
            ) : null}
          </TableTd>
        );
      }
      case "warnings": {
        return (
          <TableTd title={makeTooltip("warning")} rowSpan={rowSpan}>
            {row.messages.some((m) => m.type === "warning") ? (
              <Icon icon="triangle-exclamation" className="warning-icon pl-1" size="xl" />
            ) : null}
          </TableTd>
        );
      }
      default:
        exhaustiveCheck(value, true);
    }
  } else if (value.type === "mode") {
    return <TableTd className={"p-1 tabular-nums" + bgClassName}>{translate(texts.mode(value[modeResult]))}</TableTd>;
  } else if (value.type === "result") {
    return <TableTd className={"p-1 tabular-nums text-right" + bgClassName}>{value[modeResult]}</TableTd>;
  } else if (value.type === "variant_column") {
    return (
      <TableTd className="p-1 tabular-nums text-right" rowSpan={rowSpan}>
        {value.value}
      </TableTd>
    );
  } else if (value.type === "price") {
    return isInternalUser(activeUser.claims) ? (
      <TableTd className="p-1 tabular-nums text-right" rowSpan={rowSpan}>
        {value.value}
      </TableTd>
    ) : (
      <TableTd rowSpan={rowSpan} />
    );
  } else {
    exhaustiveCheck(value, true);
  }
}
