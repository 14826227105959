import { Cmd, Result } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { productQuery as ProductQuery } from "@ehb/shared/src/graphql-queries/product";
import { PropertyValueSet as PVS, PropertyValue as PV, PropertyValueSet } from "@promaster-sdk/property";
import { Calculator, FP, Reports, Query, SelectableFormat } from "@ehb/shared";
import {
  CircuitDiagramTableRows,
  DocumentTableRows,
  getFilteredCircuitDiagramTable,
  getFilteredDocumentsTable,
  getFilteredImagesTable,
  getFilteredPriceTables,
  ImageTableRows,
} from "@ehb/shared/src/calculator/get-tables";
import {
  CalculateRequest,
  CalculateResult,
  Currency,
  CustomItem,
  CustomItems,
  ErrorResult,
} from "@ehb/shared/src/calculator/types";
import { texts } from "@ehb/shared/src/lang-texts";
import {
  customItemsQueryParam,
  decodeCustomItemsFromParams,
  decodeCustomItemsFromPvs,
  decodePropertyValueSet,
  encodeCustomItemsToPvs,
  encodeVariantPropertyValueSet,
  reverseLookUpVariant,
} from "@ehb/shared/src/product-utils";
import {
  NavigationEffectManager as Navigation,
  SharedState,
  graphQLMutationWithAuth,
  HttpFetch,
  graphQLQueryWithAuth,
  Routes,
  PromiseEffectManager,
} from "@ehb/client-infra";
import { updateUserClaimProperties } from "@ehb/shared/src/user";
import { v4 as uuid } from "uuid";
import { createCustomItem, createErrorResult } from "@ehb/shared/src/calculator/util";
import { PriceAccessories, getPriceAccessories } from "@ehb/shared/src/calculator/calc-price";
import { SharedStateAction } from "@ehb/client-infra/src/shared-state";
import { MainLocation } from "@ehb/client-infra/src/routes";
import { getItemIdProperties, itemIdQueryParam } from "@ehb/shared/src/project";
import * as GQLOps from "../../generated/generated-operations";
import {
  generateDefaultProperties,
  generateDefaultValueForProperty,
  updateAmountFormat,
} from "../../elements/properties-selector/property-selector-def";
import { clientConfig } from "../../client-config";
import { createQuoteRequestMutation } from "./mutation";
import * as ProjectState from "../../project-state";

export const electricHeaterProductKey = "EH";

type LocationParams = { readonly query: { readonly [query: string]: string } };

export const Printing = ctorsUnion({
  Idle: () => ({}),
  Fetching: () => ({}),
  Loading: (response: Reports.ReportQueryResponse, data: CalculateResult) => ({ response, data }),
});

export type Printing = CtorsUnion<typeof Printing>;

export type ProductSelection = UrlSelection | ProjectSelection;

export type UrlSelection = {
  readonly type: "url";
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly customItems: ReadonlyArray<CustomItem>;
};

export type ProjectSelection = {
  readonly type: "project";
  readonly itemId: string;
};

export type State = {
  readonly locationParams: LocationParams;
  readonly electricalHeaterProduct: GQLOps.ProductQuery | undefined;
  readonly circuitDiagrams: ReadonlyArray<CircuitDiagramTableRows>;
  readonly documents: ReadonlyArray<DocumentTableRows>;
  readonly images: ReadonlyArray<ImageTableRows>;
  readonly productSelection: ProductSelection;
  readonly calculateResult: Result<ErrorResult, Calculator.CalculateResult> | undefined;
  readonly isValidselectedProperties: boolean;
  readonly printing: Printing;
  readonly existingCode: string;
  readonly errorExistingCode: string;
  readonly manualDepth: string;
  readonly requestQuoteStatus: "not_sent" | "sending" | "sent_ok" | "sent_error";
  readonly reportQueryRunner: Reports.QueryRunner | undefined;
  readonly projectState: ProjectState.State;
};

export function init(
  prevState: State | undefined,
  sharedState: SharedState.SharedState,
  locationParams: LocationParams
): readonly [State, Cmd<Action>?] {
  let state: State = prevState || {
    locationParams: locationParams,
    electricalHeaterProduct: undefined,
    productSelection: { type: "url", selectedProperties: PropertyValueSet.Empty, customItems: [] },
    calculateResult: undefined,
    circuitDiagrams: [],
    documents: [],
    images: [],
    isValidselectedProperties: false,
    printing: Printing.Idle(),
    existingCode: "",
    errorExistingCode: "",
    manualDepth: "",
    requestQuoteStatus: "not_sent",
    reportQueryRunner: undefined,
    projectState: {
      type: "closed",
    },
  };
  state = { ...state, locationParams };

  const cmds = [];

  if (locationParams.query[itemIdQueryParam] || sharedState.openProjectId) {
    const [projectState, projectCmd] = ProjectState.init(state?.projectState, sharedState, locationParams.query);
    state = {
      ...state,
      projectState: projectState,
    };
    cmds.push(Cmd.map(Action.DispatchProject, projectCmd));
  }

  if (state.projectState.type !== "loading") {
    const [state2, initCmd2] = init2(state, sharedState);
    state = state2;
    cmds.push(initCmd2);
  }

  return [state, Cmd.batch(cmds)];
}

function init2(state: State, sharedState: SharedState.SharedState): readonly [State, Cmd<Action>?] {
  const { locationParams, projectState } = state;

  let newState = state;

  const itemId = locationParams.query[itemIdQueryParam];
  const project = projectState.type === "open" ? projectState.project : undefined;
  const itemProperties =
    project && itemId ? getItemIdProperties(project, "electric", electricHeaterProductKey, itemId) : undefined;

  if (itemProperties) {
    newState = {
      ...newState,
      productSelection: {
        type: "project",
        itemId,
      },
    };
  } else {
    const url = new URL(window.location.href);
    const queryParams: { [key: string]: string } = {};
    url.searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });
    const selectedProperties = decodePropertyValueSet(locationParams.query, sharedState.activeUser);
    const customItems = decodeCustomItemsFromParams(queryParams);
    newState = {
      ...newState,
      productSelection: {
        type: "url",
        selectedProperties,
        customItems,
      },
    };
  }

  const gqlCmdProduct = sharedState.graphQLProductQuery<GQLOps.ProductQuery, GQLOps.ProductQueryVariables, Action>(
    ProductQuery,
    { productId: clientConfig.promaster_eh_id, language: sharedState.selectedLanguage },
    (data) => {
      return Action.ProductRecieved(data);
    }
  );

  return [newState, gqlCmdProduct];
}

export const Action = ctorsUnion({
  DispatchProject: (action: ProjectState.Action) => ({ action }),
  ProductRecieved: (data: GQLOps.ProductQuery) => ({ data }),
  SetSelectedPropertiesAndCalculate: (
    selectedProperties: PVS.PropertyValueSet,
    changedProperty: ReadonlyArray<string>
  ) => ({
    selectedProperties,
    changedProperty,
  }),
  SetSelectedFormat: (propertyName: string, selectedFormat: SelectableFormat) => ({ propertyName, selectedFormat }),
  SetFieldUnit: (fieldName: string, unit: string, decimalCount: number) => ({
    fieldName,
    unit,
    decimalCount,
  }),
  ClearFieldUnit: (fieldName: string) => ({ fieldName }),
  QuoteFinishedLoading: () => ({}),
  GenerateQuote: () => ({}),
  OnExsistingCodeValueChange: (value: string) => ({ value }),
  LookUpVariant: () => ({}),
  OnExistingDepthValueChange: (value: string) => ({ value }),
  SetManualDepth: () => ({}),
  ClearManualDepth: () => ({}),
  RequestQuote: () => ({}),
  RequestQuoteResponseReceived: (mutation: GQLOps.CreateQuoteRequestMutation) => ({ mutation }),
  RequestQuoteFailed: () => ({}),
  AddCustomItem: () => ({}),
  RemoveCustomItem: (id: string) => ({ id }),
  UpdateCustomItem: (item: CustomItem) => ({ item }),
  ReportDataReceived: (data: unknown) => ({ data }),
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "DispatchProject": {
      const cmds = [];
      let newState = state;

      const [newProjectState, cmd, sharedStateAction] = ProjectState.update(
        action.action,
        state.projectState,
        sharedState
      );
      newState = {
        ...newState,
        projectState: newProjectState,
      };
      cmds.push(Cmd.map(Action.DispatchProject, cmd));

      if (newState.projectState.type !== "loading" && state.projectState.type === "loading") {
        // Continue initing if a project got loaded
        const [initState2, initCmd2] = init2(newState, sharedState);
        newState = initState2;
        cmds.push(initCmd2);
      }

      return [newState, Cmd.batch(cmds), sharedStateAction];
    }
    case "ProductRecieved": {
      const productQuery = action.data;
      const productProperties = action.data.product?.modules.properties.property ?? [];

      const defaultProperties = generateDefaultProperties(productProperties);

      const selectedPropertiesFromUrl = PVS.setValues(getSelectedProperties(state), defaultProperties);

      const mapUserClaimsToProperties = updateUserClaimProperties(
        sharedState.activeUser.claims,
        selectedPropertiesFromUrl
      );

      let newState: State = {
        ...state,
        electricalHeaterProduct: productQuery,
        calculateResult: undefined,
      };

      const [updatedState, updateCmd] = updateProductSelection(
        sharedState,
        newState,
        mapUserClaimsToProperties,
        undefined,
        "dont_update_url"
      );
      newState = updatedState;

      if (Navigation.getCurrentUrl().query !== undefined) {
        const manualDepth = PVS.getInteger("manualdepth", mapUserClaimsToProperties);
        newState = calculate(
          {
            ...newState,
            manualDepth: manualDepth?.toString() ?? "",
          },
          sharedState
        );
      }

      return [newState, updateCmd];
    }
    case "SetSelectedPropertiesAndCalculate": {
      const newProperties = generateDefaultValueForProperty(
        state.electricalHeaterProduct?.product?.modules.properties.property ?? [],
        action.selectedProperties,
        action.changedProperty.includes("model") ? "cableinlet" : ""
      );
      const allProps = PVS.merge(newProperties, action.selectedProperties);
      const selectedcCurrency: Currency = PV.getInteger(PVS.getValue("currency", action.selectedProperties)) || 1;
      const customItems = getSelectedCustomItems(state);
      const updatedCustomItems = customItems.map((item) => ({ ...item, currency: Currency[selectedcCurrency] }));

      let newState: State = {
        ...state,
        requestQuoteStatus: "not_sent",
      };

      const [updatedState, updateCmd] = updateProductSelection(
        sharedState,
        newState,
        allProps,
        updatedCustomItems,
        "update_url"
      );
      newState = updatedState;

      newState = calculate(newState, sharedState);

      return [newState, updateCmd];
    }
    case "SetSelectedFormat": {
      const selectedProperties = getSelectedProperties(state);
      const newProps = updateAmountFormat(
        state.electricalHeaterProduct?.product?.modules.properties.property ?? [],
        selectedProperties,
        action.propertyName,
        action.selectedFormat
      );
      const [updatedState, updateCmd] = updateProductSelection(sharedState, state, newProps, undefined, "update_url");
      return [updatedState, updateCmd, SharedStateAction.SetSelectedFormat(action.propertyName, action.selectedFormat)];
    }
    case "ClearFieldUnit": {
      return [state, undefined, SharedStateAction.ClearFieldUnit(action.fieldName)];
    }
    case "SetFieldUnit": {
      return [state, undefined, SharedStateAction.SetFieldUnit(action.fieldName, action.unit, action.decimalCount)];
    }
    case "GenerateQuote": {
      if (!state.calculateResult || state.calculateResult?.type === "Err") {
        return [{ ...state, printing: Printing.Idle() }];
      }
      const selectedProperties = getSelectedProperties(state);
      const selectedCustomItems = getSelectedCustomItems(state);
      const calculateRequest = Calculator.fromVariantToCalculateRequest(selectedProperties);
      if (!calculateRequest || calculateRequest.type === "Err") {
        throw Error(calculateRequest?.error);
      }
      const priceTables = getFilteredPriceTables(state.electricalHeaterProduct, selectedProperties);
      if (!priceTables || priceTables.type === "Err") {
        throw Error(priceTables?.error);
      }
      const reportParams = createReportParams(
        sharedState,
        state,
        state.calculateResult.value,
        calculateRequest.value,
        getPriceAccessories(
          calculateRequest.value.currency,
          priceTables.value.priceAccessories,
          calculateRequest.value.discount
        ),
        selectedCustomItems
      );
      const queryRunner = Reports.runReportQuries(clientConfig.imageServiceUrl, reportParams);
      const newState = {
        ...state,
        reportQueryRunner: queryRunner,
      };
      return handleReportQueryAction(action, newState, sharedState);
    }
    case "QuoteFinishedLoading": {
      return [{ ...state, printing: Printing.Idle() }];
    }
    case "OnExsistingCodeValueChange": {
      return [{ ...state, existingCode: action.value, errorExistingCode: "" }];
    }
    case "LookUpVariant": {
      const variantFromCode = reverseLookUpVariant(state.existingCode);

      if (variantFromCode.type === "Err") {
        return [{ ...state, errorExistingCode: variantFromCode.error }];
      }

      const defaultProperties = generateDefaultProperties(
        state.electricalHeaterProduct?.product?.modules.properties.property ?? []
      );
      const mappedVariantFromCode = PVS.setValues(variantFromCode.value, defaultProperties);
      const mapUserClaimsToProperties = updateUserClaimProperties(sharedState.activeUser.claims, mappedVariantFromCode);
      const selectedcCurrency: Currency = PV.getInteger(PVS.getValue("currency", mapUserClaimsToProperties)) || 1;
      const selectedCustomItems = getSelectedCustomItems(state);
      const updatedCustomItems = selectedCustomItems.map((item) => ({
        ...item,
        currency: Currency[selectedcCurrency],
      }));

      let newState: State = {
        ...state,
        requestQuoteStatus: "not_sent",
      };

      const [updatedState, updateCmd] = updateProductSelection(
        sharedState,
        newState,
        mapUserClaimsToProperties,
        updatedCustomItems,
        "update_url"
      );
      newState = updatedState;

      newState = calculate(newState, sharedState);

      return [newState, updateCmd];
    }
    case "OnExistingDepthValueChange": {
      return [{ ...state, manualDepth: action.value, errorExistingCode: "" }];
    }

    case "SetManualDepth": {
      const selectedProperties = getSelectedProperties(state);
      const manualDepthMm = Number(state.manualDepth);
      if (Number.isNaN(manualDepthMm) || manualDepthMm < 0) {
        return [state];
      }
      // const depthMm = PV.create("integer", Amount.create(manualDepthMm, EhbUnits.Millimeter));

      const newProperties = PVS.setInteger("manualdepth", manualDepthMm, selectedProperties);

      let newState = state;

      const [updatedState, updateCmd] = updateProductSelection(
        sharedState,
        state,
        newProperties,
        undefined,
        "update_url"
      );
      newState = updatedState;

      newState = calculate(newState, sharedState);

      return [newState, updateCmd];
    }

    case "ClearManualDepth": {
      const selectedProperties = getSelectedProperties(state);
      const newProperties = PVS.setInteger("manualdepth", 0, selectedProperties);

      let newState: State = { ...state, manualDepth: "" };

      const [updatedState, updateCmd] = updateProductSelection(
        sharedState,
        state,
        newProperties,
        undefined,
        "update_url"
      );
      newState = updatedState;

      newState = calculate(newState, sharedState);

      return [newState, updateCmd];
    }
    case "RequestQuote": {
      return RequestQuote(action, state, sharedState);
    }
    case "RequestQuoteResponseReceived": {
      const requestSent = action.mutation.createQuoteRequest.quoteRequestSent;
      return [{ ...state, requestQuoteStatus: requestSent ? "sent_ok" : "sent_error" }];
    }
    case "RequestQuoteFailed": {
      return [{ ...state, requestQuoteStatus: "sent_error" }];
    }
    case "AddCustomItem": {
      const selectedProperties = getSelectedProperties(state);
      const selectedCustomItems = getSelectedCustomItems(state);
      const currency: Currency = PV.getInteger(PVS.getValue("currency", selectedProperties)) || 1;
      const customItem: CustomItem = {
        id: uuid(),
        sortNo: Math.max(0, ...selectedCustomItems.map((m) => m.sortNo)) + 1,
        name: "",
        quantity: 1,
        singlePrice: 0,
        totalPrice: 0,
        currency: (currency && Currency[currency]) ?? sharedState.activeUser.claims.currency,
      };
      const updatedCustomItems = [...selectedCustomItems, customItem];
      return updateProductSelection(sharedState, state, undefined, updatedCustomItems, "update_url");
    }
    case "RemoveCustomItem": {
      const selectedCustomItems = getSelectedCustomItems(state);
      const updatedItems = selectedCustomItems.filter((item) => item.id !== action.id);
      const refreshedCustomItems = updatedItems.map((item, index) => ({
        ...item,
        sortNo: index + 1,
      }));

      let newState: State = state;

      const [updatedState, updateCmd] = updateProductSelection(
        sharedState,
        state,
        undefined,
        refreshedCustomItems,
        "update_url"
      );
      newState = updatedState;

      newState = calculate(newState, sharedState);

      return [newState, updateCmd];
    }
    case "UpdateCustomItem": {
      const selectedCustomItems = getSelectedCustomItems(state);
      const updatedCustomItems = selectedCustomItems.map((item) =>
        item.id === action.item.id ? createCustomItem(action.item) : item
      );

      let newState: State = state;

      const [updatedState, updateCmd] = updateProductSelection(
        sharedState,
        state,
        undefined,
        updatedCustomItems,
        "update_url"
      );
      newState = updatedState;

      newState = calculate(newState, sharedState);

      return [newState, updateCmd];
    }
    case "ReportDataReceived": {
      if (!state.reportQueryRunner) {
        return [state];
      }
      return handleReportQueryAction(action, state, sharedState);
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

function handleReportQueryAction(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?] {
  if (!state.reportQueryRunner || (action.type !== "GenerateQuote" && action.type !== "ReportDataReceived")) {
    return [state];
  }
  const result =
    action.type === "GenerateQuote" ? state.reportQueryRunner.next() : state.reportQueryRunner.next(action.data);
  if (!state.calculateResult || state.calculateResult?.type === "Err") {
    return [state];
  }
  if (result.done) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    return [
      {
        ...state,
        printing: Printing.Loading(result.value, state.calculateResult.value),
        reportQueryRunner: undefined,
      },
    ];
  } else {
    return [{ ...state, printing: Printing.Fetching() }, requestsToCommand(result.value, sharedState)];
  }
}

function calculate(state: State, sharedState: SharedState.SharedState): State {
  const { translate, activeUser } = sharedState;

  const product = state.electricalHeaterProduct;
  if (!product) {
    throw new Error("calculate: Tried to calculation without product data");
  }

  const selectedProperties = getSelectedProperties(state);
  const customItems = getSelectedCustomItems(state);

  let calculateRequest: FP.Result<string, CalculateRequest>;
  try {
    calculateRequest = Calculator.fromVariantToCalculateRequest(selectedProperties);
  } catch {
    return {
      ...state,
      calculateResult: createErrorResult(texts.ERROR_SELECTION_NOT_POSSIBLE("").key, "ERROR"),
      errorExistingCode: "",
    };
  }
  if (calculateRequest.type === "Err") {
    return { ...state, errorExistingCode: "" };
  }

  const calculateResult = Calculator.calculate(calculateRequest.value, product, translate, customItems, activeUser);
  const diagrams =
    calculateResult.type === "Err" ? FP.Ok([]) : getFilteredCircuitDiagramTable(product, selectedProperties);
  const documents = calculateResult.type === "Err" ? FP.Ok([]) : getFilteredDocumentsTable(product, selectedProperties);
  const images = calculateResult.type === "Err" ? FP.Ok([]) : getFilteredImagesTable(product, selectedProperties);

  return {
    ...state,
    calculateResult: calculateResult,
    circuitDiagrams: diagrams.type === "Err" ? [] : diagrams.value,
    documents: documents.type === "Err" ? [] : documents.value,
    images: images.type === "Err" ? [] : images.value,
    errorExistingCode: "",
  };
}

function RequestQuote(
  _: ReturnType<typeof Action.RequestQuote>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  if (!sharedState.activeUser.claims.responsible_email) {
    return [state];
  }
  return [
    { ...state, requestQuoteStatus: "sending" },
    graphQLMutation<GQLOps.CreateQuoteRequestMutation, GQLOps.CreateQuoteRequestMutationVariables, Action>(
      createQuoteRequestMutation,
      {
        input: {
          configurationLink: getConfigurationLink(state, sharedState),
          language: sharedState.selectedLanguage,
        },
      },
      (data) => Action.RequestQuoteResponseReceived(data),
      () => Action.RequestQuoteFailed()
    ),
  ];
}

function requestsToCommand(query: Query.Query, sharedState: SharedState.SharedState): Cmd<Action> {
  switch (query.type) {
    case "HttpBlobQuery": {
      return HttpFetch.fetchOne({}, query.url, "blob", (data) => {
        return Action.ReportDataReceived(data);
      });
    }
    case "HttpBlobQueryMultiple": {
      return HttpFetch.fetchMultiple({}, query.url, "blob", (data) => {
        return Action.ReportDataReceived(data);
      });
    }
    case "GraphQLProductQuery": {
      return sharedState.graphQLProductQuery<unknown, unknown, Action>(query.query, query.variables, (data) => {
        return Action.ReportDataReceived(data);
      });
    }
    case "GraphQLQuery": {
      return graphQLQueryWithAuth(sharedState.activeUser)<unknown, unknown, Action>(
        query.query,
        query.variables,
        (data) => {
          return Action.ReportDataReceived(data);
        }
      );
    }
    case "PromiseQuery": {
      return PromiseEffectManager.perform<Action, unknown>(
        (data: unknown) => Action.ReportDataReceived(data),
        (async (): Promise<FP.Result<never, unknown>> => ({
          type: "Ok",
          value: await query.promise,
        }))()
      );
    }
    default:
      exhaustiveCheck(query);
      throw new Error("Not implemented");
  }
}

export function createReportParams(
  sharedState: SharedState.SharedState,
  state: State,
  calculateResult: CalculateResult,
  calculateRequest: CalculateRequest,
  priceAccessories: PriceAccessories,
  customItems: CustomItems
): Reports.ReportParamsProduct {
  const sharedParams: Omit<Reports.ReportParamsProduct, "reportType"> = {
    imageServiceUrl: clientConfig.imageServiceUrl,
    metaProductId: clientConfig.promaster_meta_id,
    ehProductId: clientConfig.promaster_eh_id,
    translate: sharedState.translate,
    calculateResult: calculateResult,
    calculateRequest: calculateRequest,
    priceAccessories: priceAccessories,
    user: sharedState.activeUser,
    configurationLink: getConfigurationLink(state, sharedState),
    customItems: customItems,
    heaterImages: state.images,
    properties: getSelectedProperties(state),
    productKey: state.electricalHeaterProduct?.product?.key || "",
    clientConfig: clientConfig,
    productByKey: sharedState.productByKey,
    productById: sharedState.productById,
    selectedLanguage: sharedState.selectedLanguage,
    getFieldFormat: sharedState.getFieldFormat,
    waterAccessories: [],
  };

  const reportParams = { ...sharedParams, reportType: "quote-page" } as const;

  return reportParams;
}

export function getSelectedProperties(state: State): PVS.PropertyValueSet {
  const { productSelection, projectState } = state;
  if (productSelection.type === "url") {
    return productSelection.selectedProperties;
  } else if (projectState.type === "open") {
    let pvs =
      getItemIdProperties(projectState.project, "electric", electricHeaterProductKey, productSelection.itemId) ||
      PropertyValueSet.Empty;
    pvs = PVS.removeProperty(customItemsQueryParam, pvs);
    return pvs;
  } else {
    return PropertyValueSet.Empty;
  }
}

export function getSelectedCustomItems(state: State): ReadonlyArray<CustomItem> {
  const { productSelection, projectState } = state;
  if (productSelection.type === "url") {
    return productSelection.customItems;
  } else if (projectState.type === "open") {
    const pvs =
      getItemIdProperties(projectState.project, "electric", electricHeaterProductKey, productSelection.itemId) ||
      PropertyValueSet.Empty;
    return decodeCustomItemsFromPvs(pvs);
  } else {
    return [];
  }
}

export function getConfigurationLink(state: State, sharedState: SharedState.SharedState): string {
  const { productSelection, projectState } = state;
  if (productSelection.type === "url") {
    return window.location.href;
  } else if (projectState.type === "open") {
    const properties = getSelectedProperties(state);
    const customItems = getSelectedCustomItems(state);
    const query = encodeVariantPropertyValueSet(properties, customItems, sharedState.activeUser);
    return window.location.origin + Routes.buildMainUrl(MainLocation.ProductSelect(query));
  } else {
    return "";
  }
}

export function updateProductSelection(
  sharedState: SharedState.SharedState,
  state: State,
  selectedProperties: PVS.PropertyValueSet | undefined,
  customItems: CustomItems | undefined,
  updateUrl: "update_url" | "dont_update_url"
): readonly [State, Cmd<Action>?] {
  const { productSelection, projectState } = state;
  const newSelectedProperties = selectedProperties || getSelectedProperties(state);
  const newCustomItems = customItems || getSelectedCustomItems(state);
  if (productSelection.type === "url") {
    const variantQuery = encodeVariantPropertyValueSet(newSelectedProperties, newCustomItems, sharedState.activeUser);
    return [
      {
        ...state,
        productSelection: {
          type: "url",
          selectedProperties: newSelectedProperties,
          customItems: newCustomItems,
        },
      },
      updateUrl === "update_url"
        ? Navigation.replaceUrl<Action>(Routes.buildMainUrl(MainLocation.ProductSelect(variantQuery)), undefined, true)
        : undefined,
    ];
  } else {
    const finalProperties = encodeCustomItemsToPvs(newSelectedProperties, newCustomItems, sharedState.activeUser);
    const [newProjectState, projectCmd] = ProjectState.updateItemProperties(
      sharedState,
      projectState,
      productSelection.itemId,
      finalProperties
    );
    return [{ ...state, projectState: newProjectState }, Cmd.map(Action.DispatchProject, projectCmd)];
  }
}
