import * as React from "react";
import { AbstractDocJsx as ADX } from "abstract-document";
import * as Common from "../../common";
import { CreatorType } from "./types";
import { TextRun } from "abstract-document/lib/abstract-document-jsx";
import { uniq } from "../../../utils";
import { Texts } from "../../..";
import { LayoutFoundation, ParagraphStyle, TextStyle } from "abstract-document/lib/abstract-document";

const { AbstractDoc, Section, Group, Paragraph, render } = ADX;

export const create: CreatorType = ({ pageProps, project }) => {
  const { translate } = pageProps;
  const styles = Common.styles();
  const numberingDefinitions = Common.numberingDefinitions(styles);

  const products = uniq(project.items.map((i) => i.product));

  const doc = render(
    <AbstractDoc styles={styles} numberingDefinitions={numberingDefinitions}>
      <Section id={Common.pageId(pageProps)} page={Common.cataloguePage(pageProps)}>
        <Group keepTogether={true}>
          <Paragraph>
            <TextRun text="TODO: general info, project name, notes, quote date, link to project, production time, payment terms, delivery terms, total project price" />
          </Paragraph>

          <Group>
            <Paragraph>
              <TextRun
                style={TextStyle.create({ bold: true })}
                text={translate(Texts.texts.product_information_title)}
              />
            </Paragraph>

            {products.map((p) => (
              <Paragraph style={ParagraphStyle.create({ margins: LayoutFoundation.create({ bottom: 20 }) })} key={p}>
                <TextRun text={translate(Texts.texts.product_information(p))} />
              </Paragraph>
            ))}
          </Group>

          <Paragraph>
            <TextRun text="TODO: delivery terms, text from promaster meta.delivery_terms" />
          </Paragraph>
        </Group>
      </Section>
    </AbstractDoc>
  );

  return doc;
};
