import { Cmd } from "@typescript-tea/core";
import * as R from "ramda";
import gql from "graphql-tag";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { Reports, Search, Calculate, FP, CalculatorFrtCoil, SelectableFormat, User } from "@ehb/shared";
import { PropertyValueSet } from "@promaster-sdk/property";
import { CalculateResult } from "@ehb/shared/src/calculator/types";
import {
  SharedState,
  PromiseEffectManager,
  NavigationEffectManager as Navigation,
  Routes,
  graphQLProductQueryWithAuth,
  createBlobUrlCreator,
} from "@ehb/client-infra";
import { clientConfig } from "@ehb/client-infra/src/client-config";
import { productQuery } from "@ehb/shared/src/graphql-queries/product";
import { MainLocation } from "@ehb/client-infra/src/routes";
import { decodePropertyValueSet, encodePropertyValueSet } from "@ehb/shared/src/product-utils";
import { SharedStateAction } from "@ehb/client-infra/src/shared-state";
import { ActiveUser } from "@ehb/shared/src/user";
import * as GQLOps from "../../generated/generated-operations";
import {
  generateDefaultProperties,
  resetCalcParamsToDefault,
} from "../../elements/properties-selector/property-selector-def";

export const productImageQuery = gql`
  query SearchView($productId: ID!) {
    product(id: $productId) {
      modules {
        images {
          image {
            name
            image
            file_name
          }
        }
      }
    }
  }
`;

export const Printing = ctorsUnion({
  Idle: () => ({}),
  Fetching: () => ({}),
  Loading: (response: Reports.ReportQueryResponse, data: CalculateResult) => ({ response, data }),
});

export type Printing = CtorsUnion<typeof Printing>;

export type ResultOrder = {
  readonly sortOrder: Search.SortOrder;
  readonly sortColumn: string;
};

export type State = {
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly autoCalculate: boolean;
  readonly searchProduct: GQLOps.ProductQuery | undefined;
  readonly searchData: Search.SearchData | undefined;
  readonly calcData: CalculatorFrtCoil.CalculationData | undefined;
  readonly searchResult: Search.Result | undefined;
  readonly calculating: boolean;
  readonly calculationResults: Calculate.CalculationResultByKey | undefined;
  readonly resultOrder: ResultOrder;
  readonly productImages: ReadonlyMap<string, string>;
  readonly resultToUseForOrdering: Search.ModeResult;
};

export function init(
  prevState: State | undefined,
  sharedState: SharedState.SharedState,
  locationParams: { readonly query: { readonly [query: string]: string } }
): readonly [State, Cmd<Action>?] {
  if (prevState) {
    return [prevState];
  }

  const gqlCmdProduct = sharedState.graphQLProductQuery<GQLOps.ProductQuery, GQLOps.ProductQueryVariables, Action>(
    productQuery,
    { productId: clientConfig.promaster_search_product_id, language: sharedState.selectedLanguage },
    (data) => {
      return Action.ProductRecieved(data);
    }
  );

  const variant = decodePropertyValueSet(locationParams.query, sharedState.activeUser);
  const emptyProperties = PropertyValueSet.getPropertyNames(decodePropertyValueSet({}, sharedState.activeUser));
  const noQueryParamsProvided = PropertyValueSet.isEmpty(PropertyValueSet.removeProperties(emptyProperties, variant));

  const initialState: State = {
    searchProduct: undefined,
    variant: PropertyValueSet.setInteger("mode", 1, variant), // mode=1 for search page
    autoCalculate: !noQueryParamsProvided,
    calcData: undefined,
    searchData: undefined,
    searchResult: undefined,
    calculating: false,
    calculationResults: undefined,
    resultOrder: {
      sortOrder: "asc" as const,
      sortColumn: "model",
    },
    productImages: new Map(),
    resultToUseForOrdering: "heating",
  };

  return [initialState, Cmd.batch([gqlCmdProduct])];
}

export const Action = ctorsUnion({
  ProductRecieved: (data: GQLOps.ProductQuery) => ({ data }),
  SearchDataRecieved: (data: GQLOps.SearchQuery) => ({ data }),
  CalcDataRecieved: (data: GQLOps.FrtCoilQuery) => ({ data }),
  SetVariant: (variant: PropertyValueSet.PropertyValueSet) => ({ variant }),
  StartSearch: () => ({}),
  UpdateSearchResult: (result: Search.Result) => ({ result }),
  UpdateCalculationResult: (result: Calculate.CalculationResultByKey) => ({ result }),
  SetSelectedFormat: (fieldName: string, selectedFormat: SelectableFormat) => ({ fieldName, selectedFormat }),
  SetFieldUnit: (fieldName: string, unit: string, decimalCount: number) => ({
    fieldName,
    unit,
    decimalCount,
  }),
  ClearFieldUnit: (fieldName: string) => ({ fieldName }),
  SetResultOrder: (resultOrder: ResultOrder) => ({ resultOrder }),
  ProductImageRecieved: (data: GQLOps.SearchViewQuery, productId: string) => ({ data, productId }),
  SetResultToUseForOrdering: (result: Search.ModeResult) => ({ result }),
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "ProductRecieved": {
      const variant = action.data.product
        ? generateDefaultProperties(action.data.product.modules.properties.property, state.variant)
        : PropertyValueSet.Empty;
      const newState = { ...state, searchProduct: action.data, variant };
      const gqlCmdSearch = sharedState.graphQLProductQuery<GQLOps.SearchQuery, GQLOps.SearchQueryVariables, Action>(
        Search.searchProductQueryAll,
        { searchProductId: clientConfig.promaster_search_product_id },
        (data) => {
          return Action.SearchDataRecieved(data);
        }
      );

      return [newState, Cmd.batch([gqlCmdSearch])];
    }

    case "SearchDataRecieved": {
      const searchData = Search.createSearchData(action.data);
      const gqlCmdCalc = sharedState.graphQLProductQuery<GQLOps.FrtCoilQuery, GQLOps.FrtCoilQueryVariables, Action>(
        CalculatorFrtCoil.query,
        { searchProductId: clientConfig.promaster_search_product_id },
        (data) => {
          return Action.CalcDataRecieved(data);
        }
      );
      return [{ ...state, searchData: searchData }, gqlCmdCalc];
    }

    case "CalcDataRecieved": {
      if (!state.searchData) {
        return [state];
      }
      const calcData = CalculatorFrtCoil.mapQuery(action.data);
      return [
        { ...state, calcData: calcData },
        Cmd.batch([
          search(state.searchData, createSearchQuery(sharedState.activeUser, state.searchData, state.variant)),
        ]),
      ];
    }

    case "SetVariant": {
      const productProperties = state.searchProduct?.product?.modules.properties.property;
      if (!state.searchData || !productProperties) {
        return [state];
      }
      const newVariant = resetCalcParamsToDefault(productProperties, state.variant, action.variant);
      const newState = { ...state, variant: newVariant, calculationResults: undefined };
      return [
        newState,
        Cmd.batch([
          search(state.searchData, createSearchQuery(sharedState.activeUser, state.searchData, newState.variant)),
          Navigation.replaceUrl<Action>(
            Routes.buildMainUrl(
              MainLocation.ProductSearch(encodePropertyValueSet(newState.variant, sharedState.activeUser.claims))
            ),
            undefined,
            true
          ),
        ]),
      ];
    }

    case "StartSearch": {
      if (!state.searchData || !state.searchResult || !state.calcData) {
        return [state];
      }

      return [
        { ...state, calculating: true },
        calculate(state.calcData, state.variant, state.searchResult, state.searchProduct),
      ];
    }

    case "UpdateSearchResult": {
      if (!state.calcData) {
        return [state];
      }
      return [
        {
          ...state,
          calculating: state.autoCalculate,
          autoCalculate: false,
          searchResult: action.result,
        },
        state.autoCalculate ? calculate(state.calcData, state.variant, action.result, state.searchProduct) : undefined,
      ];
    }

    case "UpdateCalculationResult": {
      const newState = { ...state, calculating: false, calculationResults: action.result };
      if (!state.searchResult) {
        return [newState];
      }
      const cmds = R.uniq(state.searchResult.matches.map((m) => m.selection.productId)).map((productId) =>
        loadProductImage(sharedState.activeUser, productId)
      );

      return [newState, Cmd.batch(cmds)];
    }

    case "SetSelectedFormat": {
      return [state, undefined, SharedStateAction.SetSelectedFormat(action.fieldName, action.selectedFormat)];
    }
    case "ClearFieldUnit": {
      return [state, undefined, SharedStateAction.ClearFieldUnit(action.fieldName)];
    }
    case "SetFieldUnit": {
      return [state, undefined, SharedStateAction.SetFieldUnit(action.fieldName, action.unit, action.decimalCount)];
    }

    case "SetResultOrder": {
      return [{ ...state, resultOrder: action.resultOrder }];
    }
    case "ProductImageRecieved": {
      const product = action.data.product;
      if (!product) {
        return [state];
      }
      const newProductImage = new Map(state.productImages);

      const productImage = product.modules.images.image.find((p) => p.name === "product_image");

      if (!productImage?.image || !productImage?.file_name) {
        return [state];
      }
      const createBlobUrl = createBlobUrlCreator(sharedState.activeUser.accessToken);
      newProductImage.set(action.productId, createBlobUrl(productImage.image, productImage.file_name));
      return [{ ...state, productImages: newProductImage }];
    }
    case "SetResultToUseForOrdering": {
      return [
        {
          ...state,
          resultToUseForOrdering: action.result,
        },
      ];
    }
    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

function search(
  searchData: Search.SearchData,
  query: Search.Query
): PromiseEffectManager.PromiseEffect<Action, never, Search.Result> {
  return PromiseEffectManager.perform<Action, Search.Result>(
    (data) => Action.UpdateSearchResult(data),
    (async (): Promise<FP.Result<never, Search.Result>> => ({
      type: "Ok",
      value: Search.search(searchData, query),
    }))()
  );
}

function calculate(
  calcData: CalculatorFrtCoil.CalculationData,
  properties: PropertyValueSet.PropertyValueSet,
  searchResult: Search.Result,
  searchProduct: GQLOps.ProductQuery | undefined
): PromiseEffectManager.PromiseEffect<Action, never, Calculate.CalculationResultByKey> {
  const calculationTargetProperties = searchProduct?.product?.modules.properties.property
    .filter((p) => p.group === "calculation_target")
    .map((p) => p.name || "");
  const propertiesWithoutMargin = PropertyValueSet.removeProperties(calculationTargetProperties || [], properties);

  return PromiseEffectManager.perform<Action, Calculate.CalculationResultByKey>(
    (data) => Action.UpdateCalculationResult(data),
    (async (): Promise<FP.Result<never, Calculate.CalculationResultByKey>> => {
      return {
        type: "Ok",
        value: await Calculate.calculateSearchResult(calcData, propertiesWithoutMargin, searchResult),
      };
    })()
  );
}

function createSearchQuery(
  activeUser: ActiveUser,
  searchData: Search.SearchData,
  variant: PropertyValueSet.PropertyValueSet
): Search.Query {
  return {
    company: activeUser.companyName,
    filter: Search.propertiesToSearchFilter(searchData, variant, true),
    productKey: undefined,
    userCurrency: activeUser.claims.currency,
  };
}

function loadProductImage(activeUser: User.ActiveUser, productId: string): Cmd<Action> {
  const graphQLProductQuery = graphQLProductQueryWithAuth(activeUser, clientConfig.promaster_marker);
  return graphQLProductQuery<GQLOps.SearchViewQuery, GQLOps.SearchViewQueryVariables, Action>(
    productImageQuery,
    {
      productId: productId,
    },
    (data) => {
      return Action.ProductImageRecieved(data, productId);
    }
  );
}
